import React from "react";

function DataStores () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Data Stores">
                <div className="ug-content-main-header">Data Stores</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>A datastore describes a specific instance of a system external to DCP that holds data. For example, a datastore may represent an Oracle database, an Amazon S3 or Hadoop file system, a Salesforce account, or any other data storage system supported by DCP.</p>
                    <p>Datastores are available in the DCP user interface only after they have been added to DCP, usually by a DCP administrator. When the administrator adds a datastore, they are adding information about how to connect to an external system.</p>
                    <p>The datastores are external to DCP, so DCP needs a way to communicate with them. DCP provides a variety of datastore adapters for this purpose. A datastore is typically specified by a DCP datastore adapter and the parameters associated with that adapter. The parameters might include the host name, host IP address, port, and similar characteristics. For more information see "Adapters" and "Data Stores" in the DCP Administrator’s Guide.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Supported Data Stores">
                <div className="ug-content-main-header">Supported Data Stores</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>DCP supports the following datastore types:</p>
                    <ul>
                        <li>Adobe Cloud</li>
                        <li>Amazon S3</li>
                        <li>Amazon EMR 5.8.0, 5.11.1 HDFS</li>
                        <li>Amazon EMR 5.8.0, 5.11.1 Hive</li>
                        <li>Apache HDFS 2.5.0, 2.6.0, 2.7.1, 2.7.3</li>
                        <li>Apache Hive 0.13.1, 1.1.0, 1.2.1</li>
                        <li>Azura Data Lake Store (ADLS)</li>
                        <li>Cisco Information Server (CIS) 7.0</li>
                        <li>Cloudera HDFS 5.3.0, 5.7, 5.12.1</li>
                        <li>Cloudera Hive 5.3.0, 5.7, 5.12.1ddfff</li>
                        <li>Google Analytics</li>
                        <li>Google BigQuery</li>
                        <li>Google Cloud Storage</li>
                        <li>Google Sheets</li>
                        <li>Greenplum Database</li>
                        <li>Hortonworks Data Platform (HDP) HDFS 2.4, 2.6</li>
                        <li>Hortonworks Data Platform (HDP) Hive 2.4, 2.6</li>
                        <li>IBM DB2 10.5</li>
                        <li>IBM DB2 on AS/400 V5R2, V6R1</li>
                        <li>MapR 4.0.2 HDFS</li>
                        <li>MapR 4.0.2 Hive</li>
                        <li>Marketo</li>
                        <li>Microsoft Azure Blob</li>
                        <li>Microsoft Azure SQL</li>
                        <li>Microsoft Dynamics CRM</li>
                        <li>Microsoft HDInsight 3.4, 3.6 HDFS</li>
                        <li>Microsoft HDInsight 3.4, 3.6 Hive</li>
                        <li>MongoDB 3.4</li>
                        <li>MySQL 5.7</li>
                        <li>Netezza 7.2</li>
                        <li>NetSuite CRM, NetSuite ERP</li>
                        <li>Oracle 12.1</li>
                        <li>PostgreSQL 9.3</li>
                        <li>Progress OpenEdge 10.1A, 10.2B</li>
                        <li>Redshift</li>
                        <li>Remote File System</li>
                        <li>Salesforce</li>
                        <li>SAP Adaptive Server Enterprise (ASE) 16.0</li>
                        <li>SAP HANA SPS11</li>
                        <li>ServiceNow</li>
                        <li>Snowflake 1.8</li>
                        <li>SQL Server 8.0, 11.0</li>
                        <li>Tableau 10.4.0, 10.5.0</li>
                        <li>Teradata 15.0</li>
                        <li>Twitter</li>
                        <li>YouTube Analytics</li>
                        <li>Zuora</li>
                    </ul>
                    <p>Additional information is available from the Data Stores chapter in the DCP Administrator’s Guide.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Viewing Data Stores">
                <div className="ug-content-main-header">Viewing Data Stores</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>In the left navigation bar, click Manage>Datastores. The Datastores screen lists the available datastores. Only users with administrator privileges can see this screen.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Adding a Data Store">
                <div className="ug-content-main-header">Adding a Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Log in to the DCP with a user account that has administrator permissions, such as the Boomi user.</li>
                        <li>In the left navigation bar, click Manage, then click Datastores.</li>
                        <li>Click the plus icon.</li>
                        <li>Choose the type of datastore you are going to use: DATABASE, FILESYSTEM, SERVICE, or BI.</li>
                        <li>Type a name for the datastore.</li>
                        <li>Type a description. You can enter an HTML anchor tag to create a hyperlink.</li>
                        <li>Choose the adapter.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the AtomSphere Data Store">
                <div className="ug-content-main-header">Connect the AtomSphere Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Enter a data store name. For example, Boomi platform.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>AtomSphere Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter the base URL used to access the Boomi platform. For example, https://platform.boomi.com.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the Master Data Hub Data Store">
                <div className="ug-content-main-header">Connect the Master Data Hub Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Enter a data store name. For example, Master Data Hub.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Enter the Hub Cloud host name. To locate the host name: 
                            <ol type="a">
                                <li>In the Boomi platform, navigate to <strong>Integration</strong> &gt; <strong>Manage</strong> &gt; <strong>Atom Management</strong>.</li>
                                <li>Click the atom.</li>
                                <li>In Atom Information, enter the <strong>Host Name</strong>. For example, USA East Hub Cloud.</li>
                            </ol>
                        </li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the Azure SQL Data Store">
                <div className="ug-content-main-header">Connect the Azure SQL Database Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, Azure SQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Azure SQL Database Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your Azure SQL database host DNS name or IP address. For example, mysqlserver.database.windows.net.</li>
                        <li>Enter your Azure SQL database port. 1433 is the default port.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the BigQuery Data Store">
                <div className="ug-content-main-header">Connect the BigQuery Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, BigQuery.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>BigQuery Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the Oracle Data Store">
                <div className="ug-content-main-header">Connect the Oracle Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, Oracle.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Oracle</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your Oracle database host DNS name or IP address.</li>
                        <li>Enter your Oracle database port. 1521 is the default port.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the PostgreSQL Data Store">
                <div className="ug-content-main-header">Connect the PostgreSQL Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, PostgreSQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>PostgreSQL Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your PostgreSQL host DNS name or IP address.</li>
                        <li>Enter your PostgreSQL port. 5432 is the default port.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the Redshift Data Store">
                <div className="ug-content-main-header">Connect the Redshift Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, Redshift.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Redshift</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your Redshift cluster JDBC endpoint. To locate the endpoint:
                            <ol type="a">
                                <li>Navigate to AWS console &gt; <strong>Analytics</strong> &gt; <strong>Amazon Redshift</strong>.</li>
                                <li>Click <strong>Clusters</strong>.</li>
                                <li>Select your cluster.</li>
                                <li>In your cluster’s <strong>General Information</strong> section, locate the JDBC URL.</li>
                                <li><p>Remove jdbc:redshift// from the beginning of the URL and remove the port and database name. The endpoint should follow this format:</p>
                                    <p>“cluster-name.xxxx.aws-region.redshift.amazonaws.com”</p>
                                </li>
                            </ol>
                        </li>
                        <li>Enter your Redshift port. 5439 is the default port.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Connect the Salesforce Data Store">
                <div className="ug-content-main-header">Connect the Salesforce Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Service</strong>.</li>
                        <li>Enter a data store name. For example, Salesforce.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Salesforce Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your current Salesforce API Version. Refer to Salesforce’s <a href={"https://help.salesforce.com/s/articleView?id=000386929&type=1"} className={"link"}>Identify your current API version</a> article for assistance.</li>
                        <li>Enter your Salesforce domain. For example, mycompany.my.salesforce.com. Refer to Salesforce’s <a href={"https://help.salesforce.com/s/articleView?id=sf.domain_name_overview.htm&type=5"} className={"link"}>My Domain</a> article for assistance.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Connect the Snowflake Data Store">
                <div className="ug-content-main-header">Connect the Snowflake Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, Snowflake.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Snowflake</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your Snowflake account name. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/admin-account-identifier.html#label-account-name"} className={"link"}>Account</a> article for more information.</li>
                        <li>Enter your Snowflake account domain. For example, snowflakecomputing.com</li>
                        <li>Select an authentication type: 
                            <ul>
                                <li>BASIC - authenticate with a username and password.</li>
                                <li>OAUTH - authenticate with Snowflake OAuth 2.0.</li>
                            </ul>
                        </li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Connect the Tableau Data Store">
                <div className="ug-content-main-header">Connect the Tableau Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>BI</strong>.</li>
                        <li>Enter a data store name. For example, Tableau.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Tableau Server</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your base URL used to log in to Tableau.</li>
                        <li>Enter the re-synchronize interval in minutes to indicate how often you want to sync with the Tableau server.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Connect the MongoDB Data Store">
                <div className="ug-content-main-header">Connect the MongoDB Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, MongoDB.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>MongoDB Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li><strong>Optional</strong>: Select <strong>Connect to MongoDB Atlas Cloud Database</strong> if you are using Atlas Cloud.</li>
                        <li>Enter your MongoDB host name or IP address. For example, sample.host. For MongDB Atlas, enter the replica nodes separated by a comma. You can find a list of replica nodes MongoDB &gt; Deployment &gt; Database in the database overview page. 
                            Do not include the port number that is listed after each replica node.</li>
                        <li>Enter your MongoDB port number. 27017 is the default port. 
                            For MongoDB Atlas, you can find the port number listed after the replica node in MongoDB &gt; Deployment &gt; Database.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Connect the MySQL Data Store">
                <div className="ug-content-main-header">Connect the MySQL Data Store</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, MySQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>MySQL</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your MySQL host DNS name or IP address. The SQL query SHOW VARIABLES WHERE Variable_name = 'hostname"; shows you the host name.</li>
                        <li>Enter your MySQL port. 3306 is the default port. The SQL query "SHOW VARIABLES WHERE Variable_name = 'port'; shows you the port. </li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Data Store Configuration Parameters">
                <div className="ug-content-main-header">Data Store Configuration Parameters</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <div>
                        <p><b>Adobe Cloud Platform (Beta)</b></p>
                        <ul>
                            <li>Adobe API Domain name</li>
                            <li>Adobe Auth API Domain</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Amazon S3</b></p>
                        <ul>
                            <li>None</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Azure Data Lake</b></p>
                        <ul>
                            <li>
                                <p>Auth Token Endpoint<br/>
                                    For example:<br/>
                                    https://login.microsoftonline.com/d26466c2-6ed1/oauth2/token
                                </p>

                                <p>The format:<br/>
                                    https://login.microsoftonline.com/&lt;tenant id&gt;/oauth2/token
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Cisco Information Server (CIS)</b></p>
                        <div>
                            <p>For the CIS 7.0 adapter.</p>
                            <ul>
                                <li>Host: The CIS Server host name or IP address.</li>
                                <li>Port: The CIS Server service port number.</li>
                                <li>Domain: The CIS domain.</li>
                            </ul>
                        </div>
                    </div>

                    <div>
                        <p><b>Google Analytics</b></p>
                        <ul>
                            <li>None</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Google BigQuery</b></p>
                        <ul>
                            <li>None</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Google Cloud Storage</b></p>
                        <ul>
                            <li>None</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Google Sheets</b></p>
                        <ul>
                            <li>None</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Greenplum</b></p>
                        <ul>
                            <li>Host: The Greenplum instance host name or IP address.</li>
                            <li>Port: The Greenplum instance port number. Default: 5432.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>HDFS</b></p>
                        <p>For Amazon EMR 5.8 HDFS, EMR 5.11.1 HDFS, Apache HDFS 2.5.0, 2.6.0, 2.7.1, or 2.7.3, Cloudera HDFS 5.3.0, 5.7, or 5.12.1, HDP HDFS 2.4 or 2.6, Microsoft HDInsight HDFS 3.4 or 3.6, and Mapr HDFS 4.0.2 adapters.</p>
                        <ul>
                            <li>Core-Site.xml: Upload the file.</li>
                            <li>HDFS-Site.xml: Upload the file.</li>
                            <li>Mapred-Site.xml: Upload the file.</li>
                            <li>Yarn-Site.xml: Upload the file.</li>
                            <li>Authentication Mechanism: The authentication mechanism. SIMPLE (default) or KERBEROS.</li>
                        </ul>
                        <p><b>Service Principal</b><br/>
                            If the Authentication Mechanism is KERBEROS: Provide the HDFS service principal name, omitting the realm. Example: If the service principal name is hdfs/server.example.com@CLOUDERA, enter hdfs/server.example.com.
                        </p>
                        <p><b>Realm</b><br/>
                            If the Authentication Mechanism is KERBEROS: Provide the HDFS service realm name, in uppercase. Example: If the realm name is hdfs/server.example.com@CLOUDERA, enter CLOUDERA.<br/>
                            For example:<br/>
                            https://platform.adobe.io/data/foundation

                        </p>
                    </div>

                    <div>
                        <p><b>Hive</b></p>
                        <p>For Amazon EMR 5.8 Hive, EMR 5.11.1 Hive, Apache Hive 0.13.1, 1.1.0, and 1.2.1, Cloudera Hive 5.3.0 and 5.7, HDP Hive 2.4, Microsoft HDInsight 3.4 or 3.6 Hive, and Mapr Hive 4.0.2  adapters.</p>
                        <ul>
                            <li>Hive-Site.xml: Upload the hive-site.xml file.</li>
                            <li>HiveServer2: Hostname</li>
                            <li>HiveServer2 instance host name. Default: localhost.</li>
                            <li>HiveServer2 Port: The HiveServer2 instance port number. Default: 10000.</li>
                            <li>Authentication Mechanism: The authentication mechanism. SIMPLE (default) or KERBEROS.</li>
                            <li>Service Principal - If Authentication Mechanism is KERBEROS: Provide the hive service principal name, omitting the realm. Example: If the service principal name is hive/server.example.com@CLOUDERA, enter hive/server.example.com.</li>
                            <li>Realm - If Authentication Mechanism is KERBEROS: Provide the hive service realm name, in uppercase. Example: If the service principal name is hive/server.example.com@CLOUDERA, enter CLOUDERA.</li>
                            <li>Authorization Mechanism - If Authentication Mechanism is KERBEROS: Indicate whether you want to use Apache Sentry for authorization. If you do not want to use Sentry, choose None.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>IBM DB2 AS/400</b></p>
                        <p>For IBM DB2 on AS/400 V5R2 or V6R1 adapter.</p>
                        <ul>
                            <li>Host: The DB2 AS400 instance host name. Default: localhost.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>IBM DB2</b></p>
                        <p>For IBM DB2 10.5 adapter.</p>
                        <ul>
                            <li>Host: The DB2 instance host name or IP address. Default: localhost.</li>
                            <li>Port: The DB2 instance port number. Default: 50000.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Marketo</b></p>
                        <p>For Marketo adapter. To obtain the values for these parameters, log in to your Marketo account. Click your name menu and click the My Account menu item. In the left navigation, click to expand Integration, then Web Services. In the Manage Web Services tab, you will find the values for SOAP API endpoint and REST API endpoint.</p>
                        <ul>
                            <li>SOAP Endpoint - Marketo SOAP endpoint.</li>
                            <li>REST Endpoint - Marketo REST endpoint.</li>
                            <li>Page Size - (Optional) Batch size to use when fetching results from the source. Default: 500.
                            </li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Azure Blob</b></p>
                        <ul>
                            <li>None.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Microsoft Dynamics CRM</b></p>
                        <p>Add the Online CRM URL for the Microsoft Dynamics CRM adapter.</p>
                        <ul>
                            <li>For example, https://myorg.crm.dynamics.com/</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>MongoDB</b></p>
                        <p>For the MongoDB 3.4 adapter.</p>
                        <ul>
                            <li>Host - MongoDB instance hostname or IP address. Default: localhost.</li>
                            <li>Port - MongoDB instance port number</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>MySQL</b></p>
                        <p>For MySQL 5.7 adapter.</p>
                        <ul>
                            <li>Host - MySQL instance host name or IP address. Default: localhost.</li>
                            <li>Port - MySQL instance port number. Default: 5432.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Netezza</b></p>
                        <p>For the Netezza 7.2 adapter.</p>
                        <ul>
                            <li>Host - The Netezza instance host name or IP address. Default: localhost.</li>
                            <li>Port - The Netezza instance port number. Default: 5480.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>NetSuite CRM and NetSuite ERP</b></p>
                        <ul>
                            <li>None</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Oracle</b></p>
                        <p>For the Oracle 12.1 adapter.</p>
                        <ul>
                            <li>Host - Oracle instance host name. Default: localhost.</li>
                            <li>Port - Oracle instance port number. Default: 1521.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>PostgreSQL</b></p>
                        <p>For the PostgreSQL 9.3 adapter:</p>
                        <ul>
                            <li>Host - PostgreSQL instance host name or IP address. Default: localhost.</li>
                            <li>Port - PostgreSQL instance port number. Default: 5432.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Postgres OpenEdge</b></p>
                        <p>For OpenEdge adapters.</p>
                        <ul>
                            <li>Host - OpenEdge instance host name or IP address. Default: localhost.</li>
                            <li>Port - OpenEdge instance port number.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Redshift</b></p>
                        <p>For the Amazon Redshift adapter. In addition to the fields in the configuration dialog, additional steps might be required for some deployments, as described below.</p>
                        <ul>
                            <li>Host - Redshift cluster host name or IP address.</li>
                            <li>Port - Redshift cluster port number. Default: 5439.</li>
                        </ul>

                        <p>Additional Configuration</p>
                        <ul>
                            <li>Due to a known issue that is caused by the inherent behavior of sqoop, if your Hive Metastore is using PostgreSQL, you need to do some additional configuration steps.</li>
                            <li>The Redshift JDBC conflicts with the PostgreSQL JDBC that is used to connect the Hive Metastore. As a result, whenever DCP attempts to import data into Hive, the operation fails. Specifically, this is caused when the sqoop job runs and attempts to import data. The Redshift JDBC is in the sqoop/lib directory.</li>
                            <li>If a different database is used, such as MySQL, there is no conflict. For PostgreSQL, there is a workaround. Append the following option○to○the connectionURL○parameter in the○hive-site.xml○configuration file on the DCP host (or wherever sqoop is running):<br/>>
                                <span className={"font-weight-300"}>OpenSourceSubProtocolOverride=true</span>
                            </li>
                        </ul>
                        <p>For example, if the connectionURL in hive-site.xml is set to:<br/>
                            <span className={"font-weight-300"}>jdbc:postgresql://unifi-host:5432/metastore</span>
                        </p>
                        <p>Change it to:<br/>
                            <span className={"font-weight-300"}>jdbc:postgresql://unifi-host:5432/metastore?OpenSourceSubProtocolOverride=true</span>
                        </p>
                        <p>
                            The following example shows how this element would appear in the file:<br/>
                            <span className={"font-weight-300"}>
									&lt;property&gt;<br/>
                                &lt;name&gt;javax.jdo.option.ConnectionURL&lt;/name&gt;<br/>
                                &lt;value&gt;jdbc:postgresql://unifi-host:5432/metastore?OpenSourceSubProtocolOverride=true&lt;/value&gt;<br/>
                                &lt;description&gt;JDBC connect string for a JDBC metastore&lt;/description&gt;<br/>
                                &lt;/property&gt;<br/>
								</span>
                            For more information about OpenSourceSubProtocolOverride, see the Amazon Redshift documentation at:<br/>
                            <a href={"http://docs.aws.amazon.com/redshift/latest/mgmt/configure-jdbc-options.html"} className={"link"}>http://docs.aws.amazon.com/redshift/latest/mgmt/configure-jdbc-options.html</a>
                        </p>
                    </div>

                    <div>
                        <p><b>Remote Filesystem</b></p>
                        <p>For the remote filesystem datastore:</p>
                        <ul>
                            <li>Host - The remote file system host name or IP address. Default: localhost.</li>
                            <li>Port - The remote file system SSH port. Default: 22.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Salesforce</b></p>
                        <ul>
                            <li>None.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>SAP ASE</b></p>
                        <p>For SAP ASE adapter.</p>
                        <ul>
                            <li>Host: SAP ASE instance host name. Default: localhost.</li>
                            <li>Port: SAP ASE instance port number. Default: 5000.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>SAP Hana</b></p>
                        <p>For SAP Hana adapter.</p>
                        <ul>
                            <li>Primary Host - SAP Hana instance host name or IP address. Default: localhost.</li>
                            <li>Primary Host Port - SAP Hana instance port number. Default: 30015.</li>
                            <li>Failover Host - SAP Hana failover instance host name or IP address.</li>
                            <li>Failover Host Port - SAP Hana failover instance port number.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>ServiceNow</b></p>
                        <ul>
                            <li>Host - The ServiceNow host. Default: instance.service-now.com.</li>
                            <li>Encoding - (Optional) Character encoding used. Default: utf-8.</li>
                            <li>Batch size - (Optional) Automatically divide a large request into smaller requests of this maximum size. Default: 1000.</li>
                            <li>Threads - (Optional) Performs parallel requests to ServiceNow API to retrieve large tables in batches. Default: 5.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>SnowFlake</b></p>
                        <p>For SnowFlake adapter.</p>
                        <ul>
                            <li>Account - The Snowflake account name.</li>
                            <li>Account Domain - The Snowflake account region domain.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>SQL Server</b></p>
                        <p>For Microsoft SQL Server 8.0, 11.0, 12.0 or Azure SQL Database adapter.</p>
                        <ul>
                            <li>Host - SQL Server instance host name. Default: localhost.</li>
                            <li>Port - SQL Server instance port number. Default: 1433.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Tableau</b></p>
                        <ul>
                            <li>Base URL - The Tableau server host name or IP address.</li>
                            <li>Resynchronize time parameters.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Teradata</b></p>
                        <p>For Teradata 15.0 adapter.</p>
                        <ul>
                            <li>Host - Teradata instance host name. Default: localhost.</li>
                            <li>Port - Teradata instance port number. Default: 1025.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Twitter</b></p>
                        <ul>
                            <li>None.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>YouTube Analytics</b></p>
                        <ul>
                            <li>None.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Zuora</b></p>
                        <ul>
                            <li>The Zuora API domain</li>
                            <li>OAuth Client ID</li>
                            <li>OAuth Client Secret</li>
                            <li>(Optional) The Zuora account ID.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default DataStores;
