import React from "react";

function TransformJobs() {
  return (
    <section>
      <div
        className="ug-content-wrapper"
        data-content="Transform Jobs"
      >
        <div className="ug-content-main-header">Transform Jobs</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            A transform job is a set of rules and functions that take in the raw
            data from one or more datasets and process the data into a form that
            provides answers to business information needs.
          </p>
          <p>
            Much of a business analyst user’s time is typically spent creating
            and running transform jobs.
          </p>
          <p>Types of jobs</p>
          <ul>
            <li>Prep Job</li>
            <li>OS Job</li>
            <li>Local Import</li>
            <li>SQL Job</li>
          </ul>
          <p>
            <b>Note:</b> OS Jobs are only support in the DCP On-Premesis edition
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/jobs_create_1.png")}
            />
          </figure>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Prep Jobs">
        <div className="ug-content-main-header">Prep Jobs</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            A prep job is a set of rules and functions that take in the raw data
            from one or more datasets and process the data into a form that
            provides answers to business information needs.
          </p>
          <p>
            Much of a business analyst user’s time is typically spent creating
            and running prep jobs.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Viewing Prep Jobs"
      >
        <div className="ug-content-main-header">Viewing Prep Jobs</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            The Jobs screen lists the jobs for which the logged-in user is the
            owner or has permissions.
          </p>
          <p>
            In the left navigation bar, click Prepare. In the summary page you
            can see jobs for which you have permissions.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Prep Job Summary Cart"
      >
        <div className="ug-content-main-header">Prep Job Summary Cart</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Summary cart will be open automatically and respective step data
            will be displayed. Rest of the steps will remain collapsed. User can
            expand/collapse steps in summary cart.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_summary_cart.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Viewing individual Prep Job Details"
      >
        <div className="ug-content-main-header">
          Viewing individual Prep Job Details
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            You can view the details of a specific transform job by clicking the
            Detail icon.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/62.png")}
            />
          </figure>
        </div>
      </div>

      {/*<div className="ug-content-wrapper" data-content="Job summary info panel">*/}
      {/*    <div className="ug-content-main-header">Job summary info panel</div>*/}
      {/*    <div className="col-12 col-md-8 col-lg-9 ug-content">*/}
      {/*        <p>From the summary info panel you can:</p>*/}
      {/*        <ul>*/}
      {/*            <li>Edit the Description and tags</li>*/}
      {/*            <li>Open Chat (LABS required)</li>*/}
      {/*            <li>From Chat, request permissions to an object</li>*/}
      {/*        </ul>*/}
      {/*    </div>*/}
      {/*</div>*/}

      <div
        className="ug-content-wrapper"
        data-content="Overview of Creating a Prep Job"
      >
        <div className="ug-content-main-header">
          Overview of Creating a Prep Job
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            To perform an analysis on the wealth of available data, define one
            or more prep jobs. This is the key part of DCP software that is used
            by business analysts from day to day. Users with the Data Analyst or
            Administrator role can create prep jobs.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Creating a Prep Job"
      >
        <div className="ug-content-main-header">Creating a Prep Job</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            The prep job is defined through a six-step wizard in the DCP user
            interface. We have provided step-by-step instructions for each
            wizard panel:
          </p>
          <ol>
            <li>Data Source and Dataset</li>
            <li>Join Datasets</li>
            <li>Enriching the Data with Derived Attributes</li>
            <li>Choose Columns</li>
            <li>Add Filter</li>
            <li>Aggregate</li>
            <li>Output</li>
          </ol>
          <p>
            You must start at step 1, Data Source and Dataset. Typically, you
            would then follow the steps in order. However, you can click back to
            an earlier panel at any time to modify your previous choices.
            Changing early choices might make later choices invalid or
            unavailable. For example, if you return to step 2 and change the
            joined dataset, you might take away the dataset that provided some
            of the columns you selected in step 4.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_create_1.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Data source and Dataset"
      >
        <div className="ug-content-main-header">Data source and Dataset</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Before you start these steps, you should know which data source and
            dataset you will be using.
          </p>
          <ol>
            <li>
              In the left navigation bar click the plus icon under Build , then
              click Job.
            </li>
            <li>
              Provide a name and optional brief description for the new job. 
              You can enter an HTML anchor tag to create a hyperlink.</li>
            <li>(Optional) Define tags if desired. See Using Tags.</li>
            <li>
              Click <b>CREATE</b>.
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/63.png")}
                />
              </figure>
              <p>
                Now you have launched the job definition wizard, which will walk
                you through the phases of creating a data transform job. The
                first phase is the <b>SELECT DATASET</b> panel.
              </p>
            </li>
            <li>
              <p>Scroll through the list of datasets. Or you can click the search
              icon’s drop-down arrow, then choose "Name" to search for a
              particular dataset by name or "Source" to search by data source
              name. To choose from a list of data sources, click the Data
              sources icon. If tags have been defined for your data sources, you
              can narrow down the list by clicking one of the labels in the TAGS
              column.</p>
              <p>Choose the dataset you want to use.</p>
              <p>Troubleshooting: No dataset listed. When a Hadoop, Amazon S3, or
                other remote filesystem data source is used for the first time,
                the Datasets list is empty. For information about how to add
                datasets, see Creating a Dataset.</p>
            </li>
            <li>
              (Optional) To take a look at the data inside the dataset, click{" "}
              <b>SAMPLE DATA</b>.
              <p>DCP displays a few rows of data from the selected dataset. You
                can narrow down the displayed columns by clicking the Search
                icon and typing part of the column name. When you are finished,
                click SAMPLE DATA again to return to the previous screen.</p>
            </li>
            <li>
              Click <b>JOIN DATASETS</b> to go on to the next step in defining a
              transform job.
            </li>
          </ol>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Join Datasets">
        <div className="ug-content-main-header">Join Datasets</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            <i>
              This procedure is part of creating a transform job. In the steps
              below, it is assumed you have started creating a transform job and
              reached the Join Datasets panel.
            </i>
          </p>
          <p>
            A join is a logical connection between two sets of data, based on
            matching values in certain fields. New data rows are created that
            contain values from both datasets.
          </p>
          <p>
            For example, if you have a database table that contains customer
            addresses and another database table that contains customer orders,
            the two tables can be joined on the customer name. In this way, the
            address information is associated with each order.
          </p>
          <p>
            In DCP, joins are used when creating transform jobs. The DCP user
            interface provides graphical guidance and suggestions to make
            creating joins easier. You will typically just need to click "Save"
            to accept the default join suggestions provided. However, to get the
            most out of your transform jobs, it can be useful to learn more
            about joins. For example, joins come in various types, such as inner
            joins and outer joins. The join type affects how the join operates.
          </p>
          <p>
            Joins are a major topic in the field of data storage. If you are not
            familiar with joins, you can learn more from the large amounts of
            information available in print and online.
          </p>
          <p>
            In the Join Datasets panel, you can define relationships between
            multiple datasets.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="How to Define a Join"
      >
        <div className="ug-content-main-header">How to Define a Join</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            This step is optional. To skip it, click the Enrich tab and continue
            with Enriching the Data with Derived Attributes.
          </p>
          <ol>
            <li>
              Click <b>JOIN DATASETS</b>.
            </li>
            <li>
              Based on the data in the first selected dataset, DCP suggests
              additional dataset that can be joined. The suggestions are
              displayed in the OneClick Joins<sup>TM</sup> tab.
              <p>
                Click the dataset you want to add to the join. Repeat this step
                to add more joins from this tab.
              </p>
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/64.png")}
                />
              </figure>
            </li>
            <li>
              If you don’t want to use any of the suggested joins, or you want
              to define additional joins, click the <b>BUILD YOUR OWN</b> tab
              and choose a different dataset.
              <p>
                DCP finds the matching key columns and suggests one or more
                recommended joins in the <b>RECOMMENDATIONS</b> tab.
              </p>
              <p>
                If the recommended join is not what you need, click{" "}
                <b>BUILD YOUR OWN</b> again. To change the type of join, choose
                Inner Join, Right Outer Join, Left Outer Join, or Full Outer
                Join. If you want to choose different dataset column names to
                use in the join, use the column picker to choose new column
                names for the left and right sides of the join.
              </p>
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/65.png")}
                />
              </figure>
            </li>
            <li>
              Based on the dataset that was just joined, DCP might suggest
              another dataset that can be joined. If you want to continue,
              repeat these steps to add more joins.
            </li>
            <li>
              To modify,swap or delete a join, hover the cursor over the join
              and click more option dots Edit, swap or remove.
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/66.png")}
                />
              </figure>
              <p>
                When you click EDIT, the left side of the panel changes to the
                Build Your Own view. To change the type of join, choose Inner
                Join, Right Outer Join, Left Outer Join, or Full Outer Join. If
                you want to choose different dataset column names to use in the
                join, use the column picker to choose new column names for the
                left and right sides of the join.
              </p>
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/67.png")}
                />
              </figure>
              <p>
                When you are finished editing the join, click <b>DONE</b>.
              </p>
            </li>
            <li>
              (Optional) If you click <b>SAMPLE DATA</b>, you can now see sample
              data from all the joined datasets.
            </li>
            <li>
              Click <b>ENRICH DATA</b> to go on to the next step in defining a
              transform job.
            </li>
            <li>
              Dataset information details
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/prep_job_dataset_info.png")}
                />
              </figure>
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/prep_job_dataset_info_1.png")}
                />
              </figure>
            </li>
            <li>
              Column info details
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/prep_job_column_info_1.png")}
                />
              </figure>
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/prep_job_column_info_2.png")}
                />
              </figure>
            </li>
            <li>
              Click ENRICH DATA to go on to the next step in defining a prep
              job.
            </li>
          </ol>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Enrich Data With Derived Attributes"
      >
        <div className="ug-content-main-header">
          Enrich Data With Derived Attributes
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            <i>
              This procedure is part of creating a transform job. In the steps
              below, it is assumed you have started creating a transform job and
              reached the Enrich Data panel.
            </i>
          </p>
          <p>
            A derived attribute is a way to obtain the desired value by
            performing arithmetic calculations or other operations on the values
            of columns in a dataset. The derivation is performed on each row
            (record) of the dataset.
          </p>
          <p>
            Using derived attributes can make the output of a transform job more
            meaningful and readable. Instead of scanning through multiple
            columns looking for all the related pieces to form a single concept,
            get the big picture in a single column. Instead of writing programs
            or spreadsheets to perform calculations on the returned data,
            perform those calculations right in the transform job, and let it
            hand you the answers.
          </p>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Example">
        <div className="ug-content-main-header">Example</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Suppose you have a dataset containing information about employees.
            For each employee, you have individual columns containing the first
            name, last name, and monthly salary.
          </p>
          <table>
            <thead>
              <tr>
                <th>firstname</th>
                <th>lastname</th>
                <th>Monthly</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Merri</td>
                <td>Seals</td>
                <td>6695.50</td>
              </tr>
              <tr>
                <td>Eugena</td>
                <td>Kingsley</td>
                <td>12100</td>
              </tr>
            </tbody>
          </table>
          <p>You could create derived attributes like the following:</p>
          <ul>
            <li>
              Multiply an employee’s monthly salary by twelve to find the yearly
              salary
            </li>
            <li>
              Derive a person’s full name by putting the first name and last
              name together
            </li>
          </ul>
          <table>
            <thead>
              <tr>
                <th>name</th>
                <th>yearly</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Merri seals</td>
                <td>80346</td>
              </tr>
              <tr>
                <td>Eugena Kingsley</td>
                <td>145200</td>
              </tr>
            </tbody>
          </table>
          <p>
            The derived attribute is given a name and added to the job’s result
            set. It looks just like any other column. The difference is that the
            value is not stored in any column in the source dataset; it is the
            result of some calculation or processing that is done on one or more
            underlying columns in the dataset.
          </p>
          <p>
            Derived attributes can be referred to by name and used like any
            other column in the job’s result set. For example, you can group by
            or sort by the derived attribute. And if the result set is saved and
            used as a data source for other jobs, the derived attribute can be
            used as a building block for further derived attributes in those
            jobs.
          </p>
          <p>
            A prep job that includes derived attributes might, in fact, return
            no columns at all from the underlying dataset. Instead, the output
            might be entirely made up of derived attributes, providing a more
            compact and more customized view of the data.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="How to Add a Derived Attribute in a Prep Job"
      >
        <div className="ug-content-main-header">
          How to Add a Derived Attribute in a Prep Job
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>In the Enrich Data panel, you can define derived attributes.</p>
          <p>
            This step is optional. To skip it, click the Choose tab and continue
            with Choose Columns.
          </p>
          <ol>
            <li>
              Click <b>ENRICH DATA</b>.
            </li>
            <li>
              If any derived attributes have already been defined that would be
              applicable in this transform job, DCP displays these suggested
              derived attributes in the OneClick Functions<sup>TM</sup> tab.
              Click one or more of the suggestions to add it to the job. The
              selected items appear in the Saved Functions area.
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/68.png")}
                />
              </figure>
              <p>
                OneClick Function<sup>TM</sup> are derived attributes that have
                previously been defined using this job’s selected dataset(s).
                The derived attributes might have been defined in this job or in
                any other job that uses one or more of the same dataset.
              </p>
            </li>
            <li>
              After selecting a function, you can modify it. Click the function
              in the Saved Functions area.
              <p>
                Make your desired changes. When you save the job, the new
                version of the function becomes available for use by other jobs.
                If the function was already in use, the previous definition is
                replaced by the new definition.
              </p>
            </li>
            <li>
              If no OneClick Functions<sup>TM</sup> are displayed, or if you
              want to define new derived attributes, click the{" "}
              <b>BUILD YOUR OWN</b> tab.
            </li>
            <li>
              Type a name and (optional) description for the new attribute.
            </li>
            <li>
              {" "}
              In Function Editor , create a derived attribute in the Free-form
              text field so you can type in any function, including user-defined
              functions.
              <p>
                Click function and column picker icon to view the definition and
                usage of every function. Use this as a reference while you are
                defining derived attributes.
              </p>
            </li>
            <li>
              Under Expression, click on the blank line and start typing.
              <p>
                DCP displays a list of functions, arithmetic operators, and
                column names that match whatever you have typed so far. For
                example, if you begin typing "con … ", UPD displays a list
                including the conv and concat functions.
              </p>
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/69.png")}
                />
              </figure>
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/70.png")}
                />
              </figure>
              <p>
                When you see the item you want in the list, click to choose it.
                The chosen item appears in the editing area. If you choose a
                function, its syntax appears; for example, concat(string
                a,string b). The name of the function comes first (like concat)
                and any parameters to the function are represented by
                placeholder values (like string a and string b).
              </p>
              <p>
                Click one of the parameter placeholders in the function syntax,
                and DCP displays a list of valid column names that could be used
                for that parameter. For example, when you click the string,a
                parameter of the concat function, DCP displays the names of
                string type columns from the dataset.
              </p>
              <p>
                You can either click one of the suggestions in the drop-down or
                type a literal value that matches the data type of the
                parameter. For example, you could either click a dataset column
                name, like customer.c_name or type a string, like "Name: ".
              </p>
              <p>
                To enter a column name that contains a space, enclose it in
                square brackets.
              </p>
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/71.png")}
                />
              </figure>
              <p>
                You can enter expressions that are more complex than the point.
                For example:
              </p>
              <p>
                concat(customer.firstname," ",customer.lastname,", ",
                callcenter.resolutionstatus)
              </p>
              <p>
                As you are typing, whenever you come to the end of one word or
                symbolic group, DCP will pause briefly to parse your input and
                verify that what you have typed is valid. This real-time
                assistance helps make sure you are entering a valid expression.
              </p>
              <p>
                You can enter Hive user-defined functions (UDFs). A UDF is a way
                to write code to extend the capabilities of Hive. If the
                built-in Hive functions do not meet your needs, you can extend
                Hive by writing a custom UDF.
              </p>
              <p>
                Custom UDFs are not created using DCP software. They are written
                in the Java programming language. Each custom UDF extends the
                Hive UDF class and implements the evaluate method. Inside this
                evaluate method, write the code to perform whatever function you
                need on the data. Compile the code, upload it to your Hive
                cluster, and run a few Hive console commands to make the UDF
                available for use. The UDF can then be used in derived attribute
                definitions just like any other Hive function. For more
                information about creating custom UDFs, see:
              </p>
              <ul>
                <li>
                  <u>cwiki.apache.org/confluence/display/Hive/HivePlugins</u>
                </li>
                <li>
                  <u>
                    snowplowanalytics.com/blog/2013/02/08/writing-hive-udfs-and-serdes
                  </u>
                </li>
              </ul>
              <p>
                You can try an example UDF from these documents in the DCP
                Advanced view, perhaps with some modifications to match the
                column names in your datasets.
              </p>
              <p>
                Error icon will be displayed if the function is not completed
                and the syntax is incorrect. When the function is complete, Done
                button will be enabled.
              </p>
            </li>
            <li>
              Click <b>DONE</b>.
            </li>
            <li>
              (Optional) To add more derived attributes, repeat these steps.
            </li>
            <li>
              (Optional) To take a look at the data inside the dataset, click{" "}
              <b>SAMPLE DATA</b>. To see an updated view of the sample data
              including all choices made so far, click <b>PREVIEW</b>.
              <p>The preview data now includes the derived attributes.</p>
              <p>
                When you are finished, click <b>SAMPLE DATA</b> again to return
                to the derived attributes screen.
              </p>
            </li>
            <li>
              When you are finished adding derived attributes, click the Choose
              tab.
              <p>Continue with the instructions at Choose Columns.</p>
            </li>
          </ol>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Choose Columns"
      >
        <div className="ug-content-main-header">Choose Columns</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            <i>
              This procedure is part of creating a transform job. In the steps
              below, it is assumed you have started creating a transform job and
              reached the Choose Columns panel.
            </i>
          </p>
          <p>
            In the Choose Columns panel, you select which subset of the data you
            want to include in the job output.
          </p>
          <p>
            If you define derived attributes in the Enrich Data panel, this step
            is optional. To skip it, click <b>ADD FILTER</b> and continue with
            Add Filter.
          </p>
          <ol>
            <li>
              Click the names of the columns you want to include in the output
              of the transform job. The columns you choose depend solely on the
              intended use of the transform job. To narrow down the columns,
              click the search icon and start typing part of the column name.
              <p>
                The number of columns selected is shown in the counter next to
                the <b>VIEW SELECTED COLUMNS</b> link.
              </p>
              <p>
                For example, in the following job, 7 columns are selected. The
                total number of columns is shown as 8 because there is 1 derived
                attribute defined in this job.
              </p>
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/72.png")}
                />
              </figure>
            </li>
            <li>
              (Optional) To get more details about the columns that are selected
              so far, click <b>VIEW SELECTED COLUMNS</b>. A table is displayed
              that shows more information about each column, including the
              source dataset, data type, and (for derived attributes) the
              defining expression. To return to the main Columns panel, click{" "}
              <b>ADD MORE COLUMNS</b>.
            </li>
            <li>
              (Optional) To take a look at the data inside the dataset, click{" "}
              <b>SAMPLE DATA</b>. To see an updated view of the sample data
              including all choices made so far, click <b>PREVIEW</b>.
              <p>The preview data now includes the chosen columns.</p>
              <p>
                When you are finished, click <b>SAMPLE DATA</b> again to return
                to the columns screen.
              </p>
            </li>
            <li>
              When you are finished, click <b>ADD FILTER</b>.
              <p>The Add Filter panel appears.</p>
            </li>
          </ol>
          <p>View column level panel details against every columns</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/choose_columns_1.png")}
            />
          </figure>
          <p>
            Clicking on details icon against column gives complete information
          </p>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Add Filter">
        <div className="ug-content-main-header">Add Filter</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            <i>
              This procedure is part of creating a transform job. In the steps
              below, it is assumed you have started creating a transform job and
              reached the Filters panel.
            </i>
          </p>
          <p>
            In the Add Filter panel, you can set up conditions that will affect
            which rows are included in the job’s results. Filters are useful
            when you want to include only data that meets certain criteria, such
            as only customers above a certain income level or only years when
            the average rainfall was below a certain amount.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_filter_1.png")}
            />
          </figure>
          <p>This step is optional. To skip it, click Aggregate.</p>
          <ol>
            <li>
              Click <b>ADD FILTER</b>.
            </li>
            <li>
              If any filters have already been defined that would be applicable
              in this transform job, DCP displays these suggested filters in the
              OneClick Filters™ tab. Click one of the suggestions to add it to
              the job. The selected item appears in the Saved Filter area. A job
              can have only one filter.
              <p>
                OneClick Filters<sup>TM</sup> are filters that have previously
                been defined using this job’s selected dataset(s) and columns.
                The filters might have been defined in this job or in any other
                job that uses one or more of the same dataset columns.
              </p>
            </li>
            <li>
              After selecting a filter, you can modify it. Hover over the filter
              in the Saved Filter area and click <b>EDIT</b>.
              <p>
                Make your desired changes to the filter. When you save the job,
                the new version of the filter becomes available for use by other
                jobs. If the filter was already in use, the previous definition
                is also saved and remains in use in those jobs. In this case,
                you will see two OneClick Filters<sup>TM</sup> with the same
                name but different definitions.
              </p>
              <p>
                If no OneClick Filters<sup>TM</sup> are displayed, or if you
                want to define a new filter, click the <b>BUILD YOUR OWN</b>{" "}
                tab.
              </p>
            </li>
            <li>Give the filter a name and (optional) description.</li>
            <li>
              Click <b>ADD CONDITION</b>.
            </li>
            <li>
              In Select a column, click the down arrow. From the drop-down list,
              choose the column to which you want to apply screening criteria.
              <p>
                <b>Troubleshooting:</b> The desired column is not shown. Filters
                are based on columns in the job results. If the columns you want
                are not in the drop-down list, then it was not selected for
                inclusion in the results. You can click the Choose Columns panel
                again and add the desired column as described in Choose Columns.
              </p>
            </li>
            <li>
              Choose the logical operator (=, &lt;, etc.)
              <p>
                For more information about the operators, refer to SQL
                documentation.
              </p>
            </li>
            <li>
              In the second Columns field, choose a column name or type a value.
              <p>
                Typing a value will work only if the column’s data type and the
                operator are compatible. For example, with string columns, use
                the LIKE operator rather than the equal sign (=).
              </p>
            </li>
            <li>
              (Optional) Click <b>ADD CONDITION</b> again to define another test
              that the data must pass.
            </li>
            <li>
              (Optional) Choose between the logical operators AND and OR. This
              choice is needed only if you define more than one condition. The
              choice you make here will be used to connect all the conditions as
              a group.
            </li>
            <li>
              (Optional) Use <b>ADD GROUP</b> to create groups of conditions
              that are evaluated together. The result for the entire group can
              then be used as a value in a larger expression. This is also
              useful when you want to combine both AND and OR conditions in a
              single filter. The grouped conditions are placed inside
              parentheses in the filter expression.
            </li>
            <li>
              When you are finished, click <b>AGGREGATE</b>.
            </li>
          </ol>
          <p>View dataset info details</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_filter_2.png")}
            />
          </figure>
          <p>Clicking details icon gives dataset information</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_filter_3.png")}
            />
          </figure>
          <p>View Column info details</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_filter_4.png")}
            />
          </figure>
          <p>Clicking details icon gives column information</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_filter_5.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Add Filter using sample data drawer"
      >
        <div className="ug-content-main-header">
          Add Filter using sample data drawer
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_add_filter_1.png")}
            />
          </figure>
          <p>Review Expression</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_add_filter_2.png")}
            />
          </figure>
          <p>Viewing dataset details in sample data drawer</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_add_filter_3.png")}
            />
          </figure>
          <p>Viewing column details in sample data drawer</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_add_filter_4.png")}
            />
          </figure>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Aggregate">
        <div className="ug-content-main-header">Aggregate</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            <i>
              This procedure is part of creating a transform job. In the steps
              below, it is assumed you have started creating a transform job and
              reached the Aggregation panel.
            </i>
          </p>
          <p>
            In the Aggregate panel, you determine which result set columns will
            be displayed in the row (SELECT), which will be used to group result
            rows (GROUP), and apply aggregate functions to summarize the data in
            groups of rows (COUNT, SUM, etc.).
          </p>
          <ol>
            <li>
              Click the <b>SELECT</b> box next to the rows you want to display
              in the result set, or click <b>SELECT ALL</b>.
            </li>
            <li>
              Click the <b>GROUP</b> box next to the rows you want to use as the
              basis for data grouping, or click <b>GROUP ALL</b>.
            </li>
            <li>
              Click the boxes for any desired aggregate functions, such as{" "}
              <b>COUNT</b>.
              <p>
                This will create a summary column. For more information about
                aggregate functions, see SQL documentation.
              </p>
            </li>
            <li>
              Click <b>SAVE</b> or <b>SAVE & RUN</b>. If you chose RUN, see
              Running a Job and Specifying Output Options for next steps.
            </li>
          </ol>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_aggregate_1.png")}
            />
          </figure>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Output">
        <div className="ug-content-main-header">Output</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Output allows the user to view all of the selected columns and map
            those columns against the output, including reordering the columns
            as necessary.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/73.png")}
            />
          </figure>
          <p>There are different output types:</p>
          <ul>
            <li>Adobe Campaign Standard</li>
            <li>Adobe Experience Platform</li>
            <li>Amazon S3</li>
            <li>BigQuery</li>
            <li>Dynamic 365</li>
            <li>Greenplum</li>
            <li>HDFS</li>
            <li>HIVE</li>
            <li>MySQL</li>
            <li>Redshift</li>
            <li>SQL Server</li>
          </ul>
          <p>
            If you configure the job output to target a file system, you can
            turn on <strong>Import Data Set</strong>
            to automatically import the file as a data set.
          </p>
          <p>
            The UI allows you to map your output columns against your source by
            rearranging the columns.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_output_1.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Multiple Outputs"
      >
        <div className="ug-content-main-header">Multiple Outputs</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>Add Output</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_add_output_1.png")}
            />
          </figure>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_add_output_2.png")}
            />
          </figure>
          <p>Options for existing Outputs</p>
          <ol>
            <li>Configure Output</li>
            <li>Clone</li>
            <li>Remove</li>
          </ol>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_add_output_3.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Copying or Editing a job"
      >
        <div className="ug-content-main-header">Copying or Editing a job</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Instead of creating a new job from scratch, you might want to start
            from an existing job and modify it. You can create a clone, or copy,
            of an existing job.
          </p>
          <p>
            You can edit a previously saved job to make changes to the dataset,
            join, derived attributes, columns, or any other aspect of the job.
            When you do, a new numbered version of the job is saved. Previous
            versions of the job are available so you can access and run any
            version, compare two versions, or revert to a previous job version.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="How to Copy a Job"
      >
        <div className="ug-content-main-header">How to Copy a Job</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <ol>
            <li>
              In the left navigation bar, click Prepare. You can click the My
              View icon to see only jobs for which you have permissions or click
              the Community icon to see all the jobs created by users in your
              organization.
            </li>
            <li>
              Hover over the name of the job you want to copy. A menu icon
              (three vertical dots) appears. Click to open the menu, then choose
              Clone.
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/74.png")}
                />
              </figure>
            </li>
            <li>
              Give the new job a name, and click <b>CLONE</b>.
              <p>The new job appears in the Jobs list.</p>
            </li>
          </ol>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="How to Edit a Job"
      >
        <div className="ug-content-main-header">How to Edit a Job</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <ol>
            <li>
              In the left navigation bar, click Prepare. You can click the My
              View icon to see only jobs for which you have permissions or click
              the Community icon to see all the jobs created by users at your
              organization.
            </li>
            <li>Double-click the name of the job you want to modify.</li>
            <li>
              When you are finished making changes, click <b>SAVE</b>.
            </li>
            <li>
              In the Save Changes popup, type a comment to explain why you
              modified the job.
            </li>
            <li>
              Click <b>SAVE</b> or <b>SAVE & RUN</b>.
            </li>
          </ol>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Running a Job and Specifying Output Options"
      >
        <div className="ug-content-main-header">
          Running a Job and Specifying Output Options
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <ol>
            <li>
              When you finish defining a job, in the <b>AGGREGATE</b> panel,
              choose <b>SAVE & RUN</b>.
            </li>
            <li>
              The <b>JOB OUTPUT</b> dialog appears so you can specify the output
              location, file compression, and format conversion.
              <p>
                Click HDFS, Hive, MS SQ or any. The dialog box changes depending
                on which you select.
              </p>
              <ul>
                <li>
                  In the HDFS dialog, you can choose the output location and
                  path (folder), enable output compression and choose the
                  compression format, or choose to convert the output to
                  comma-separated value (CSV) format, Tableau Data Extract (TDE)
                  format, or Tableau Workbook (TBWX) format, and set the file
                  names for the converted files. When you are finished making
                  selections, click RUN.
                </li>
                <li>
                  In the Hive dialog, you can choose the schema and table,
                  enable output compression, and choose the compression format.
                  When you are finished making selections, click RUN.
                </li>
                <li>
                  In the <b>MS SQL</b> dialog, you can choose the schema and
                  table, enable output compression, and choose the compression
                  format. The format of the chosen table should match exactly
                  the format of the generated table. When you are finished
                  making selections, click RUN.
                  <p>
                    The <b>MS SQL</b> option is available only if the DCP
                    administrator added a datastore of that type which was
                    defined as an allowable output destination. See Adding a
                    Data Store in the DCP Administrator’s Guide.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              To run the job again later, use any of the following techniques:
              <ul>
                <li>
                  In the left navigation, click Prepare. Find the job you want
                  to run, click the name of the job, and click <b>RUN</b>.
                </li>
                <li>
                  Include the job when you define a new workflow and schedule.
                  The job will run at the interval defined in the schedule. For
                  more information see Workflows or Schedules.
                </li>
                <li>
                  In the left navigation bar, click Automate, then click
                  Schedules. In the Summary page, find the name of the schedule
                  that includes the job you want to run. Click the menu icon and
                  choose Run Immediate
                  <figure>
                    <img
                      alt="Boomi Data Catalog & Prep"
                      src={require("../assets/images/75.png")}
                    />
                  </figure>
                </li>
                <li>
                  In Activity on the Dashboard, hover over the job name. Click
                  the menu icon and choose Rerun.
                  <figure>
                    <img
                      alt="Boomi Data Catalog & Prep"
                      src={require("../assets/images/76.png")}
                    />
                  </figure>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Viewing Job Output and Logs"
      >
        <div className="ug-content-main-header">
          Viewing Job Output and Logs
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            When a transform job runs, it creates a set of result data. You can
            see sample result data in the DCP user interface. When creating a
            new transform job, it is useful to run the job and check the sample
            output so you can fine-tune the job if necessary.
          </p>
          <p>
            Jobs also emit status messages while they are running. To
            troubleshoot a job that did not complete successfully, it is helpful
            to view the message log.
          </p>
          <ol>
            <li>Click Dashboard.</li>
            <li>
              In Activity, find and click the name of the schedule that contains
              the job you are interested in.
              <p>The Activity Detail window is displayed.</p>
            </li>
            <li>
              Click <b>LOGS</b> to see whether the job ran successfully, how
              long it took, and what runtime messages were generated.
            </li>
            <li>
              Click <b>OUTPUT</b> to see the data returned by the transform job.
            </li>
            <li>
              To export the data for use in an external tool, click{" "}
              <b>EXTRACT FILES</b> and choose the file format you want (.csv,
              .tde, or .twbx), then click Generate.
            </li>
            <li>
              To store a copy of the output on your local computer, click{" "}
              <b>EXTRACT FILES</b>, hover on one of the generated files, then
              click Download.
            </li>
            <li>
              To view the underlying dataset and columns that were used to
              create the current result set, click <b>LINEAGE</b>. In the data
              lineage page, you can view the input and output of a data
              transformation to gain a more thorough understanding of job
              results.
              <figure>
                <img
                  alt="Boomi Data Catalog & Prep"
                  src={require("../assets/images/77.png")}
                />
              </figure>
              <p>
                For more information, see Running a Job and Specifying Output
                Options.
              </p>
            </li>
          </ol>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Export Job from Summary panel"
      >
        <div className="ug-content-main-header">
          Export Job from Summary panel
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            <b>Single Job Export option</b>
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_single_export.png")}
            />
          </figure>
          <p>
            <b>Multiple Job Export option</b>
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/prep_job_multi_export.png")}
            />
          </figure>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="OS Jobs">
        <div className="ug-content-main-header">OS Jobs</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/os_job_1.png")}
            />
          </figure>
          <p>
            <b>Details</b>
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/os_job_2.png")}
            />
          </figure>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Local Import">
        <div className="ug-content-main-header">Local Import</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            <b>Create flow</b>
          </p>
          <p>User can upload file with JSON format</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_local_import_1.png")}
            />
          </figure>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="SQL Jobs">
        <div className="ug-content-main-header">SQL Jobs</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            <b>Create flow</b>
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_sql_create.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Strategy-Create"
      >
        <div className="ug-content-main-header">Strategy-Create</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_sql_strategy_create.png")}
            />
          </figure>
          <p>
            <b>Import Dataset</b>
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_sql_import_dataset.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Strategy-Insert"
      >
        <div className="ug-content-main-header">Strategy-Insert</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_sql_strategy_insert.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Strategy-Overwrite"
      >
        <div className="ug-content-main-header">Strategy-Overwrite</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_sql_strategy_overwrite.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Strategy-Raw Query"
      >
        <div className="ug-content-main-header">Strategy-Raw Query</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_sql_strategy_raw_query.png")}
            />
          </figure>
          <p>
            <b>Column list</b>
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_sql_strategy_raw_query_column_detail.png")}
            />
          </figure>
          <p>
            <b>Function details</b>
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_sql_strategy_raw_query_function_detail.png")}
            />
          </figure>
          <p>
            <b>Dataset details</b>
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_sql_strategy_raw_query_dataset_detail.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Deleting a Job"
      >
        <div className="ug-content-main-header">Deleting a Job</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            A job can be deleted by either the owner of the job or a user with
            Administrator privileges. To delete a job, display it in the Prepare
            page, the Jobs pane of the Dashboard, or in search results, then
            click the context menu and choose Delete.
          </p>
          <p>
            This deletes all the dependent objects also, such as filters and
            derived attributes. If you do not have permissions on the dependent
            objects, DCP lists the restricted objects so you can request the
            owners to give you access to them.
          </p>
          <p>
            All Deleted items can be retrieved from the Trash. Navigate to
            Manage &gt; Trash to review all deleted objects, But once an item is
            "Deleted Permanently" it cannot be retrieved.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_delete_1.png")}
            />
          </figure>
          <p>
            Deleted Jobs and their associated objects can be recovered from the
            Trash.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/job_delete_2.png")}
            />
          </figure>
          <p>Deleted Jobs and their associated objects cannot be recovered.</p>
        </div>
      </div>
    </section>
  );
}

export default TransformJobs;
