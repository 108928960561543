import React from "react";

function Copyright () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Copyright">
                <div className="ug-content-main-header">Copyright</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p><b>Copyright &copy; 2023 Boomi, LP. All rights reserved</b>. Boomi, AtomSphere, the Boomi "b" logo, and Molecule are trademarks or registered trademarks of Boomi, LP, or its subsidiaries in the United States and/or other countries. Other trademarks may be trademarks of their respective owners.</p>
                </div>
            </div>
        </section>
    )
}

export default Copyright;
