import React from 'react';

function Footer() {
	return (
		<footer>
			<ul>
				<li>
					<a target="_blank" rel="noopener noreferrer" href="https://status.boomi.com/">Platform Status &amp; Announcements</a>
				</li>
				<li className="copyright">© Copyright 2023 Boomi, LP.</li>
				<li>
					<a target="_blank" href="https://boomi.com/privacy/" rel="noopener noreferrer">Privacy</a>
				</li>
			</ul>
		</footer>
	);
}

export default Footer;