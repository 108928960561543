import React from "react";

function History () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="History">
                <div className="ug-content-main-header">History</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p><b>Browse</b></p>
                    <figure>
                        <img alt="History" src={require('../assets/images/history_browse.png')} />
                    </figure>
                    <p><b>Error</b></p>
                    <figure>
                        <img alt="History" src={require('../assets/images/history_error.png')} />
                    </figure>
                </div>
            </div>
        </section>
    )
}

export default History;
