import React from "react";

function Help () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Help">
                <div className="ug-content-main-header">Help</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p><b>Documentation</b></p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/help_1.png')} />
                    </figure>
                    <p><b>License</b></p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/licence.png')} />
                    </figure>
                </div>
            </div>
        </section>
    )
}
export default Help;
