import React from "react";

function Glossary () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Business Glossary">
                <div className="ug-content-main-header">Business Glossary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The business glossary is a page that contains common business terms and concepts your organization uses. Subject matter experts can collaborate so that glossaries are comprehensive. While the data dictionary defines columns within a dataset, business glossaries define terms and concepts used throughout your organization. 
                        You can add text, images, videos, and links to support definitions, business information, and explanations.</p>
                    <h5>Benefits</h5>
                    <ul>
                        <li>Increases productivity by allowing team members to self-service and obtain information on their own</li>
                        <li>Establishes a common language across the organization</li>
                        <li>Guides new team members during onboarding</li>
                    </ul>
                    <h5>Chat</h5>
                    <p>Teams can collaborate, discuss, and provide feedback on business glossary terms using the Chat panel. You can reply to comments, 
                        like comments, or dislike comments in the chat to provide feedback. Click the Chat icon to open the chat panel.</p>
                    <p>You can enter the @ symbol in your comments to tag a specific user when asking or answering questions. Enter the # symbol to reference an object.</p>
                    <h5>Tagging</h5>
                    <p>You can add tags to business glossaries to increase findability in search and associate objects, such as data sets, with a business glossary.</p>
                    <h5>Endorsement</h5>
                    <p>Data stewards can review and indicate that a business glossary’s definitions are trusted and endorsed. 
                        Click the i icon to open the Details panel to view the Trust Level of a business glossary.</p>
                    
                    <figure>
                        <img alt="Clone Glossary" src={require('../assets/images/glossary_clone_1.png')} />
                    </figure>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Creating a Business Glossary">
                <div className="ug-content-main-header">Creating a Business Glossary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>Business glossaries help your organization add context to data. You can add text, links, images, tables, and video to enrich the understanding of datasets.</p>
                  <ol>
                    <li>Navigate to <strong>Explore</strong> &gt; <strong>Business Glossary</strong>.</li>
                    <li>Click the plus icon.</li>
                    <li>Enter a topic.</li>
                    <li><strong>Optional:</strong> Enter a description. You can enter an HTML anchor tag to create a hyperlink in the description. 
                        You can also add URLs in the glossary page and references.</li>
                    <li><strong>Optional:</strong> Enter tags to improve the findability of the glossary.</li>
                    <li>Click <strong>Create</strong>.</li>
                    <li>Add content to the business glossary page.</li>
                    <li>Click <strong>Save</strong>. You can click the <strong>Details</strong> panel for additional actions, such as endorsing the glossary and providing access to users so they can contribute.</li>
                  </ol> 
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Deleting a Business Glossary">
                <div className="ug-content-main-header">Deleting a Business Glossary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>A Business glossary  can be deleted by either the owner of the Business glossary or a user with Administrator privileges. To delete a  Business glossary, display it in the  Business glossary page or in search results, then click the context menu and choose Delete.</p>
                    <p>Deleted  Business glossary are listed in the Trash window. You can restore the  Business glossary from here if desired. When you restore a  Business glossary you are notified of any missing dependent objects.</p>
                    <p>All Deleted items can be retrieved from the Trash. Navigate to Manage > Trash to review all deleted objects, But once an item is “Deleted Permanently” it cannot be retrieved.</p>
                    <figure>
                        <img alt="Clone Glossary" src={require('../assets/images/glossary_delete_1.png')} />
                    </figure>
                    <p>Deleted  Business glossary  and their associated objects can be recovered from the Trash.</p>
                    <figure>
                        <img alt="Clone Glossary" src={require('../assets/images/glossary_delete_2.png')} />
                    </figure>
                    <p>Deleted Business glossary and their associated objects cannot be recovered.</p>
                </div>
            </div>
        </section>
    )
}

export default Glossary;
