import React from 'react';

function DataSources() {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Data Sources">
                <div className="ug-content-main-header">Data Sources</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>A data source is a logical collection of data that is located on a datastore. For example:</p>
                    <ul>
                        <li>A sales database could be a data source, and the corresponding datastore could be an Oracle database server.</li>
                        <li>The directory /data/events could be a data source, and the corresponding datastore could be a Cloudera Hadoop instance. The following types of data sources are supported for use with DCP:</li>
                        <li>Databases, such as Oracle and IBM DB2</li>
                        <li>Remote file systems, such as Hadoop</li>
                        <li>External services, such as Salesforce</li>
                    </ul>
                    <p>Data sources are listed in the DCP user interface only after they have been added to DCP, usually by a DCP administrator. The administrator can decide which data sources will be made available in DCP and which users can access each data source.</p>
                    <p>A data source is specified by the following characteristics:</p>
                    <ul>
                        <li>Data Store – The underlying storage system.</li>
                        <li>Database -- The file directory (folder), or Amazon S3 bucket – entity containing the data comprising the data source.</li>
                        <li>Authentication details – Passwords or keys DCP uses to log in to the database, Amazon S3, Salesforce, etc.</li>
                    </ul>
                    <p>Permissions can be set to determine which DCP users can access each data source. For more information, see the DCP Administrator’s Guide.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Viewing Data Sources">
                <div className="ug-content-main-header">Viewing Data Sources</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>To view the available data sources, click Manage in the left navigation bar, then click Data Sources.</p>
                    <p>The Data Sources screen lists the available data sources. An icon to the left of each data source name indicates what type of data source it is:</p>
                    <ul>
                        <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/bi.png')} /> Business Intelligence</li>
                        <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/filesystem.png')} /> Filesystem</li>
                        <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/database.png')} /> Database</li>
                        <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/service.png')} /> Service</li>
                        <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/atomsphere.png')} /> Atmosphere</li>
                    </ul>
                    <p>To find a particular data source, click the magnifying glass search icon above the list and start typing the data source name. If tags have been defined for your data sources, you can narrow down the list by clicking one of the labels in the TAGS column.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the AtomSphere Data Source">
                <div className="ug-content-main-header">Connect the AtomSphere Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>Before you begin:</p>
                    <ul>
                        <li>The Boomi platform user account must have at least <a href={"https://help.boomi.com/bundle/atomsphere_platform/page/c-atm-User_roles_and_privileges.html"} className={"link"}>standard role permissions</a>.</li>
                        <li>The platform user account must have Build, Read, and Write Access privileges if you are configuring the Boomi platform to receive data from DCP.</li>
                        <li>The DCP user role must have at least Create access for Adapter, Data Store, Data Source, and at least Read access for Data Set, 
                            Data Content - Read & See Data, and Data Set Metadata.</li>
                    </ul>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Boomi platform.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select the AtomSphere adapter.</li>
                        <li>Select the AtomSphere data store.</li>
                        <li>Enter your email address associated with your platform account.</li>
                        <li>Enter your platform API token. To create your API token:
                            <ol type="a">
                                <li>In the platform, click <strong>Settings</strong>.</li>
                                <li>Select <strong>Account Information and Setup</strong>.</li>
                                <li>In the My User Settings section, click <strong>AtomSphere API Tokens</strong>.</li>
                                <li>Click <strong>Add New Token</strong>.</li>
                                <li>Enter a token name.</li>
                                <li>Click <strong>Generate Token</strong>.</li>
                                <li>Click <strong>Copy to Clipboard and Close</strong> and save your token in a text file for future use. You cannot view the token in the platform after you click <strong>Copy to Clipboard and Close</strong>.</li>
                            </ol>
                        </li>
                        <li>Enter your platform account ID, which is located in <strong>Settings</strong> &gt; <strong>Account Information and Setup</strong> &gt; <strong>Account Information</strong>.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the Azure SQL Data Source">
                <div className="ug-content-main-header">Connect the Azure SQL Database Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Azure SQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Azure SQL Database Adapter</strong>.</li>
                        <li>Select the Azure SQL data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the Azure SQL user name. Users with read-only permissions can connect. However, if Azure SQL is an output destination, read-write permissions are required.</li>
                        <li>Enter the Azure SQL password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Azure SQL. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the Master Data Hub Data Source">
                <div className="ug-content-main-header">Connect the Master Data Hub Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                <p>Prerequisite:</p>
                    <ul>
                        <li>Boomi platform API token.</li>
                    </ul>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example,  Master Data Hub.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select the Master Data Hub adapter.</li>
                        <li>Select the Master Data Hub data store.</li>
                        <li>Enter the Hub repository name you want to connect to.</li>
                        <li>Enter your Boomi platform account ID. To locate your account ID:
                            <ol type="a">
                                <li>In the platform, navigate to <strong>Settings</strong> &gt; <strong>Account Information and Setup</strong> &gt; <strong>Account Information</strong>.</li>
                                <li>Copy the <strong>Account ID</strong>.</li>
                            </ol>
                        </li>
                        <li>Enter your platform API token. To create your API token:
                            <ol type="a">
                                <li>In the platform, click <strong>Settings</strong>.</li>
                                <li>Select <strong>Account Information and Setup</strong>.</li>
                                <li>In the My User Settings section, click <strong>AtomSphere API Tokens</strong>.</li>
                                <li>Click <strong>Add New Token</strong>.</li>
                                <li>Enter a token name.</li>
                                <li>Click <strong>Generate Token</strong>.</li>
                                <li>Click <strong>Copy to Clipboard and Close</strong> and save your token in a text file for future use. You cannot view the token in the platform after you click <strong>Copy to Clipboard and Close</strong>.</li>
                            </ol>
                        </li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the BigQuery Data Source">
                <div className="ug-content-main-header">Connect the BigQuery Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Note: The BigQuery service account must have at least read-only access to objects and object metadata. If BigQuery is used as an output destination, the service account must have read-write privileges.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, BigQuery.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select the BigQuery adapter.</li>
                        <li>Select the BigQuery data store.</li>
                        <li>Enter the <strong>OAuth Client ID</strong> and <strong>OAuth Client Secret</strong>. To obtain the client ID and secret:
                            <ol type="a">
                                <li>Navigate to <strong>Google Cloud Console</strong> &gt; <strong>APIs and Services</strong> &gt; <strong>Credentials</strong>.</li>
                                <li>Click <strong>Create Credentials</strong>.</li>
                                <li>Select <strong>OAuth Client ID</strong>.</li>
                            </ol>
                        </li>
                        <li>Enter the OAuth Callback URL, which is the DCP Host IP and an unoccupied port. The default is http://127.0.0.1:1234.</li>
                        <li><strong>Optional</strong>: Enter the <strong>OAuth Refresh Token</strong>. To create the token:
                            <ol type="a">
                                <li>Add https://developers.google.com/oauthplayground/ to the list of authorized redirect URIs in <strong>Google Cloud Console</strong> &gt; <strong>APIs & Services</strong> &gt; <strong>Credentials</strong>.</li>
                                <li>Visit https://developers.google.com/oauthplayground/.</li>
                                <li>In Step 1, select <strong>Big Query API v2</strong>.</li>
                                <li>Click <strong>Authorize APIs</strong>. Follow the prompts to authorize Google Playground.</li>
                                <li>In Step 2, click <strong>Exchange authorization code for tokens</strong>.</li>
                                <li>Copy the refresh token.</li>
                                <li>Click the gear icon in the top right of the screen.</li>
                                <li>Select <strong>Use your own OAuth credentials</strong>.</li>
                                <li>Enter the <strong>OAuth Client ID</strong> and <strong>OAuth Client Secret</strong> obtained in step 10.</li>
                            </ol>
                        </li>
                         <li>Enter the following in OAuth Scope:
                            <ul>
                                <li>https://www.googleapis.com/auth/cloud-platform</li>
                                <li>https://www.googleapis.com/auth/bigquery</li>
                                 <li>https://www.googleapis.com/auth/bigquery.insertdata</li>
                                <li>https://www.googleapis.com/auth/devstorage.full_control</li>
                                <li>https://www.googleapis.com/auth/devstorage.read_write</li>
                             </ul>
                            <p>Information about scope can be found in Google’s <a href={"https://developers.google.com/identity/protocols/oauth2/scopes#bigquery"} className={"link"}>OAuth 2.0 Scopes</a> article.</p>
                        </li>
                        <li>Enter the <strong>Project ID</strong>. The project ID is located in <strong>Google Cloud Console</strong> &gt; <strong>APIs & Services</strong> &gt; <strong>Manage all projects</strong>.</li>      
                        <li>Enter the <strong>Dataset ID</strong> located in BigQuery.</li>  
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to BigQuery. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the Oracle Data Source">
                <div className="ug-content-main-header">Connect the Oracle Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Oracle.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Oracle Adapter</strong>.</li>
                        <li>Select the Oracle data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the Oracle user name. Users with read-only permissions can connect. However, if Oracle is an output destination, read-write permissions are required.</li>
                        <li>Enter the Oracle password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Oracle. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the PostgreSQL Data Source">
                <div className="ug-content-main-header">Connect the PostgreSQL Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, PostgreSQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>PostgreSQL</strong> adapter.</li>
                        <li>Select the PostgreSQL data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the PostgreSQL user name. Users with read-only access can also connect to the database. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>Enter the PostgreSQL password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to PostgreSQL. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Connect the Redshift Data Source">
                <div className="ug-content-main-header">Connect the Redshift Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Redshift.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Redshift</strong> adapter.</li>
                        <li>Select the Redshift data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the Redshift master-username, which is the administrator account for the cluster. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>Enter the Redshift master-user-password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Redshift. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Connect the Salesforce Data Source">
                <div className="ug-content-main-header">Connect the Salesforce Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                <p>Prerequisites: The Salesforce user must have at least read-only access to <strong>My Settings</strong> &gt; <strong>Personal</strong> &gt; <strong>Personal Information and Personal</strong> &gt; <strong>Reset My Security Token</strong>.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Salesforce.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Salesforce</strong> adapter.</li>
                        <li>Select the Salesforce data store.</li>
                        <li>Enter your Salesforce user name. You must have at least read-only access to the Personal Information page in Salesforce (<strong>My Settings</strong> &gt; <strong>Personal</strong> &gt; <strong>Personal Information and Personal</strong> &gt; <strong>Reset My Security Token</strong>).</li>
                        <li>Enter your password.</li>
                        <li>Enter your security token. To obtain the token:
                            <ol type="a">
                                <li>In Salesforce, navigate to <strong>My Settings</strong> &gt; <strong>Personal</strong> &gt; <strong>Reset My Security Token</strong>. Refer to Salesforce’s <a href={"https://help.salesforce.com/s/articleView?id=sf.user_security_token.htm&type=5"} className={"link"}>Reset My Security Token</a> article for assistance.</li>
                                <li>Click <strong>Reset Security Token</strong>. The token is sent to your email address.</li>
                            </ol>
                        </li>
                        <li><strong>Optional</strong>: Enter the Salesforce sandbox name.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Connect the Snowflake Data Source">
                <div className="ug-content-main-header">Connect the Snowflake Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                <h5>Connect the Data Source with Basic Authentication</h5>
                    <p>If you selected BASIC as the authentication method for the data store, follow these steps to connect the data source.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Snowflake.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Snowflake Adapter</strong>.</li>
                        <li>Select the Snowflake data store.</li>
                        <li>Enter the <strong>Virtual Warehouse Name</strong>. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/warehouses.html"} className={"link"}>Virtual Warehouse</a> article for more information.</li>
                        <li>Enter the <strong>Database Name</strong>.</li>
                        <li>Enter the Snowflake user name. Users with read-only access can also connect to the database. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>Enter the Snowflake password.</li>
                        <li><strong>Optional</strong>: Select <strong>CLIENT_SESSION_KEEP_ALIVE parameter</strong>. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/sql-reference/parameters.html"} className={"link"}>Parameters</a> article for more information about the CLIENT_SESSION_KEEP_ALIVE parameter.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Snowflake. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow transform pushdown</strong>. This option only applies if you have Catalog and Preparation. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/spark-connector-use.html#pushdown"} className={"link"}>Using the Spark Connector</a> for more information about pushdown.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/jdbc-parameters.html#jdbc-driver-connection-parameter-reference"} className={"link"}>JDBC Driver Connection Properties</a> for more information.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source with OAuth 2.0 Authentication</h5>
                    <p>If you selected OAUTH as the authentication method for the data store, follow these steps to connect the data source.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Snowflake.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Snowflake Adapter</strong>.</li>
                        <li>Select the Snowflake data store.</li>
                        <li>Enter the <strong>Virtual Warehouse Name</strong>. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/warehouses.html"} className={"link"}>Virtual Warehouse</a> article for more information.</li>
                        <li>Enter the <strong>Database Name</strong>.</li>
                        <li>Enter the Snowflake user name. Users with read-only access can also connect to the database. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>In Snowflake, run the following code in a worksheet to create the integration.<br></br>
                                <pre><code>
                                CREATE security integration DCP_APP<br></br>
                                type = oauth<br></br>
                                enabled = true<br></br>
                                oauth_client = custom<br></br>
                                oauth_client_type = 'CONFIDENTIAL'<br></br>
                                oauth_redirect_uri = '&#123;<strong>DCP host URL</strong>&#125;:&#91;<strong>port</strong>&#93;'&#59;

                                    </code></pre>
                                    
                        </li>
                        <li>Enter and run the following:<br></br>
                            <pre><code>SELECT system$show_oauth_client_secrets('DCP_APP')&#59;</code></pre>
                        </li>
                        <li>The OAUTH_CLIENT_SECRET_2, OAUTH_CLIENT_SECRET, and OAUTH_CLIENT_ID display. Copy the values in a text file.</li>
                        <li>In DCP, enter the OAUTH_CLIENT_ID and OAUTH_CLIENT_SECRET.</li>
                        <li>Enter and run the following to fetch all properties of the integration:<br></br>
                           <pre><code>DESCRIBE integration DCP_APP&#59;</code></pre>
                           <p><strong>Note</strong>: Ensure that your current role used to create the integration is not included in the BLOCKED_ROLES_LIST. 
                            If it is, refer to Snowflake’s <a href={"https://docs.snowflake.com/en/sql-reference/sql/alter-security-integration-oauth-snowflake.html"} className={"link"}>Alter Security Integration</a> article to remove your role from the Blocked List. Blocked roles cannot request an authorization code.</p>
                        </li>
                        <li>Enter the OAUTH_REDIRECT_URI in DCP’s <strong>OAuth Callback URL</strong> field.</li>
                        <li><strong>Optional</strong>: Select <strong>CLIENT_SESSION_KEEP_ALIVE parameter</strong>. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/sql-reference/parameters.html"} className={"link"}>Parameters</a> article for more information about the CLIENT_SESSION_KEEP_ALIVE parameter.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Snowflake. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow transform pushdown</strong>. This option only applies if you have Catalog and Preparation. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/spark-connector-use.html#pushdown"} className={"link"}>Using the Spark Connector</a> for more information about pushdown.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/jdbc-parameters.html#jdbc-driver-connection-parameter-reference"} className={"link"}>JDBC Driver Connection Properties</a> for more information.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>You are redirected to the Snowflake login screen. Sign into Snowflake. <strong>Note</strong>: If your browser uses a pop-up blocker, you must allow your browser to send redirects to Snowflake.</li>
                        <li>Click <strong>Allow</strong> to let DCP access your Snowflake account.</li>
                        <li>After you click <strong>Create</strong> in the data source configuration, you are redirected to the Snowflake login screen. Sign into Snowflake.</li>
                        <li>Click <strong>Allow</strong>. DCP adds the refresh token for authorization to your data source configuration.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Connect the Tableau Data Source">
                <div className="ug-content-main-header">Connect the Tableau Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                <p>Note: You must have site administrator access to connect and must have read access to projects, workbooks, data sources, and views.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Tableau.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Tableau Server</strong> adapter.</li>
                        <li>Select the Tableau data store.</li>
                        <li>Enter the <strong>Site ID</strong>. Refer to Tableau’s <a href={"https://help.tableau.com/current/online/en-us/sites_add.htm"} className={"link"}>Site Settings Reference</a> article for more information about the site ID.</li>
                        <li>Enter the user name. The user must have a site administration role and needs at least read access to projects, workbooks, data sources, and views.</li>
                        <li>Enter the password.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Connect the MySQL Data Source">
                <div className="ug-content-main-header">Connect the MySQL Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, MySQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>MySQL Adapter</strong>.</li>
                        <li>Select the MySQL data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the MySQL user name. Users with read-only access can also connect to the database. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>Enter the MySQL password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to MySQL. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
                </div>
                <div className="ug-content-wrapper" data-content="Connect the MongoDB Data Source">
                    <div className="ug-content-main-header">Connect the MongoDB Data Source</div>
                    <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, MongoDB.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>MongoDB Adapter</strong>.</li>
                        <li>Select the MongoDB data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the MongoDB user name. Users require a read role and listDatabases privilege to connect to the database. Read MongoDB’s <a href={"https://www.mongodb.com/docs/upcoming/reference/privilege-actions/"} className={"link"}>Privilege Actions</a> and <a href={"https://www.mongodb.com/docs/upcoming/core/authorization/"} className={"link"}>Role-Based Access Control</a> to learn more.</li>
                        <li>Enter the MongoDB password.</li>
                        <li>Enter the authentication database name.</li>
                        <li>Select the authentication mechanism. Read MongoDB’s <a href={"https://www.mongodb.com/docs/drivers/go/v1.9/fundamentals/auth/?_ga=2.40673066.943533190.1670344415-1309415329.1670344415&_gac=1.49190356.1670352640.Cj0KCQiA7bucBhCeARIsAIOwr-_0d-mrrkBQ_OSNntWgGykhz33XqZ7jLdRpRDITiHVJcuVGcK_YH1UaAs7hEALw"} className={"link"}>Authentication Mechanisms</a> article to learn more.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Adding a Data Source">
                <div className="ug-content-main-header">Adding a Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>If you have the appropriate permissions you can create a Data Source. Before you start, be sure to have all the configuration details handy, such as usernames, passwords, or secret keys.</p>
                    <ol>
                        <li>Log in to the DCP user interface with a user account that has administrator permissions, such as the boomi  user.</li>
                        <li>Navigate to Manage > Data Source.</li>
                        <li>Click the plus icon <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/plus.png')} /></li>
                        <li>Select the type of datastore that contains the data for this data source:<br/><b>DATABASE, FILESYSTEM, SERVICE, or BI</b>.</li>
                        <li>Give the data source a name and a description. You can enter an HTML anchor tag in the description to create a hyperlink.</li>
                        <li>
                            In Datastore Name, choose the name of the datastore you want to use.
                            <p><b>Note:</b> Contact your DCP Administrator if you do not see the appropriate Datastore. If the datastore does not appear in the list, it has not yet been added to DCP or you have not been granted permission. Before you can add a data source, the datastore must be added.</p>
                        </li>
                        <li>Provide the configuration details that are required by the type of datastore you selected. For more information about the specific Data Source see the Boomi  Administrator's Guide.</li>
                        <li>
                            If you want to use this database to store the output from transform jobs, check the Allow As Output Destination option.
                            <p><b>Note:</b> This feature is not available for Postgres.</p>
                        </li>
                        <li>
                            In Permissions, select the users and user groups to whom you want to grant access to this data source.
                            <p>For more information, see Data Access Permissions.</p>
                        </li>
                        <li>(Optional) Define tags if desired. See Using Tags in the DCP User’s Guide for more information.</li>
                        <li>You can do bulk import when creating a data source (assuming you have administrator privileges for the data source). If you want to do a bulk import, check Import Database.</li>
                        <li>Click <b>ADD SOURCE</b>. If you clicked the Import Database check box, click <b>ADD & IMPORT SOURCE</b>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="File Crawler Data Source">
                <div className="ug-content-main-header">File Crawler Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The file crawler data source automatically creates datasets as it traverses a given directory structure. Each unique schema in each unique directory is added as a dataset.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Creating a File Crawler Data Source">
                <div className="ug-content-main-header">Creating a File Crawler Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Navigate to Manage > Data Sources</li>
                        <li>Click New <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/plus.png')} /></li>
                        <li>Choose <b>FILESYSTEM</b>.</li>
                        <li>Enter the following information:
                            <ul>
                                <li>Datasource Name</li>
                                <li>Datasource description</li>
                                <li>Permissions</li>
                                <li>Tags</li>
                            </ul>
                        </li>
                        <li>Click Next.</li>
                        <li>Select the Datastore.</li>
                        <li>Enter the User Name.</li>
                        <li>Enter the Base Directory (This is the root for this datastore’s data).</li>
                        <li>Allow as Output Destination. Select to use this datastore as the output destination for your job output.</li>
                        <li>Crawl and Import. Select this option to automatically import and create datasets for each unique discovered schema. Note: When the File Crawler encounters multiple files it checks each file for a schema and creates one dataset per unique schema per directory.</li>
                        <li>Set the Crawl Base Directory. This is the first directory containing files you want to be examined.</li>
                        <li>Select Recursive to examine all sub-directories.</li>
                        <li>For File Inclusions option add any regular expressions (regex) for file types to include using a regex like ".* " and .*csv".</li>
                        <li>For File Exclusions option add any regex expression like ".* " and .*csv" for file types to be excluded.</li>
                        <li>For Dataset Prefix, add the prefix you want to be added to each dataset.</li>
                        <li>For Dataset Tags, add any tags you want to be added to each dataset.</li>
                        <li>Click Save & Crawl.</li>
                    </ol>
                    <p><b>Note:</b> The datasets are created in the background. Check the results by checking the status of the Data Source. You can also re-crawl a datasource to refresh it, or point it to a different directory to create new datasets. The crawler examines file schemas irrespective of file extension and creates one dataset per schema per directory.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Deleting a Data Source">
                <div className="ug-content-main-header">Deleting a Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>With the appropriate permissions, you can delete a Data Source by clicking Delete from the Data Source ellipses. This also lists and deletes references to associated items like datasets, jobs, workflows, and schedules.</p>
                    <p>All Deleted items can be retrieved from the Trash. Navigate to Manage > Trash to review all deleted objects, But once an item is "Deleted Permanently" it cannot be retrieved.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/18.png')} />
                    </figure>
                    <p>Deleted Data Sources and their associated objects can be recovered from the Trash.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/19.png')} />
                    </figure>
                    <p>Deleted Data Sources and their associated objects cannot be recovered.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Additional Data Source Configuration Parameters">
                <div className="ug-content-main-header">Additional Data Source Configuration Parameters</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <div>
                        <p><b>Adobe Cloud Platform</b></p>
                        <ul>
                            <li>Client ID - The Adobe generated API Key</li>
                            <li>Client Secret - The Adobe generated secret</li>
                            <li>Technical Account ID - &lt;your-ID&gt;@techacct.adobe.com</li>
                            <li>Organization ID - &lt;your-ID&gt;@AdobeOrg</li>
                            <li>Key file - The path to upload a private file to generate the Adobe JWT</li>
                        </ul>
                        <p><b>Note:</b> The Adobe Cloud Platform is writable. </p>
                    </div>

                    <div>
                        <p><b>Amazon S3</b></p>
                        <ul>
                            <li>AWS Access Key ID - The first part of the AWS access key security credential is used to authenticate the AWS account that will be sending requests to Amazon Web Services (AWS).</li>
                            <li>AWS Secret Access Key - The second part of the AWS access key security credential used to authenticate the AWS account that will be sending requests to Amazon Web Services (AWS).</li>
                            <li>Region - the regional location of the S3 bucket. This should be the same region as your EMR instance.</li>
                            <li>Bucket Name - The container where files are stored on Amazon S3.</li>
                            <li>Base Directory - The directory that is the root of the available files for creating datasets out of this data source.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Azure Data Lake</b></p>
                        <ul>
                            <li>ADLS Account Name - &lt;Data Lake Store Name&gt;.azuredatalakestore.net</li>
                            <li>Associate an application with the Data Lake Store.
                                <ul>
                                    <li>Step 1:<br/>
                                        <a href="https://docs.microsoft.com/en-us/azure/azure-resource-manager/resource-group-create-service-principal-portal" className={"link"} target={"_blank"} rel={"noopener noreferrer"}>https://docs.microsoft.com/en-us/azure/azure-resource-manager/resource-group-create-service-principal-portal</a>
                                    </li>
                                    <li>Step 2:<br/>
                                        <a href="https://docs.microsoft.com/en-us/azure/data-lake-store/data-lake-store-service-to-service-authenticate-using-active-directory" className={"link"} target={"_blank"} rel={"noopener noreferrer"}>https://docs.microsoft.com/en-us/azure/data-lake-store/data-lake-store-service-to-service-authenticate-using-active-directory</a>
                                    </li>
                                </ul>
                            </li>
                            <li>Client Id: Azure Active Directory &gt; App registrations &gt; &lt;Application Name&gt; &gt;Application id
                                <ul>
                                    <li>Sample Client Id: b6319e77-0390-43c1-8703-2e802c3ceda6</li>
                                </ul>
                            </li>
                            <li>Client Key: Azure Active Directory &gt; App registrations &gt; &lt;Application Name&gt;  Settings &gt; Keys
                                <ul>
                                    <li>Client Key: bdlNmwfiVdfea7lvEdBvlGtD0qkl759T60gZ9TmMjEM=</li>
                                </ul>
                            </li>
                            <li>Base Directory -  /</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Cisco Information Server (CIS)</b></p>
                        <ul>
                            <li>Database Name - Name of the CIS data source.</li>
                            <li>User - Username for an account which has the appropriate permissions on this data source.</li>
                            <li>Password - Password to authenticate the username.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Database</b></p>
                        <p>For IBM DB2 10.5, DB2 on AS/400 V5R2 or V6R1, Netezza, Oracle 12.1, PostgreSQL, Progress OpenEdge, SAP ASE, SAP Hana, Azure SQL Database, Greenplum Database, or Microsoft SQL Server adapter.</p>
                        <ul>
                            <li>Database Name - a unique identifier that was given to the database when it was created.</li>
                            <li>User Name - Username for an account which has the appropriate permissions on this database.</li>
                            <li>Password - Password to authenticate the username.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Google Analytics</b></p>
                        <ul>
                            <li>OAuth Client ID - The Google Analytics client ID (consumer key).</li>
                            <li>OAuth Client Secret - The Google Analytics OAuth client secret (consumer secret).</li>
                            <li>OAuth Callback URL - The Google Analytics OAuth callback URL. The IP address in the URL is the DCP server. The port must be an unoccupied port on the DCP server.</li>
                            <li>OAuth Refresh Token - (Optional) The Google Analytics OAuth refresh token.</li>
                            <li>OAuth Scope - (Optional) The Google Analytics OAuth scope.
                                <p>Default:<br/>
                                    <span className={"font-weight-300"}>openid https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.readonly</span>
                                </p>
                            </li>
                            <li>Profile - The Google profile to connect to.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Google BigQuery</b></p>
                        <ul>
                            <li>OAuth Client ID - The BigQuery client ID (consumer key).</li>
                            <li>OAuth Client Secret - The BigQuery client secret (consumer secret).</li>
                            <li>OAuth Callback URL - The BigQuery OAuth callback URL. The IP address must be the DCP server, and the port must be an unoccupied port on the server.</li>
                            <li>OAuth Refresh Token - (Optional) The BigQuery OAuth refresh token.</li>
                            <li>OAuth Scope - (Optional) The BigQuery OAuth scope. Default: "https://www.googleapis.com/auth/bigquery https://www.googleapis.com/auth/cloud-platform"</li>
                            <li>Project ID - ID of a BigQuery project.</li>
                            <li>Dataset ID - ID of the BigQuery dataset.</li>
                            <li>Allow as Output Destination – Allow this source to be destination of job output.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Google Cloud Storage</b></p>
                        <ul>
                            <li>Google Service Account Key File - The path to the Google Cloud Storage service account key file.</li>
                            <li>Bucket Name - Name of a bucket on the Google Cloud Storage.</li>
                            <li>Base Directory - The location on the Google Cloud Storage under which the data is kept. Default: /.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Google Sheets</b></p>
                        <ul>
                            <li>OAuth Client ID - The Sheets client ID (consumer key).</li>
                            <li>OAuth Client Secret - The Sheets client secret (consumer secret).</li>
                            <li>OAuth Callback URL - The Sheets OAuth callback URL. The IP address must be the DCP server, and the port must be an unoccupied port on the server.</li>
                            <li>OAuth Refresh Token - (Optional) The Sheets OAuth refresh token.</li>
                            <li>OAuth Scope - (Optional) The Sheets OAuth scope. Default: "https://www.googleapis.com/auth/bigquery https://www.googleapis.com/auth/cloud-platform"</li>
                            <li>Spreadsheet Names - (Optional) Name of the spreadsheet that contains the data.</li>
                            <li>Header - (Optional) True or false. Tells whether the first row in the spreadsheet is a header row.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>HDFS</b></p>
                        <p>For Amazon EMR 5.8 HDFS, EMR 5.11.1 HDFS, Apache HDFS 2.5.0, 2.6.0, 2.7.1, or 2.7.3, Cloudera HDFS 5.3.0, 5.7, or 5.12, HDP HDFS 2.4 or 2.6, Microsoft HDInsight HDFS 3.4 or 3.6, and Mapr HDFS 4.0.2 adapters.</p>
                        <ul>
                            <li>Base Directory - The location on the local filesystem under which Hadoop keeps all its data.</li>
                            <li>User Name - Username used by Hadoop to determine who is allowed to submit or modify jobs.</li>
                            <li>User Principal - If the Authentication Mechanism of the underlying datastore is KERBEROS: Provide the HDFS user principal name, omitting the realm. Example: If the user principal name is unifi/server.example.com@CLOUDERA, enter unifi/server.example.com.</li>
                            <li>If multiple data sources are created from the same datastore, they can have different user principals.</li>
                            <li>Realm  If the Authentication Mechanism of the underlying datastore is KERBEROS: Provide the HDFS user realm name, in uppercase. Example: If the user principal name is unifi/server.example.com@CLOUDERA, enter CLOUDERA.</li>
                            <li>Keytab File Path - If the Authentication Mechanism of the underlying datastore is KERBEROS: Provide the path to the user’s keytab file. Each user principal keytab file must be located on the machine where DCP is installed. The file can be copied to any location on the DCP host, and the unifi user must have permissions on the file.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Hive</b></p>
                        <p>For Amazon EMR 5.8 Hive, EMR 5.11.1 Hive, Apache Hive 0.13.1, 1.1.0, and 1.2.1, Cloudera Hive 5.3.0, 5.7, and 5.12, HDP Hive 2.4, Microsoft HDInsight 3.4 or 3.6 Hive, and Mapr Hive 4.0.2 adapters.</p>
                        <ul>
                            <li>Database Name - Name of the Hive database.</li>
                            <li>User - Username used by Hive to determine who is allowed to submit or modify jobs.</li>
                            <li>Password - Password to authenticate the username.</li>
                            <li>User Principal - If the Authentication Mechanism of the underlying datastore is KERBEROS: Provide the Hive user principal name, omitting the realm. Example: If the user principal name is unifi/server.example.com@CLOUDERA, enter unifi/server.example.com.</li>
                            <li>If multiple data sources are created from the same datastore, they can have different user principals.</li>
                            <li>Realm - If the Authentication Mechanism of the underlying datastore is KERBEROS: Provide the Hive user realm name, in uppercase. Example: If the user principal name is unifi/server.example.com@CLOUDERA, enter CLOUDERA.</li>
                            <li>Keytab File Path - If the Authentication Mechanism of the underlying datastore is KERBEROS: Provide the path to the user’s keytab file. Each user principal keytab file must be located on the machine where DCP is installed. The file can be copied to any location on the DCP host, and the unifi user must have permissions on the file.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Marketo</b></p>
                        <p>To obtain the values for these parameters, log in to your Marketo account. Click your name menu, and click the My Account menu item. In the left navigation, click to expand LaunchPoint. Choose from the list the service definition for the account that you want DCP to log in as. From the service definition, you can get the Client ID and Client Secret.</p>
                        <ul>
                            <li>User ID - Username for an account which can be used to log in to the Marketo service.</li>
                            <li>Password - Password to authenticate the user ID.</li>
                            <li>Encryption Key - Marketo encryption key.</li>
                            <li>OAuth Client ID - OAuth client ID.</li>
                            <li>OAuth Client Secret - OAuth client secret.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Azure Blob</b></p>
                        <ul>
                            <li>Storage Account Name - Username for an account which can be used to log in to the Azure cloud storage service.</li>
                            <li>Storage Account Key - Azure account key.</li>
                            <li>Container Name - Name of a container in the Azure cloud storage.</li>
                            <li>Base Directory - The default directory on the Azure Blob under which the data is kept. Default: /</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Microsoft Dynamics CRM</b></p>
                        <ul>
                            <li>User - Username for an account which can be used to log in to the service.</li>
                            <li>Password - Password to authenticate the username.</li>
                            <li>MongoDB Data Source Configuration Parameters</li>
                            <li>Database Name - The MongoDB database name.</li>
                            <li>User - User account ID in this database.</li>
                            <li>Password - Password to authenticate the user ID.</li>
                            <li>Authentication Mechanism - The authentication mechanism. NONE (default) or SCRAM-SHA-1.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>MySQL</b></p>
                        <ul>
                            <li>Database Name - The MySQL database name.</li>
                            <li>User - User account on this database.</li>
                            <li>Password - Password to authenticate the username.</li>
                            <li>Allow as Output Destination – Allow this source to be destination of job output.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>NetSuite CRM and NetSuite ERP</b></p>
                        <ul>
                            <li>Account ID - The ID for an account which can be used to access NetSuite. The account ID typically corresponds to an organization. To find the account ID, log in to the NetSuite account, then click Setup - Integration -○Web Services Preferences.</li>
                            <li>User - Username which can be used to log in to NetSuite.</li>
                            <li>Password - Password to authenticate the username.</li>
                            <li>Role ID - (Optional) The user’s NetSuite Role ID. To find the role ID, log in to the NetSuite account, then click Setup – Integration – Web Services Preferences, then click Manage Roles. The role ID is shown in the Internal ID column. If you don't see this column, click View and add the column.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>PostgreSQL</b></p>
                        <ul>
                            <li>Database Name - The PostgreSQL database name.</li>
                            <li>User - The user account on this database.</li>
                            <li>Password - Password to authenticate the username.</li>
                            <li>Allow as Output Destination – Allow this source to be destination of job output.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Redshift</b></p>
                        <p>Database Name</p>
                        <ul>
                            <li>The Redshift cluster database name.</li>
                            <li>User - Redshift Master User on this database.</li>
                            <li>Password - Password to authenticate the Master Username.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Remote File System</b></p>
                        <ul>
                            <li>User Name - Username for an account which has the appropriate permissions on this file system.</li>
                            <li>Password - Password to authenticate the username.</li>
                            <li>Private Key - Decryption key.</li>
                            <li>Private Key Password - The password that protects the private key.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Salesforce</b></p>
                        <ul>
                            <li>User - Username for an account which can be used to log in to the Salesforce service.</li>
                            <li>Password - Password for the user account.</li>
                            <li>Security Token - The security token for the account. To obtain this value, log in to your Salesforce account. Click My Developer Account, then log in again. Click your name menu, then click My Settings, Personal, Reset My Security Token. The security token will be sent to you as an email.<br/>
                                <b>Note:</b> If you ever reset your token again, you must edit the data source in DCP to use the new token.
                            </li>
                            <li>Sandbox Name - The name of the sandbox you created in Salesforce.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>ServiceNow</b></p>
                        <ul>
                            <li>User - Username for an account which can be used to log in to the ServiceNow service.</li>
                            <li>Password - Password for the user account.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>SnowFlake</b></p>
                        <ul>
                            <li>Virtual Warehouse Name - The Snowflake virtual warehouse to which you want to connect.</li>
                            <li>Database Name - Name of the Snowflake database.</li>
                            <li>User - Username for an account which has the appropriate permissions on this database.</li>
                            <li>Password - Password to authenticate the username.</li>
                            <li>Client Session Keep Alive Parameter – Snowflake CLIENT_SESSION_KEEP_ALIVE connection parameter.</li>
                            <li>Allow as Output Destination – Allow this source to be destination of job output.</li>
                            <li>Use Bulk Load – Use Snowflake’s native bulk loading (copy) for writes.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Tableau</b></p>
                        <ul>
                            <li>Data Source Name - The datastore used to create the data source.</li>
                            <li>Site ID - The Tableau Site ID that identifies a specific site on the server.</li>
                            <li>Tableau Username - The Tableau administrative user with access to one or more Tableau sites.</li>
                            <li>Password for the Tableau Username on the Tableau server.</li>
                            <li>Synchronize - check to synchronize the Tableau metadata with DCP.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>Twitter</b></p>
                        <ul>
                            <li>A Twitter data source can be configured to use OAuth for authorization. This is a protocol that allows one application (DCP) to be approved to interact with another (Twitter) on behalf of a user. In the normal case, 3-legged OAuth is used. A browser tab appears where the user must give permission for DCP to access Twitter.</li>
                            <li>The values for Client ID and Client Secret can be obtained from the Twitter app’s developer UI screens. For more information, see https://dev.twitter.com/oauth.</li>
                            <li>OAuth Client ID - Credential for a Twitter app owned by the Twitter account that is serving as a data source. The Twitter app acts as an intermediary between DCP and Twitter.</li>
                            <li>OAuth Client Secret - Credential from the same Twitter app as in OAuth Client ID.</li>
                            <li>OAuth Callback URL - The user-defined endpoint to which Twitter can return callbacks from its API. The callback URL is the IP address or hostname of the DCP host, with a port number you have set aside for this use (like unifi-host:port). The port must not be used for any other purpose.</li>
                            <li>OAuth Access Token - Access token provided by Twitter. The access token allows DCP to have authorized access to Twitter on behalf of a Twitter user. For 2-legged OAuth. Allows Unifi to connect to the app with the username of the app owner.</li>
                            <li>OAuth Access Token Secret - The value which identifies the account on behalf of which the DCP application is acting.</li>
                        </ul>
                    </div>

                    <div>
                        <p><b>YouTube Analytics</b></p>
                        <ul>
                            <li>OAuth Client ID - The YouTube client ID (consumer key).</li>
                            <li>OAuth Client Secret - (Optional) The YouTube client secret (consumer secret).</li>
                            <li>OAuth Callback URL - The YouTube OAuth callback URL. The IP address must be the DCP server, and the port must be an unoccupied port on the server.</li>
                            <li>OAuth Refresh Token - (Optional) The YouTube OAuth refresh token.</li>
                            <li>OAuth Scope - (Optional) The YouTube OAuth scope. Default: "https://www.googleapis.com/auth/bigquery https://www.googleapis.com/auth/cloud-platform"</li>
                            <li>Content Owner ID - (Optional) Name of the account that owns the YouTube content.</li>
                            <li>Channel ID - (Optional) Name of the YouTube channel.</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="BI Explorer and the Tableau Data Source">
                <div className="ug-content-main-header">BI Explorer and the Tableau Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>A Tableau datasource connects to the Tableau server and synchronizes the Tableau server metadata to the DCP server. This requires the appropriate Tableau login credentials that not only log into the Tableau server but have access to one or more Tableau sites.</p>

                    <table>
                        <thead>
                        <tr>
                            <th colSpan={2}>Tableau Server to DCP Server Mapping Views</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Tableau Server</td>
                            <td>DCP Data Store</td>
                        </tr>
                        <tr>
                            <td>Tableau Site</td>
                            <td>DCP Data Source</td>
                        </tr>
                        <tr>
                            <td>Tableau Workbook</td>
                            <td>DCP Dataset (in Data Explorer</td>
                        </tr>
                        </tbody>
                    </table>

                    <p>After creating the Tableau Datasource and navigating to Explore>BI Explorer>Tableau, the Admin users sees a consolidated view of all projects and workbooks across multiple Tableau sites and servers.</p>

                    <p>The BI Tableau Summary page displays the Tableau workbooks in one of three states:</p>
                    <ul>
                        <li>Chain icon -- no mapping has been created between the Unifi user and the Tableau server user.</li>
                        <li>Lock icon -- mapping has been created and the user does not have access to the workbook.</li>
                        <li>No icon -- mapping is complete and the user has access to the workbook.</li>
                    </ul>

                    <p>When your end user first accesses this page they will see the same list of workbooks with the chain icon.</p>

                    <figure>
                        <img alt={"Workbooks"} src={require('../assets/images/workbook_1.png')} />
                    </figure>

                    <p>This indicates that none of the workbooks have a link between the Unifi user and their corresponding Tableau credentials. When your user clicks Link and enters their Tableau credentials DCP creates a mapping between the Unifi user and Tableau they are granted access to the workbooks.</p>

                    <figure>
                        <img alt={"Workbooks"} src={require('../assets/images/workbook_2.png')} />
                    </figure>

                    <p>When your user opens a workbook they see a list of views from that workbook. </p>

                    <figure>
                        <img alt={"Workbooks"} src={require('../assets/images/workbook_3.png')} />
                    </figure>

                    <p>Opening one of their views renders the view from the DCP serve</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Tableau as a Trusted Server">
                <div className="ug-content-main-header">Tableau as a Trusted Server</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>To avoid the issue of multiple logins the Tableau Administrator should create the DCP host as a trusted server on Tableau by using the following link:</p>
                    <a href={"https://onlinehelp.tableau.com/current/server/en-us/trusted_auth_trustIP.htm"} className={"link"} target={"_blank"} rel={"noopener noreferrer"}>
                        https://onlinehelp.tableau.com/current/server/en-us/trusted_auth_trustIP.htm
                    </a>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Data Type Discovery">
                <div className="ug-content-main-header">Data Type Discovery</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Data Type discovery follows these guidelines:</p>
                    <ul>
                        <li>Data discovery is not case sensitive.</li>
                        <li>The discovery algorithm parses the data as follows:
                            <ul>
                                <li>The executor service evaluates a complex data type column by picking a SAMPLE from that column as defined in the Settings>General>Default Sample Size setting.</li>
                                <li>The executor service matches sample records against system-defined complex types in the following order of priority:
                                    <ul>
                                        <li>User-Defined-DataTypes (regular expressions as defined by the end-user).</li>
                                        <li>DCP-Defined-DataTypes  (system default values). The system defines these values using the property "columnTypeConfidenceThreshold" located in  $UNIFI_HOME/share/resources/executor/conf/executor.conf.</li>
                                        <li>The match is defined by the column type variable. The variable default value is 1 (columnTypeConfidenceThreshold = 1.0). Thus by default, the system requires a 100 percent match. You can change the percentage of matching property by changing the columnTypeConfidenceThreshold property. For example, to change the matching percentage to 80 percent, the value would read: (columnTypeConfidenceThreshold = .80. Note that the executor service must be restarted to instantiate this change.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Re-Importing a Data Source">
                <div className="ug-content-main-header">Re-Importing a Data Source</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>If the underlying structure of the data source changes, the data source can be re-imported into DCP. For example, when the schema of a database changes, DCP needs to refresh its information about the database.</p>
                    <p>Note: When reimporting a data source, columns that you have marked PII or have masked retain those attributes even if additional rows have been added to those columns. New column attributes are also added.</p>
                    <ul>
                        <li>In the left navigation bar, click Manage, then click Data Sources.</li>
                        <li>Click the name of the data source you want to re-import.</li>
                        <li>The click on the re-import button in the upper right-hand corner of the screen.</li>
                    </ul>
                    <figure>
                        <img alt={"Re-Importing a Data Source"} src={require("../assets/images/reimport_data_source.png")} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Adding a User-Defined Data Type">
                <div className="ug-content-main-header">Adding a User-Defined Data Type</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>In addition to the many built-in datatypes, there is also user-defined datatype (UDT) available. As an administrator, you create a new UDT from the Manage > User Defined Data Types.</p>
                    <p>To create a new User Defined Datatype</p>
                    <ol>
                        <li>Click Manage > Data Types.</li>
                        <li>Click “+”</li>
                        <li>Add the following:
                            <ul>
                                <li>Name - Required</li>
                                <li>Description - Describe the data type</li>
                                <li>Tags - Searchable</li>
                                <li>Mark as PII (on or off)</li>
                                <li>Mask Data (on or off)
                                    <ul>
                                        <li>Mask All - Masks all</li>
                                        <li>Partial Mask - Masks first and/or last.
                                            <figure>
                                                <img alt={"Partial Mask"} src={require("../assets/images/partial_mask.png")} />
                                            </figure>
                                        </li>
                                        <li>Partial Show - Shows first and/or last.
                                            <figure>
                                                <img alt={"Partial Show"} src={require("../assets/images/partial_show.png")} />
                                            </figure>
                                        </li>
                                    </ul>
                                </li>
                                <li>Regular Expression - The expressions on which this data type filters. It is required</li>
                            </ul>
                        </li>
                        <li>Click Save. The new UDT is displayed.
                            <figure>
                                <img alt={"UDT"} src={require("../assets/images/udt.png")} />
                            </figure>
                        </li>
                    </ol>
                    <p>You can delete a UDT from the right vertical ellipse menu.</p>
                    <figure>
                        <img alt={"UDT Delete"} src={require("../assets/images/udt_delete.png")} />
                    </figure>
                    <p>When importing or reimporting new datasets, the UDT columns that are discovered are marked with the UDT icon.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Notes on using UDT">
                <div className="ug-content-main-header">Notes on using UDT</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ul>
                        <li>Only an Admin or Data Steward role can create a PII.</li>
                        <li>UDT can also have masking dataset</li>
                        <li>When a UDT is created it can be deleted and cannot be edited.</li>
                        <li>End users (non-Admin roles) can view UDT.</li>
                        <li>When a UDT is deleted, the description of the types of columns detected by that UDT returns to the base data type.</li>
                        <li>When a deleted UDT is restored, the types of columns previously detected are still primitive types. Discovery must be run again.</li>
                    </ul>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="DCP System Defined Datatypes">
                <div className="ug-content-main-header">DCP System Defined Datatypes</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>DCP recognizes the following system-defined datatypes. </p>
                    <table>
                        <thead>
                            <tr>
                                <th>Data Type</th>
                                <th>Discovery Rule</th>
                                <th>Automatically tagged as PII</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Address (USA)</td>
                                <td>Number, String, City, Zip code</td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td>City</td>
                                <td>USA City name or City alias from a list of cities.<br/>https://www.uscitieslist.org/</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Credit Card Number</td>
                                <td>Maestro-16 or 19 digits - starts with 5018, 5020, 5038, 5893, 6304, 6759, 6761, 6762, 6763<br/>
                                    Visa Electron - 16 digits - starts with 4026, 417500, 4508, 4844, 4913, 4917<br/>
                                    Visa- 13 or 16 or 19 digits - starts with 4<br/>
                                    MasterCard- 16 digits- starts with 51, 52, 53, 54, 55, 222100-272099<br/>
                                    American Express - 15 digits - starts with 34, 37<br/>
                                    4 digits number separated by single space or dash<br/>
                                    discovery happens in the order of priority given above
                                </td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td>Date</td>
                                <td>USA Date format<br/>
                                    2012-12-28<br/>
                                    Rest of the world Date format (if we are able to break the tie between month and date)<br/>
                                    2012-28-12
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>DateTime</td>
                                <td>USA Date format<br/>
                                    2012-12-28 03:01<br/>
                                    2012-12-28 2:00pm<br/>
                                    2012-12-28 01:02:59<br/>
                                    2012-12-28 01:02:1.89567
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>EMAIL</td>
                                <td>string@string.string</td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td>eyeColor<br/>
                                    <a href={"https://www.aamva.org/workarea/downloadasset.aspx?id=1147"} className={"link"} target={"_blank"} rel={"noopener noreferrer"}>AAMVA D20 Standard</a>
                                </td>
                                <td>BLK or Black<br/>
                                    BLU or Blue<br/>
                                    BRO or Brown<br/>
                                    GRY or Gray<br/>
                                    GRN or Green<br/>
                                    HAZ or Hazel<br/>
                                    MAR or Maroon<br/>
                                    PNK or Pink<br/>
                                    DIC or Dichromatic<br/>
                                    UNK or Unknown
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Gender</td>
                                <td>Male, M, Female, F</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td><a href={"https://www.bioinformatics.org/tutorial/1-1.html"} className={"link"} target={"_blank"} rel={"noopener noreferrer"}>geneticSequence</a></td>
                                <td>DNA code is written out in a sequence of 4 letters which represent the 4 chemical bases in a DNA molecule: AGCT. sample DNA sequence: GTTCAGGCAAAGGCAGCAGTTGCTAATGAAGACACTGGAGGACAGCGGGCTT with minimum 10 characters</td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td>Geolocation</td>
                                <td>latitude  (-90 to +90)<br/>
                                    longitude  (-180 to +180)<br/>
                                    optional bracket in beginning and end<br/>
                                    separated by comma<br/>
                                    no space before/after comma or bracket
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>hairColor<br/>
                                    <a href={"https://www.aamva.org/workarea/downloadasset.aspx?id=1147"} className={"link"} target={"_blank"} rel={"noopener noreferrer"}>AAMVA D20 Standard</a><br/>
                                    hairColor has priority than eyeColor, if there is a conflict<br/>
                                </td>
                                <td>BAL or Bald<br/>
                                    BLK or Black<br/>
                                    BLN or Blond<br/>
                                    BRO or Brown<br/>
                                    GRY or Grey<br/>
                                    RED or Red/Auburn<br/>
                                    SDY or Sandy<br/>
                                    WHI or White<br/>
                                    UNK or Unknown
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>HOSTNAME</td>
                                <td>localhost  or<br/>
                                    ipv4:port  (port is optional)
                                </td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td>IMEI<br/>
                                    <a href={"https://tools.ietf.org/rfc/rfc7254.txt"} className={"link"} target={"_blank"} rel={"noopener noreferrer"}>https://tools.ietf.org/rfc/rfc7254</a>. page 8
                                </td>
                                <td>nnnnnnnn-nnnnnn-n<br/>
                                    (8 digits - 6 digits - 1 digit    each digit could be from 0 to 9)<br/>
                                    separated by single space or dash
                                </td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td>
                                    <a href={"https://www.ibm.com/support/knowledgecenter/en/STCMML8/com.ibm.storage.ts3500.doc/opg_3584_IPv4_IPv6_addresses.html"} className={"link"} target={"_blank"} rel={"noopener noreferrer"}>IPV4 address</a>
                                </td>
                                <td>An IPv4 address has the following format: x . x . x . x where x is called an octet and must be a decimal value between 0 and 255</td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td><a href={"https://www.ibm.com/support/knowledgecenter/en/STCMML8/com.ibm.storage.ts3500.doc/opg_3584_IPv4_IPv6_addresses.html"} className={"link"} target={"_blank"} rel={"noopener noreferrer"}>IPV6 address</a></td>
                                <td>An IPv6 (Normal) address has the following format: y : y : y : y : y : y : y : y where y is called a segment and can be any hexadecimal value between 0000 and FFFF.</td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td>MacAddress</td>
                                <td>5 hex codes separated by : or hyphen</td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td>maritalStatus</td>
                                <td>"married" or<br/>
                                    "Widowed" or<br/>
                                    "Divorced" or<br/>
                                    "Separated" or<br/>
                                    "Never married" or<br/>
                                    "single"
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>SSN</td>
                                <td>The Social Security number is a nine-digit number in the format "AAA-GG-SSSS". The number is divided into three parts</td>
                                <td>PII</td>
                            </tr>
                            <tr>
                                <td>State</td>
                                <td>50 USA states only (short form and long form - Wisconsin or WI</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Times</td>
                                <td>03:01<br/>
                                    2:00pm<br/>
                                    01:02:59<br/>
                                    01:02:1.89567
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>unifiDecimal</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>unifiInt</td>
                                <td>any number in the range -9223372036854775808 to 9223372036854775807 (Java Long)</td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>unifiNull</td>
                                <td>"null" or Hive Null - "\\N"</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>URL</td>
                                <td>protocol://URN<br/>
                                    Supported protocol:<br/>
                                    a) s3<br/>
                                    b) HTTP<br/>
                                    c) HTTPS<br/>
                                    d) TCP<br/>
                                </td>
                                <td>&nbsp;</td>
                            </tr>
                            <tr>
                                <td>Zipcode (USA)</td>
                                <td>"5 digits" '-" hyphen "4 digits"</td>
                                <td>&nbsp;</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}
export default DataSources;
