import React from "react";

function EnablingSsl () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Enabling SSL">
                <div className="ug-content-main-header">Enabling SSL</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>SSL settings are only available for customers using the DCP On-Premesis edition.</p>
                    <p>DCP provides a command-line utility that you can use to enable○SSL○on the DCP Integration Service so all connections to the Integration Service are encrypted and secure. The utility, unifi_enablessl, configures a DCP installation to accept connections via HTTPS instead of HTTP.</p>
                    <p>Here are the options for the script:</p>
                    <p className={"font-weight-300"}>$ scripts/sbin/unifi_enablessl --help</p>
                    <p className={"font-weight-300"}>usage: unifi_enablessl [--privkey PRIVKEY --ssl-cert CERT | --self-signed]</p>
                    <p>optional arguments:<br/>
                        <span className={"font-weight-300"}>○ -h, --help ○ ○ ○ ○ ○ show this help message and exit</span><br/>
                        <span className={"font-weight-300"}>○ --privkey PRIVKEY ○ ○Private key used to generate CSR</span><br/>
                        <span className={"font-weight-300"}>○ --ssl-cert SSL_CERT ○SSL○certificate</span><br/>
                        <span className={"font-weight-300"}>○ --self-signed ○ ○ ○ ○Generate and install a self-signed○SSL○certificate</span><br/>
                        <span className={"font-weight-300"}>○ --debug ○ ○ ○ ○ ○ ○ ○Enable debug logging</span><br/>
                    </p>
                    <p>The script has the option to either install an existing SSL certificate and key (--ssl-cert and --privkey options) or generate a new key, create a self-signed certificate and install it (--self-signed option).</p>
                    <p className={"font-weight-300"}>
                        $ scripts/sbin/unifi_enablessl --privkey /tmp/privkey.pem --ssl-cert /tmp/fullchain.pem<br/>
                        2016-07-12 22:00:29,965 - unifi_enablessl - INFO - Checking for self-signed certificate...<br/>
                        2016-07-12 22:00:29,965 - unifi_enablessl - INFO - Getting certificate subject...<br/>
                        2016-07-12 22:00:29,984 - unifi_enablessl - INFO - Getting certificate issuer...<br/>
                        2016-07-12 22:00:30,003 - unifi_enablessl - INFO - Installing○SSL○certificate and private key...<br/>
                        2016-07-12 22:00:30,004 - unifi_enablessl - INFO - Updating Nginx configuration...<br/>
                        2016-07-12 22:00:30,004 - unifi_enablessl - INFO - Checking for self-signed certificate...<br/>
                        2016-07-12 22:00:30,004 - unifi_enablessl - INFO - Getting certificate subject...<br/>
                        2016-07-12 22:00:30,023 - unifi_enablessl - INFO - Getting certificate issuer...<br/>
                        2016-07-12 22:00:30,042 - unifi_enablessl - INFO - Creating new env.py file...<br/>
                        2016-07-12 22:00:30,042 - unifi_enablessl - INFO - Getting certificate subject...<br/>
                        2016-07-12 22:00:30,168 - unifi_enablessl - INFO - Updating Access Service configuration...<br/>
                        2016-07-12 22:00:30,168 - unifi_enablessl - INFO - Getting certificate subject...<br/>
                        2016-07-12 22:00:31,316 - unifi_enablessl - INFO - Successfully enabled○SSL○on port 8443.<br/>
                        2016-07-12 22:00:31,316 - unifi_enablessl - INFO - Unifi must be restarted for changes to take effect.<br/>
                    </p>
                    <p>When enabling SSL using a certificate signed by a trusted Certificate Authority (CA) the connection between the browser and the DCP is fully secure and trusted.</p>
                    <figure>
                        <img alt="Enabling SSl" src={require('../assets/images/enabling_ssl.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Self-signed Certificates">
                <div className="ug-content-main-header">Self-signed Certificates</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The --self-signed option should not be used in production environments. While it does provide strong encryption it does not provide host identify verification.</p>
                    <p className={"font-weight-300"}>
                        $ scripts/sbin/unifi_enablessl --self-signed<br/>
                        2016-07-12 22:37:30,634 - unifi_enablessl - WARNING - *************************************************************************************<br/>
                        2016-07-12 22:37:30,634 - unifi_enablessl - WARNING - While using a self-signed certificate will still provide end-to-end encryption<br/>
                        2016-07-12 22:37:30,634 - unifi_enablessl - WARNING - it cannot provide host verification. ○This is a security risk. ○Web browsers by<br/>
                        2016-07-12 22:37:30,634 - unifi_enablessl - WARNING - default will also not trust self-signed certificates. ○It is strongly recommended<br/>
                        2016-07-12 22:37:30,635 - unifi_enablessl - WARNING - that you do not use a self-signed certificate and use one from a trusted certificate<br/>
                        2016-07-12 22:37:30,635 - unifi_enablessl - WARNING - authority. ○LetsEncrypt (https://www.letsencrypt.org/) is a trusted CA that provides<br/>
                        2016-07-12 22:37:30,635 - unifi_enablessl - WARNING - free○SSL○certificates.<br/>
                        2016-07-12 22:37:30,635 - unifi_enablessl - WARNING - *************************************************************************************<br/>
                        Do you want to continue and use a self-signed certificate? [y/n] y<br/>
                        2016-07-12 22:37:32,229 - unifi_enablessl - INFO - Creating Certificate Signing Request...<br/>
                        Generating a 2048 bit RSA private key<br/>
                        ...............................................................................................+++<br/>
                        ...................+++<br/>
                        writing new private key to '/tmp/tmps2D9cC'<br/>
                        -----<br/>
                        You are about to be asked to enter information that will be incorporated<br/>
                        into your certificate request.<br/>
                        What you are about to enter is what is called a Distinguished Name or a DN.<br/>
                        There are quite a few fields but you can leave some blank<br/>
                        For some fields, there will be a default value,<br/>
                        If you enter '.', the field will be left blank.<br/>
                        -----<br/>
                        Country Name (2 letter code) [XX]:US<br/>
                        State or Province Name (full name) []:California<br/>
                        Locality Name (eg, city) [Default City]:San Mateo<br/>
                        Organization Name (eg, company) [Default Company Ltd]:Unifi Software, Inc.<br/>
                        Organizational Unit Name (eg, section) []:Engineering<br/>
                        Common Name (eg, your name or your server's hostname) []:example.com<br/>
                        Email Address []:user@example.com<br/><br/>

                        Please enter the following 'extra' attributes<br/>
                        to be sent with your certificate request<br/>
                        A challenge password []:<br/>
                        An optional company name []:<br/>
                        2016-07-12 22:38:04,757 - unifi_enablessl - INFO - Signing CSR...<br/>
                        Signature ok<br/>
                        subject=/C=US/ST=California/L=San Mateo/O=Unifi Software, Inc./OU=Engineering/CN=user.example.com/emailAddress=user@example.com<br/>
                        Getting Private key<br/>
                        2016-07-12 22:38:04,779 - unifi_enablessl - INFO - Getting certificate subject...<br/>
                        2016-07-12 22:38:04,900 - unifi_enablessl - INFO - Installing○SSL○certificate and private key...<br/>
                        2016-07-12 22:38:04,901 - unifi_enablessl - INFO - Updating Nginx configuration...<br/>
                        2016-07-12 22:38:04,902 - unifi_enablessl - INFO - Checking for self-signed certificate...<br/>
                        2016-07-12 22:38:04,902 - unifi_enablessl - INFO - Getting certificate subject...<br/>
                        2016-07-12 22:38:04,919 - unifi_enablessl - INFO - Getting certificate issuer...<br/>
                        2016-07-12 22:38:04,934 - unifi_enablessl - INFO - Creating new env.py file...<br/>
                        2016-07-12 22:38:04,935 - unifi_enablessl - INFO - Getting certificate subject...<br/>
                        2016-07-12 22:38:04,954 - unifi_enablessl - INFO - Updating Access Service configuration...<br/>
                        2016-07-12 22:38:04,955 - unifi_enablessl - INFO - Getting certificate subject...<br/>
                        2016-07-12 22:38:06,201 - unifi_enablessl - INFO - Successfully enabled○SSL○on port 8443.<br/>
                        2016-07-12 22:38:06,201 - unifi_enablessl - INFO - Unifi must be restarted for changes to take effect.<br/>
                    </p>
                    <p>If using a self-signed certificate, users are presented with a warning in their browser when connecting to DCP:</p>
                    <figure>
                        <img alt="Enabling SSl" src={require('../assets/images/self_signed_certificate.png')} />
                    </figure>
                    <p>Users must click through to proceed to DCP. Looking at the details of the certificate in the browser we see that the connection is indeed encrypted, but that the identity of the host cannot be validated.</p>
                    <figure>
                        <img alt="Enabling SSl" src={require('../assets/images/self_signed_certificate_1.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Important Notes on Configuration">
                <div className="ug-content-main-header">Important Notes on Configuration</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ul>
                        <li>When generating a certificate or going through the self-signed certificate creation process it is important that:</li>
                        <li>The Common Name (CN) entered matches the fully qualified hostname (or the hostname users will use to connect to DCP) of the DCP system</li>
                        <li>The hostname entered for Common Name (CN) is resolvable from the DCP host and points to the actual DCP system</li>
                        <li>The SSL certificate used can be a certificate chain. ○This may be required if the certificate is signed using an intermediate certificate by the certificate authority or if an internally trusted CA is used to sign the certificate.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default EnablingSsl;
