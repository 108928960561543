import React from 'react';
import $ from "jquery";
import { adapters } from './data.js'

class Adapters extends React.Component {
	componentDidMount () {
		this.init();
	}

	init () {
	    let adapterType = {},
	        currentList = '',
	        searchList = [],
	        selectedType = 'all',

	        setAdapterType = () => {
	            adapterType = {
	                businessIntelligence: [],
	                database: [],
	                fileSystem: [],
	                service: [],
					atomsphere: []
	            }
	        },

	        getIconName = (name) => {
	    		return name.toLowerCase();
	        },

	        generateCards = (list, all) => {
	            list.forEach(adapter => {
	            	let tmp = `
	                    <div class="col-md-3 col-sm-4">
	                        <div class="cards">
	                            <div class="adapters-card__logo">
	                                <img src=${require('./assets/images/adapters/' + getIconName(adapter.adapter_icon_name) + '.svg')}>
	                            </div>
	                            <div class="adapters-card__name">
	                                <span class="adapters-card__name--text">` + adapter.vendor_product_name +`</span>

	                `;
	                if (adapter.vendor_product_version) {
	                    tmp += `
	                                <span class="adapters-card__name--divider"></span>
	                                <span class="adapters-card__name--version">` + adapter.vendor_product_version + `</span>
	                            </div>
	                        </div>
	                    </div>` ;
	                }
	                else {
	                    tmp += `
	                            </div>
	                        </div>
	                    </div>` ;
	                }

	                $('#card-container').append(tmp);  
	            });
	        },
	        
	        showResultCount = (list) => {
	            $("#all").text('All (' + list.length + ')');
	            $("#business-inteligence").text('Business Inteligence (' + adapterType.businessIntelligence.length + ')');
	            $("#database").text('Database (' + adapterType.database.length + ')');
	            $("#file-system").text('File System (' + adapterType.fileSystem.length + ')');
	            $("#service").text('Service (' + adapterType.service.length + ')');
	            $("#atomsphere").text('AtomSphere (' + adapterType.atomsphere.length + ')');
	        },

	        generateCurrentList = () => {
	            searchList.forEach(adapter => {
	                switch (adapter.adapter_category.toLowerCase()) {
	                    case 'business intelligence':
	                        adapterType.businessIntelligence.push(adapter);
	                        break;
	                    case 'database':
	                        adapterType.database.push(adapter);
	                        break;
	                    case 'filesystem':
	                        adapterType.fileSystem.push(adapter);
	                        break;
	                    case 'service':
	                        adapterType.service.push(adapter);
	                        break;
						case 'atomsphere':
							adapterType.atomsphere.push(adapter);
							break;
	                    default:
	                    	break;
	                }
	            });

	            switch (selectedType) {
	                case 'all':
	                    currentList = searchList;
	                    break;
	                case 'business-inteligence':
	                    currentList = adapterType.businessIntelligence;
	                    break;
	                case 'database':
	                    currentList = adapterType.database;
	                    break;
	                case 'file-system':
	                    currentList = adapterType.fileSystem;
	                    break;
	                case 'service':
	                    currentList = adapterType.service;
	                    break;
					case 'atomsphere':
						currentList = adapterType.atomsphere;
						break;
	                default:
						break;
	            }
	            generateCards(currentList, false);
	        },

	        searchInAdaters = (list) => {
	            searchList = [];
	            list.forEach(item => {
	                if (item.vendor_product_name.toLowerCase().indexOf($('input')[0].value.toLowerCase()) !== -1 || 
	                    item.vendor_product_version.toLowerCase().indexOf($('input')[0].value.toLowerCase()) !== -1 ) {
	                    searchList.push(item);
	                }
	            });
	            
	            $('#card-container').empty();
	            if ($('input')[0].value) {
	                $('.close-btn').show();
	            }
	            if (!searchList.length) {
	                $('.empty-state').show();
	            }
	            else {
	                generateCurrentList();
	                $('.empty-state').hide();
	            }
	            showResultCount(searchList);
	            window.scrollTo(0, 0);
	        };
	    
	    currentList = adapters;
	    searchList = adapters;
	    setAdapterType();
	    generateCurrentList();
	    showResultCount(adapters);
	    $('.adapter-container').css('margin-left', $(".side-bar").width() + 18 + 'px');
	    $('.close-btn').hide();
	    $('.empty-state').hide();
	    window.scrollTo(0, 0);

	    // ------- Events ----------
	    $('.adpt-type').on('click', function (e) {
	        $('#card-container').empty();
	        selectedType = e.target.id;
	        switch (selectedType) {
	            case 'all':
	                currentList = searchList;
	                break;
	            case 'business-inteligence':
	                currentList = adapterType.businessIntelligence;
	                break;
	            case 'database':
	                currentList = adapterType.database;
	                break;
	            case 'file-system':
	                currentList = adapterType.fileSystem;
	                break;
	            case 'service':
	                currentList = adapterType.service;
	                break;
				case 'atomsphere':
					currentList = adapterType.atomsphere;
					break;
	            default:
					break;
	        }

	        if (!currentList.length) {
	            $('.empty-state').show();
	        }
	        else {
	            generateCards(currentList, false);
	            $('.empty-state').hide();
	        }

	        $(".adpt-type").removeClass('active');
	        $(this).addClass('active');
	        window.scrollTo(0, 0);
	    });

	    $(window).resize(function(){
	        $('.adapter-container').css('margin-left', $(".side-bar").width() + 18 + 'px');
	    });

	    $('input').on('keyup', () => {
	        setAdapterType();
	        searchInAdaters(adapters);
	    });

	    $('input').on('mouseenter focus keypress', () => {
	        $('input').addClass('visibale-txt');
	    });

	    $('input').on('mouseleave blur', () => {
	        if ($('input').is(":focus") || $('input')[0].value) {
	            return;
	        }
	        else {
	            $('input').removeClass('visibale-txt');
	            $('.close-btn').hide();
	        }
	    });

	    $('.close-btn').on('click', () => {
	        $('input').text('').removeClass('visibale-txt');
	        $('input')[0].value = '';
	        $('.close-btn').hide();
	        $('#card-container').empty();
	        searchList = adapters;
	        setAdapterType();
	        generateCurrentList();
	        $('.empty-state').hide();
	        showResultCount(adapters);
	        $('.empty-state').hide();
	        window.scrollTo(0, 0);      
	    });
	}

	render() {
	  return (
	  	<div className="ug-body clearfix">
	        <div className="ug-left-container col-md-3 col-lg-2">
	            <div className="ug-nav-header">Contents</div>
	            <ul>
	                <li className='menu-item adpt-type active'>
	                    <span id="all">All</span>
	                </li>
					<li className="menu-item adpt-type">
						<span id="atomsphere">Atomsphere</span>
					</li>
	                <li className="menu-item adpt-type">
	                    <span id="business-inteligence">Business Inteligence</span>
	                </li>
	                <li className="menu-item adpt-type">
	                    <span id="database">Database</span>
	                </li>
	                <li className="menu-item adpt-type">
	                    <span id="file-system">File System</span>
	                </li>
	                <li className="menu-item adpt-type">
	                    <span id="service">Service</span>
	                </li>
	            </ul>
	        </div>
	        <div className="ug-right-container col-md-9 col-lg-10">
	        	<div className="adapter-container">
	        		<div className="search-bar">
	                    <input type="text" name="search" placeholder="Filter Adapter" />
	                    <img src={require('./assets/images/close.svg')} alt="" className="close-btn" /> 
	                </div>
	                <div className="row" id="card-container"></div>
	                <div className="empty-state">
	                    <span className="img-container">
	                        <img src={require('./assets/images/adapters/Generic.svg')} alt="" />
	                    </span>
	                    <p className="first-msg">No results found</p>
	                    <p className="second-msg">Change your filter criteria and try again</p>
	                </div>
	        	</div>
	        </div>
	    </div>
	  );
	}
}

export default Adapters;