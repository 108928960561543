import React from "react";

function DataType () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Data Type">
                <div className="ug-content-main-header">Data Types</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>When you import data, the catalog automatically assigns a data type to each column. Many primitive and non-primitive data types are available by default in Manage &#62; Data Types. 
                        You can create additional custom data types to fit your business needs.</p>
                    <h5>Benefits</h5>
                    <ul>
                        <li>Data types help you know how to utilize columns for data management, analysis, and transformation. 
                            For example, it is used when defining fields in a Master Data Hub model</li>
                        <li>Creating data type rules helps your catalog accurately identify Personal Identifiable Information (PII) and mask sensitive data</li>
                    </ul>
                    <h5>Custom data types</h5>
                    <p>You can create custom data types, such as a social security number type, to streamline the classification and searching of data. 
                        After you create custom data types, your data catalog applies the custom data type when you import or re-import data sets.</p>
                    
                    <h5>Regular expressions</h5>
                    <p>Regular expressions (regex) are coded statements used to identify patterns. Regexes are used to assign data types to data. 
                        For example, if the imported data matches the social security number pattern, the social security data type is assigned.</p>
                    <h5>Permissions</h5>
                    <p>Administrators control who can create custom data types. In <strong>Manage</strong> &#62; <strong>Access</strong> &#62; <strong>Role</strong>, assign the <strong>Create</strong> permission to UDT & PII. Users who create data type rules can also create PII rules.</p>
                    <figure>
                        <img alt="Data Type Summary" src={require('../assets/images/data_type_summary_table.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Create Data Type">
                <div className="ug-content-main-header">Create Data Types</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>You can create custom data types to automatically classify data throughout your catalog. Your data catalog applies a custom data type after you import or re-import data.</p>
                    <ol>
                      <li>Navigate to <strong>Manage</strong> &#62; <strong>Data Types</strong>.</li>
                      <li>Click the plus icon.</li>
                      <li>Enter a name for the data type.</li>
                      <li><strong>Optional</strong>: Enter a description.</li>
                      <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                      <li>Enter a regular expression (regex) to indicate the format of the data. The regex is used to automatically identify the data type throughout your catalog. For example, a part ID may contain an 11-digit string starting with a 1. The regex would be:
                        <pre><code>1\d{10}</code></pre>
                      </li>
                      <li><strong>Optional</strong>: Click the PII/Masking Rules link to set up masking and/or PII classification for the data type.</li>
                      <li>Click <strong>Save</strong>. Import or re-import data into your catalog to apply the custom data type to appropriate columns.</li>
                    </ol>
                    <figure>
                        <img alt="Data Type Summary" src={require('../assets/images/data_type_create.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Edit and Delete Data Types">
                <div className="ug-content-main-header">Edit and Delete Data Types</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>To change a custom data type, you must delete the data type and create a new one. 
                        You can delete a custom data type if you have UDT and PII - Delete permissions and you own the data type.</p>
                    <p>When you delete a custom data type, a default data type is assigned to the affected columns. When a deleted custom data type is restored, the default data type remains assigned to columns previously detected with a custom data type. 
                        Anytime a custom data type is deleted and then restored, DCP must discover where the custom data type exists in the catalog again. Re-import data to apply a restored custom data type to columns.</p>
                    <ol>
                      <li>Navigate to <strong>Manage</strong> &#62; <strong>Data Types</strong>.</li>
                      <li>Select the data type.</li>
                      <li>Close the modal and click <strong>More Options</strong> (vertical ellipsis icon) in the Details panel.</li>
                      <li>Select <strong>Delete</strong>.</li>
                    </ol>
                    <figure>
                        <img alt="Data Type Summary" src={require('../assets/images/data_type_edit.png')} />
                    </figure>
                </div>
            </div>
        </section>
    )
}
export default DataType;
