import React from 'react';

function App() {
  return (
  	<div className="dcp-body">
    	<div className="banner-area">
    		<div className="banner-body">
    			<img width="250" height="60" alt="Boomi Data Catalog and Prep Documentation" src={require("./images/Boomi_2-Color_Logo_Reversed.svg")} />
    			<h1>Data Catalog & Prep</h1>
    		</div>
    		<div className="banner-icon"></div>
    	</div>
    	<div className="categories-container">
    		<div className="row">
	    		<div className="category-box col-md-6 col-lg-3">
	    			<a href="#/getting-started" rel="noopener noreferrer">
		    			<div className="inner">
		    				<h2>Getting started</h2>
		    				<p>Get to know the basics of integrating applications and data sources across your IT landscape.</p>
		    			</div>
	    			</a>
		    	</div>
		    	<div className="category-box col-md-6 col-lg-3">
	    			<a href="#/adapters" rel="noopener noreferrer">
		    			<div className="inner">
		    				<h2>Adapters</h2>
		    				<p>Adapters let you build integrations with a wide array of data sources and applications.</p>
		    			</div>
	    			</a>
		    	</div>
				<div className="category-box col-md-6 col-lg-3">
					<a href="#/api" rel="noopener noreferrer">
						<div className="inner">
							<h2>API reference</h2>
							<p>Programmatic access to the DCP platform through APIs</p>
						</div>
					</a>
				</div>
		    </div>
    	</div>
    	<div className="most-popular-topics-container">
    		<div className="box">
    			<div className="row">
			        <div className="col-12 col-md-6"><h2>Most popular topics</h2>
			            <ul className="list-links">
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-eb8e485d-0d81-4cfb-8a0e-10ddd6fecee9" target="_blank" rel="noopener noreferrer">Building your first Boomi Integration process</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-AD96C1C0-CC92-45CD-BC9F-C3D2553A39CA" target="_blank" rel="noopener noreferrer">Process building</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-DA0E9B53-58A0-44FD-B47A-BD23D788AAB6" target="_blank" rel="noopener noreferrer">The Boomi Atom Clouds</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-84307537-99FB-46A0-8963-F3A03EFEE60A" target="_blank" rel="noopener noreferrer">Atoms</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-43F13F31-E82A-4AF8-BE49-9213D4715411" target="_blank" rel="noopener noreferrer">Integration and iPaaS</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-19c17ab6-b3fe-4e69-95f2-bcb438f3fa47" target="_blank" rel="noopener noreferrer">Data Collection</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-46A94AE7-7C1F-4C57-89E1-621BA07ADEA2" target="_blank" rel="noopener noreferrer">Process shapes</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-4b20131f-8460-4a0c-935f-5f32320ed6ad" target="_blank" rel="noopener noreferrer">Core terms and concepts</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-9B5D3D7E-0DBB-46BB-BE3D-41C3021753A1" target="_blank" rel="noopener noreferrer">Map shape</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-054AEAE8-B869-4B34-BCA5-177F3191F1A5" target="_blank" rel="noopener noreferrer">Profile components</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-CA9E44A0-5621-46AE-8419-C334B374D7E3" target="_blank" rel="noopener noreferrer">Map components</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-2F422B42-B6C8-4EDD-BD57-75832B708BDF" target="_blank" rel="noopener noreferrer">Environment management</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-A73CDE8C-98C4-4849-8A34-5030BAB5874D" target="_blank" rel="noopener noreferrer">Application connectors</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-391FB951-69DC-484C-8B1E-4248CB9368BF" target="_blank" rel="noopener noreferrer">NetSuite operation</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-62DA4589-9FF6-49AA-920A-E7F073702B65" target="_blank" rel="noopener noreferrer">Salesforce operation</a>
			                </li>
			            </ul>
			        </div>
			        <div className="col-12 col-md-6"><h2>Top tasks</h2>
			            <ul className="list-links">
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-36828270-9BB7-42C3-8618-0E178BCCCF9E" target="_blank" rel="noopener noreferrer">Adding an Atom Cloud</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-C2C15EAA-512D-4167-AB24-B5B9D458CE9C" target="_blank" rel="noopener noreferrer">Creating a component using the New button</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-920D4620-2154-411E-B16D-192D57CAA3FC" target="_blank" rel="noopener noreferrer">Adding a map to a process</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-C38D8431-B9F9-419E-A679-608FF25E1851" target="_blank" rel="noopener noreferrer">Mapping elements from source to destination</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-34EAF477-2505-47A4-919B-0FFFFE33249B" target="_blank" rel="noopener noreferrer">Importing an XML profile from an XSD file</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-C4A32570-96AB-4F54-891B-986BB2F14F41" target="_blank" rel="noopener noreferrer">Running a process in test mode</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-34b9e773-ef43-40e1-8147-f8f40a803ae2" target="_blank" rel="noopener noreferrer">Creating a single packaged component from the Packaged Components page</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-C3068B33-9CD6-4B21-BEDA-47A39161FE93" target="_blank" rel="noopener noreferrer">Attaching an Atom to or detaching an Atom from an environment</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-E3578A25-9350-493F-836B-2DCBB259A3DA" target="_blank" rel="noopener noreferrer">Finding processed documents</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/bundle/integration/page/t-atm-Configuring_an_API_component.html" target="_blank" rel="noopener noreferrer">Configuring an API Service component</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-D443D104-A533-4745-ADC8-3D15C26FBDA4" target="_blank" rel="noopener noreferrer">Installing an integration pack</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-9F663B50-6C13-4449-A0A4-C2E617742343" target="_blank" rel="noopener noreferrer">Performing a saved or advanced search in NetSuite</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-590C3D4B-0C9C-44BF-9C80-1DD3C6175F2B" target="_blank" rel="noopener noreferrer">Replacing your Salesforce password with a password and security token</a>
			                </li>
			                <li>
			                	<a href="https://help.boomi.com/csh?context=GUID-9DD8A495-9D26-469A-9DAD-4DE8EF3A799D" target="_blank" rel="noopener noreferrer">Using custom SOQL in Salesforce Get operations</a>
			                </li>
			            </ul>
			        </div>
    			</div>
			</div>
    	</div>
    </div>
  );
}

export default App;
