import React from "react";

function Trash () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Trash">
                <div className="ug-content-main-header">Trash</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p><b>Trash summary page</b></p>
                    <figure>
                        <img alt="Trash" src={require('../assets/images/trash_summary_page.png')} />
                    </figure>
                    <p><b>Restore confirmation modal</b></p>
                    <figure>
                        <img alt="Trash" src={require('../assets/images/restore_confirmation_modal.png')} />
                    </figure>
                    <p><b>Delete confirmation modal</b></p>
                    <figure>
                        <img alt="Trash" src={require('../assets/images/delete_confirmation_modal.png')} />
                    </figure>
                </div>
            </div>
        </section>
    )
}

export default Trash;
