import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import $ from 'jquery';

class Menu extends Component {
	constructor () {
		super();
		
		$(".navigation-item > a").on("click", function (e) {
			$(this).toggleClass("active");
			e.stopPropagation();
		});
	}
	
	render () {
		return (
			<div className="navigation">
				<ul className="navigation-wrapper">
					<li className="navigation-item active">
			          <a className="nav-link" href="https://help.boomi.com/category/integration" target="_blank" rel="noopener noreferrer">Boomi Integration</a>
			          <ul className="sub-navigation-wrapper">
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-B522EE93-E8A2-43CC-9D3E-EF37371AEF32">Getting started</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-C6847C47-5EFF-4933-ADA1-A47D032471C6">API reference</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-9268B9BC-9B07-4771-9AAF-D73642ABDDCD">Connectors</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-BB52F411-FC66-45DD-804E-478736CE7B05">B2B/EDI management</a>
			          	</li>
			          </ul>
			        </li>

			        <li className="navigation-item">
			           <a className="nav-link" href="https://help.boomi.com/category/hub">Master Data Hub</a>
			          <ul className="sub-navigation-wrapper">
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-002458E4-E1CC-416C-9F86-4E843CFFFE30">Getting started</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-457504D2-5A67-4EBB-B8CB-D6E1159BB388">API reference</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-CB327719-6452-4F31-84BA-ADFF903970AB">Modeling</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-7B375F46-F717-40B7-9DBE-92C03D05A943">Stewardship</a>
			          	</li>
			          </ul>
			        </li>

			        <li className="navigation-item">
			            <a className="nav-link" href="https://help.boomi.com/category/api-management">API Management</a>
			          <ul className="sub-navigation-wrapper">
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-6E1D48A0-0ADB-4878-8BE8-7AC361F7E8CE">Getting started</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-00795413-4D04-4D5F-A518-A809F119CBA6">API reference</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-67B0185E-B36C-41F0-8EE4-4DA75E34D639">Deployed APIs</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-ddddb1bf-f768-4698-b175-2a2ae701d6ae">API Gateway</a>
			          	</li>
			          </ul>
			        </li>

			        <li className="navigation-item">
			          <a className="nav-link" href="https://help.boomi.com/category/flow" target="_blank" rel="noopener noreferrer">
			            Boomi Flow
			          </a>
			          <ul className="sub-navigation-wrapper">
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-f26280db-5b73-46dd-b087-4423f5dc6774">Getting started</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-98cb7642-0e91-49b3-845a-c28f7451f134">Setting up and managing flows</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://help.boomi.com/csh?context=GUID-3dba9a15-316f-4134-9093-d4811ea7d14f">Building and publishing flows</a>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<a className="nav-link" target="_blank" rel="noopener noreferrer" href="https://docs.manywho.com/">Legacy Boomi Flow documentation</a>
			          	</li>
			          </ul>
			        </li>

			        <li className="navigation-item">
			          <a className="nav-link active" href="/">
			            Data Catalog & Prep
			          </a>
			          <ul className="sub-navigation-wrapper">
			          	<li className="sub-navigation-item">
			          		<NavLink className="nav-link" activeClassName="active" to="/getting-started">
			          			Getting started
			          		</NavLink>
			          	</li>
			          	<li className="sub-navigation-item">
			          		<NavLink className="nav-link" activeClassName="active" to="/adapters">
			          			Adapters
			          		</NavLink>
			          	</li>
						<li className="sub-navigation-item">
							<NavLink className="nav-link" activeClassName="active" to="/api">
								API reference
							</NavLink>
						</li>
			          </ul>
			        </li>
		      	</ul>
		      	
		    </div>
		);
	}
}



export default Menu;
