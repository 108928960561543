import React from "react";

function Settings() {
  return (
    <section>
      <div className="ug-content-wrapper" data-content="Settings">
        <div className="ug-content-main-header">Settings</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>DCP provides a variety of configuration settings.</p>
          <p>To access settings</p>
          <ol>
            <li>
              Log in to the DCP user interface with a user account that has
              administrator permissions, such as the Boomi user.
            </li>
            <li>
              In the left navigation bar, choose Settings. The Settings screen
              is displayed.
            </li>
            <li>
              Click the Search icon and type the name (or part of the name) of
              the setting you want to display. If you do not know the name of
              the setting, choose the category of settings that you want to work
              with: email, general, Hive, LDAP, labs, or system.
            </li>
            <li>
              The Labs settings are used to configure features that are in a
              beta release state. These features should be considered
              experimental as they are not yet fully supported for general
              availability release.
            </li>
            <li>
              Enter the values you want to set. A brief description is displayed
              to help you decide whether to change the setting.
            </li>
            <li>When you are finished, click SAVE.</li>
          </ol>



        </div>
      </div>
      <div
        className="ug-content-wrapper"
        data-content="Authentication Settings"
      >
        <div className="ug-content-main-header">Authentication Settings</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
          The Authentication page lets you control the method of user authentication and 
          various authentication settings, such as token expiration. You can also manage tokens for the DCP API.</p>
          <h4>Authentication Method</h4>
            <p>
            You have several options when setting the authentication method for
            your DCP instance.
            </p>
            
            <ul>
              <li><strong>Local/LDAP</strong> &#8212; Sign in using a local username
              and password or using your Lightweight Directory Access Protocol
              (LDAP) provider.</li>
              <li><strong>SAML2</strong> &#8212; Sign in using single sign-on (SSO)
              via the Security Assertion Markup Language 2.0 (SAML2) protocol.
              </li>
              <li><strong>IMS OAuth</strong> &#8212; Sign in using SSO via the Open
              Authorization (OAuth) protocol.</li>
              <li><strong>Boomi SSO</strong> &#8212; Sign in using your Boomi platform account credentials. Boomi SSO lets you seamlessly transition through the platform’s services without requiring re-authentication. This setting is only available for DCP Boomi platform and Boomi Data Catalog.</li>
              <li><strong>OIDC</strong> &#8212; Sign in using OpenID Connect (OIDC) for authentication.</li>
            </ul>
          <h4>Authentication Settings</h4>
            <ul>
              <li><strong>Allow Role Assignment in DCP</strong> &#8212; Controls the ability to assign a DCP role to a user within the user interface when authenticating with the Boomi SSO, SAML2, or OIDC options. 
                This setting is selected by default. If deactivated, user roles must be assigned from the identity provider.</li>
              <li><strong>Validate Source IP</strong> &#8212; Controls validation that an IP address issued with the JWT matches the IP address of the request as part of authentication with Boomi SSO. This setting is activated by default for DCP Boomi platform and Boomi Data Catalog users. You may need to deactivate this setting in scenarios where the user’s connection may appear to originate from a different IP address between requests, 
                such as when using a load-balanced reverse proxy or network security application.</li>
              <li><strong>Refresh Token Expiration</strong> &#8212; Controls the duration of time before a login session using single sign-on expires. By default, the duration is set to 12 hours.</li>
              <li><strong>Enable API token</strong> &#8212; Controls the ability for users to create API tokens in the user interface.</li>
              <li><strong>API token expiration</strong> &#8212; Controls the number of days before API tokens expire. By default, duration is set to 30 days.</li>
            </ul>

          <h4>API Token Management</h4>
          <p>
            Before you can create and manage API tokens, select the
            <strong> Enable API Token </strong> check box. Administrators can
            create tokens and delete any tokens created by other users.
            Administrators can grant the permission to create tokens in{" "}
            <strong>Manage</strong> &#62;
            <strong> Access</strong> &#62; <strong> Roles</strong> &#62;
            <strong> API Token</strong>. Non-admin roles can only create and
            delete their own tokens.
          </p>
          <p>
            The default setting for token expiration is 30 days. Tokens are
            created in the API Token page (<strong>Manage</strong> &#62;{" "}
            <strong>System</strong> &#62; <strong>API Token</strong>
            ). To learn more, read the{" "}
            <a
              href="https://help.boomi-dcp.com/#/api"
              target="_blank"
              rel="noopener noreferrer"
            >
              API documentation
            </a>
            .
          </p>
          <p>To allow users to create and delete tokens:</p>
          <ol>
            <li>
              From the main navigation panel, select <strong>Manage</strong>{" "}
              &#62; <strong>Access</strong> &#62; <strong>Roles</strong>.
            </li>
            <li>Scroll down to API Token.</li>
            <li>
              Select the column for the role to which you want to allow access.
            </li>
            <li>
              Click <strong>Allow</strong>.
            </li>
            <li>
              Click <strong>Save</strong>.
            </li>
          </ol>
          <h6>DCP Python Client</h6>
          <p>
            When you instantiate the Python Client object, you can pass in a
            keyword parameter <em>authtoken</em> with the value of the API
            token. You can use the client object similar to how you would
            authenticate using a username and password combination.
          </p>
          <p>Request example:</p>
          <pre>
            <code>
              from unifi.dataiapi.client import get_client <br></br>
              client = get_client('1.0', authtoken=‘&#123;AUTH_TOKEN&#125;’)
              <br></br>
              resp = client.get_server_info() 
            </code>
          </pre>
          <h6>DCP REST API</h6>
          <p>
            When using the token, an Authorization header must be included in
            the request.
          </p>
          <p>Request example:</p>
          <pre>
            <code>
              curl -H ‘Authorization: Token &#123;AUTH_TOKEN&#125;’
              ‘https://&#123;HOSTNAME&#125;:&#91;PORT&#93;/datai-api/roles/'
            </code>
          </pre>
        </div>
      </div>
      
     

      <div
        className="ug-content-wrapper"
        data-content="Setting Up OpenID Connect Authentication"
      >
        <div className="ug-content-main-header">Setting Up OpenID Connect Authentication using Okta</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>OpenID Connect (OIDC) authentication is available for instances that want to use their own identity provider (for example, Okta) or for 
            DCP Classic instances that cannot use Boomi’s single sign-on OIDC authentication with Okta.</p>
          <p>Prerequisites:</p>
          <ul>
            <li>Okta administrator account</li>
            <li>DCP Administrator access</li>
            <li>User accounts set up in Okta.</li>
          </ul>
          
          <p><strong>Note</strong>: Before you begin, ensure that you have Okta users assigned to a group. You will <a href="https://help.okta.com/en-us/Content/Topics/users-groups-profiles/usgp-assign-group-people.htm" className="link">assign a group of users</a> to the application in step 9.</p>
          
          <p>The following steps cover the minimum requirements to set up OIDC authentication for DCP Classic. If you have DCP Boomi platform, contact <a href="https://community.boomi.com/s/support" className="link">Boomi Support</a> to set up OIDC authentication. 
            Review Okta’s <a href="https://help.okta.com/oie/en-us/Content/Topics/Apps/Apps_App_Integration_Wizard_OIDC.htm" className="link">Create OIDC app integrations</a> article for more information.</p>
          <ol>
            <li>In the Okta dashboard, navigate to <strong>Applications</strong> &#62; <strong>Applications</strong>.</li>
            <li>Click <strong>Create App Integration</strong>.</li>
            <li>In Sign-in method, select <strong>OIDC - OpenID Connect</strong>.</li>
            <li>In Application type, select <strong>Web Application</strong>.</li>
            <li>Click <strong>Next</strong>.</li>
            <li>Enter an app integration name. For example, DCP.</li>
            <li>In Grant type, ensure that the <strong>Authorization Code</strong> check box is selected. This is the default setting. Ensure other Grant type check boxes are deselected.
              <figure>
                <img alt="Grant type section with only Authorization Code selected" src={require('../assets/images/img-dcp-OIDC_grant_type.png')} />
              </figure>
            </li>
            <li>In Sign-in redirect URIs, enter your redirect uri, which is based on your DCP URL (and port, if applicable) where the authentication response is sent.<br></br> 
                For example, if your DCP URL is <pre><code>https://&#123;your dcp domain&#125;/datai</code></pre>  
                enter <pre><code>https://&#123;your dcp domain&#125;:&#91;port&#93;/datai-api/oidc/callback</code></pre> <br></br>
                You have the option to add a wildcard. For example, <pre><code>https://&#123;your domain&#125;&#42;/datai-api/oidc/callback</code></pre></li>
            <li>In Assignments, select <strong>Limit access to selected groups</strong>. Enter the name of your group that represents all DCP user accounts.
              <figure>
                <img alt="Selected limit access option and using the Everyone group" src={require('../assets/images/img-dcp-group-access.png')} />
              </figure>
            </li>
            <li>Click <strong>Save</strong>.</li>
            <li>The Client Credentials display. Save the <strong>Client ID</strong> and <strong>Client Secret</strong> to a text file.</li>
            <li>In DCP, navigate to <strong>Manage</strong> > <strong>Settings</strong> > <strong>Authentication</strong>.</li>
            <li>In Authentication Method, select <strong>OIDC</strong>.</li>
            <li>Enter the <strong>OIDC app Client Secret</strong> obtained from step 11.</li>
            <li>Enter the <strong>OIDC app Client ID</strong> obtained from step 11.</li>
            <li>Enter the <strong>OIDC Userinfo Endpoint</strong>, <strong>OIDC Authorization Endpoint</strong>, and <strong>OIDC Token Endpoint</strong>. To obtain the endpoints, call the following endpoint: <br></br>
                <pre><code>https://&#123;yourOktaDomain&#125;/.well-known/openid-configuration</code></pre> <br></br>
                For example, the OIDC Userinfo Endpoint is <pre><code>https://&#123;your okta domain&#125;/oauth2/default/v1/userinfo</code></pre> 
            </li>
            <li>Do one of the following: <br></br>
              <ul>
                <li>To use a public key:
                    <ol>
                      <li>Select the public key format in <strong>OIDC PUBLIC KEY Format</strong> (<strong>JWK</strong> or <strong>PEM</strong>).</li>
                      <li>Enter the <strong>OIDC RP PUBLIC KEY</strong>. Public keys take precedence over a JWKS endpoint. JWK public keys can be obtained by calling <br></br>
                         <pre><code>https://&#123;your okta domain&#125;/oauth2/default/v1/keys</code></pre></li>
                    </ol>
                </li>
                <li>To use the JWKS endpoint:
                    <ol>
                      <li>Enter the <strong>OIDC JWKS Endpoint</strong>, which can be obtained in step 16.</li>
                      <li>Ensure that the <strong>OIDC RP PUBLIC KEY</strong> field is blank. Public keys take precedence over a JWKS endpoint.</li>
                    </ol>
                </li>
              </ul>
            </li>
            <li>Enter the <strong>Sign in algorithm</strong>. The default is RS256. You can obtain values by calling the following endpoint: <br></br>
                <pre><code>https://&#123;yourOktaDomain&#125;/.well-known/openid-configuration</code></pre>
            </li>
            <li>Do one of the following: <br></br>
              <ul>
                <li>Ensure the <strong>Allow Role Assignment in DCP</strong> check box is selected. This setting allows you to modify user roles in DCP (<strong>Manage</strong> &#62; <strong>Access</strong>). 
                  If users are created in Okta without a role assignment, they are assigned the Data Analyst role by default.</li>
                <li>Assign roles to users in Okta. Refer to <strong>Assign roles to users in Okta</strong> for steps. <strong>Note</strong>: You can assign roles to users in Okta and select <strong>Allow Role Assignment in DCP</strong> check box to allow you to modify user roles in DCP. 
                If users are created in Okta without a role assignment, they are assigned the Data Analyst role by default.</li>
              </ul>
            </li>
            <li>Click <strong>Save</strong>.</li>
            <li>Click <strong>Sign Out</strong> to log out of DCP. Sign in to DCP so that new authentication settings take effect. <br></br>
                <strong>Note</strong>: If there is an error in the authentication settings and you are locked out of your instance, use the following command to reset your instance to the local/LDAP login method: 
                <pre><code>$ scripts/sbin/unifi_reset_authtype</code></pre>

            </li>
          </ol>
          <h4>Assign role to users in Okta</h4>
          <p>Refer to Okta’s <a href="https://help.okta.com/oie/en-us/Content/Topics/users-groups-profiles/usgp-add-custom-user-attributes.htm" className="link">Add customer attributes to an Okta user profile</a> for more information.</p>
          <ol>
            <li>In the Okta dashboard, navigate to <strong>Directory</strong> > <strong>Profile Editor</strong>.</li>
            <li>Open the default DCP user profile.</li>
            <li>Click <strong>Add Attribute</strong>.</li>
            <li>Create a custom DCP role attribute using the following settings: 
              <ol>
                <li><strong>Data Typ</strong>e: String</li>
                <li><strong>Display Name</strong>: BoomiDCP Role</li>
                <li><strong>Variable name</strong>: role</li>
                <li><strong>Description</strong>: BoomiDCP Role</li>
                <li><strong>Enum</strong>: select the check box</li>
                <li><strong>Attribute Members</strong>: Administrator, Data Steward, Data Engineer, Data Analyst, and Data Catalog Manager. For each member, add the role name as the Display name and value. 
                  For example, Display name: Administrator Value: Administrator.</li>
              </ol>
            </li>
            <li>Select <strong>Attribute required</strong>.</li>
            <li>Select <strong>User personal</strong>.</li>
            <li>Click <strong>Save</strong>. The BoomiDCP Role is now available to all users within DCP.</li>
            <li>Assign users to the DCP role so they can use DCP with Okta. In the Okta dashboard, navigate to <strong>Applications</strong> &#62; <strong>Applications</strong>.</li>
            <li>Search and click <strong>BoomiDCP</strong> (or the app name you entered in step 6) to open the settings page for the DCP integration.</li>
            <li>Click the <strong>Assignments</strong> tab.</li>
            <li>You can assign DCP access to users or groups.
              <ul>
                <li>To assign to users: 
                  <ol>
                    <li>Click <strong>Assign</strong> and select <strong>Assign to People</strong>.</li>
                    <li>In the Assign BoomiDCP to People dialog box, click <strong>Assign</strong> next to the user.</li>
                    <li>Select the <strong>BoomiDCP Role</strong>.</li>
                    <li>Click <strong>Save and Go Back</strong>. Repeat the steps for each user.</li>
                    <li>Click <strong>Done</strong>.</li>
                  </ol>
                </li>
                <li>To assign to groups:
                  <ol>
                    <li>Click <strong>Assign</strong> and select <strong>Assign to Groups</strong>.</li>
                    <li>In the Assign BoomiDCP to Groups dialog box, click <strong>Assign next to Boomi DCP Administrator</strong>.</li>
                    <li>Select <strong>Administrator</strong> as the BoomiDCP Role.</li>
                    <li>Click <strong>Save and go back</strong>.</li>
                    <li>Repeat the steps for each role.</li>
                    <li>Click <strong>Done</strong>.</li>
                  </ol>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Setting Up SAML2 Authentication">
        <div className="ug-content-main-header">Setting Up SAML2 Authentication using Okta</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>Prerequisites:</p>
          <ul>
            <li>Okta administrator account</li>
            <li>DCP Administrator access</li>
            <li>User accounts set up in Okta</li>
          </ul>
          <p><strong>Note</strong>: Before you begin, ensure that you have Okta users assigned to a group. You will assign a group of users to the application. Refer to the following
            documentation for more information.
            <ul>
              <li><a href={"https://help.okta.com/oie/en-us/Content/Topics/Apps/Apps_App_Integration_Wizard_SAML.htm"} target={"_blank"} rel={"noopener noreferrer"} className={"link"}>Create SAML app integrations</a>.</li>
              <li><a href={"https://help.okta.com/oie/en-us/Content/Topics/users-groups-profiles/usgp-add-custom-user-attributes.htm"} target={"_blank"} rel={"noopener noreferrer"} className={"link"}>Add custom attributes to a user profile</a>.</li>
              <li><a href={"https://help.okta.com/oie/en-us/Content/Topics/Provisioning/lcm/lcm-assign-app-groups.htm"} target={"_blank"} rel={"noopener noreferrer"} className={"link"}>Assign an app integration to a group</a>.</li>
              <li><a href={"https://help.okta.com/oie/en-us/Content/Topics/Provisioning/lcm/lcm-assign-app-user.htm"} target={"_blank"} rel={"noopener noreferrer"} className={"link"}>Assign an app integration to a user</a>.</li>
            </ul>
          </p>
          <h4>Configure SAML Settings in Okta</h4>
          <p>The following steps cover the minimum requirements to set up SAML2 authentication for DCP Classic. 
            If you have DCP Boomi platform, contact <a href={"https://community.boomi.com/s/support"} target={"_blank"} rel={"noopener noreferrer"} className={"link"}>Boomi Support</a> to set up SAML2 authentication.</p>
          <ol>
            <li>In the Okta admin dashboard, Navigate to <strong>Applications</strong> &#62; <strong>Applications</strong>.</li>
            <li>Click <strong>Create App Integration</strong>.</li>
            <li>In Sign-in method, select <strong>SAML 2.0</strong>.</li>
            <li>Click <strong>Next</strong>.</li>
            <li>Enter an app integration name. For example, DCP.</li>
            <li>Click <strong>Next</strong>.</li>
            <li>In the <strong>Single sign on URL</strong> field, enter the service URL. Your service URL is the URL that appears in your internet browser when you access DCP. Add the port if applicable. For example:
              <pre><code>https://&#123;your dcp domain&#125;:&#91;port&#93;/datai-api/saml-acs/</code></pre>  
              You must include the last / in the URL.</li>
            <li>Select the <strong>Use this for Recipient URL and Destination URL</strong> check box.</li>
            <li>In the <strong>Audience URI(SP Entity ID)</strong> field, enter boomidcp or or app name you entered in step 5.</li>
            <li>In the <strong>Name ID format</strong> field, select <strong>Unspecified</strong>.</li>
            <li>In the <strong>Application username</strong> field, select <strong>Okta username</strong>.</li>
            <li>In the <strong>Update application username on</strong> field, select <strong>Create and update</strong>.</li>
            <li><strong>In Attribute Statements (optional)</strong> field, enter the following 5 attributes that are inserted into the SAML assertions sent to DCP. 
              The attributes entered here should match the attributes entered in the Authentication section in DCP (<strong>Manage</strong> &#62; <strong>Settings</strong> &#62; <strong>Authentication</strong>). 
              Attribute names are case-sensitive in DCP. Incorrect values can prevent you from logging in to DCP. The values in Name correspond with values automatically entered in  
              <strong>Manage</strong> &#62; <strong>Settings</strong> &#62; <strong>Authentication</strong> for SAML2. Values must match. For example, If Name = firstName, SAML2 First Name Attribute = firstName.
              <ul>
                <li><strong>Name</strong>: firstName / <strong>Name format</strong>: Basic / <strong>Value</strong>: user.firstName</li>
                <li><strong>Name</strong>: lastName / <strong>Name format</strong>: Basic / <strong>Value</strong>: user.lastName</li>
                <li><strong>Name</strong>: email / <strong>Name format</strong>: Basic / <strong>Value</strong>: user.email</li>
                <li><strong>Name</strong>: username / <strong>Name format</strong>: Basic / <strong>Value</strong>: user.login</li>
                <li><strong>Name</strong>: role / <strong>Name format</strong>: Basic / <strong>Value</strong>: appuser.role</li>
              </ul>
            </li>
            <li>Click <strong>Next</strong>.</li>
            <li>Select the <strong>I’m an Okta customer adding an internal app</strong> check box.</li>
            <li>Click <strong>Finish</strong>.</li>
            <li>In the Sign On tab of the Settings page, click the <strong>Identity Provider metadata</strong> link.</li>
            <li>Copy the IdP metadata URL that appears in the browser. This URL is entered in the SAML2 IdP Metadata URL field in DCP.</li>
            <li>In DCP, navigate to <strong>Manage</strong> &#62; <strong>Settings</strong> &#62; <strong>Authentication</strong>.</li>
            <li>Do one of the following: 
              <ul>
                <li>Ensure the <strong>Allow Role Assignment in DCP</strong> check box is selected. This setting allows you to modify user roles in DCP (<strong>Manage</strong> > <strong>Access</strong>). 
                  When users are created, they are assigned the Data Analyst role by default.</li>
                <li>Assign roles to users in Okta. Refer to <strong>Assign roles to users in Okta</strong> for steps. <strong>Note</strong>: You can assign roles to users in Okta and select <strong>Allow Role Assignment in DCP</strong> check box to allow you to modify user roles in DCP. 
                  If the identity provider does not assign a role, the default user role is Data Analyst.</li>
              </ul>
            </li>
            <li>Select <strong>SAML2 </strong>in the Authentication Method drop-down.</li>
            <li>In the <strong>SAML2 IdP Metadata URL</strong> field, paste the URL you copied in step 20.</li>
            <li>Click <strong>Save</strong>.</li>
            <li>Click <strong>Sign Out</strong> to log out of DCP. Sign in to DCP so that new authentication settings take effect. <br></br>
                <strong>Note</strong>: If there is an error in the authentication settings and you are locked out of your instance, use the following command to reset your instance to the local/LDAP login method: 
                <pre><code>$ scripts/sbin/unifi_reset_authtype</code></pre>
            </li>
          </ol>
          <h4>Assign role to users in Okta</h4>
          <p>Refer to Okta’s <a href={"https://help.okta.com/oie/en-us/Content/Topics/users-groups-profiles/usgp-add-custom-user-attributes.htm"} className={"link"}>Add customer attributes to an Okta user profile</a> for more information.</p>
          <ol>
            <li>In the Okta dashboard, navigate to <strong>Directory</strong> > <strong>Profile Editor</strong>.</li>
            <li>Open the default DCP user profile.</li>
            <li>Click <strong>Add Attribute</strong>.</li>
            <li>Create a custom DCP role attribute using the following settings: 
              <ol>
                <li><strong>Data Typ</strong>e: String</li>
                <li><strong>Display Name</strong>: BoomiDCP Role</li>
                <li><strong>Variable name</strong>: role</li>
                <li><strong>Description</strong>: BoomiDCP Role</li>
                <li><strong>Enum</strong>: select the check box</li>
                <li><strong>Attribute Members</strong>: Administrator, Data Steward, Data Engineer, Data Analyst, and Data Catalog Manager. For each member, add the role name as the Display name and value. 
                  For example, Display name: Administrator Value: Administrator.</li>
              </ol>
            </li>
            <li>Select <strong>Attribute required</strong>.</li>
            <li>Select <strong>User personal</strong>.</li>
            <li>Click <strong>Save</strong>. The BoomiDCP Role is now available to all users within DCP.</li>
            <li>Assign users to the DCP role so they can use DCP with Okta. In the Okta dashboard, navigate to <strong>Applications</strong> &#62; <strong>Applications</strong>.</li>
            <li>Search and click <strong>BoomiDCP</strong> (or the app name you entered in step 6) to open the settings page for the DCP integration.</li>
            <li>Click the <strong>Assignments</strong> tab.</li>
            <li>You can assign DCP access to users or groups.
              <ul>
                <li>To assign to users: 
                  <ol>
                    <li>Click <strong>Assign</strong> and select <strong>Assign to People</strong>.</li>
                    <li>In the Assign BoomiDCP to People dialog box, click <strong>Assign</strong> next to the user.</li>
                    <li>Select the <strong>BoomiDCP Role</strong>.</li>
                    <li>Click <strong>Save and Go Back</strong>. Repeat the steps for each user.</li>
                    <li>Click <strong>Done</strong>.</li>
                  </ol>
                </li>
                <li>To assign to groups:
                  <ol>
                    <li>Click <strong>Assign</strong> and select <strong>Assign to Groups</strong>.</li>
                    <li>In the Assign BoomiDCP to Groups dialog box, click <strong>Assign next to Boomi DCP Administrator</strong>.</li>
                    <li>Select <strong>Administrator</strong> as the BoomiDCP Role.</li>
                    <li>Click <strong>Save and go back</strong>.</li>
                    <li>Repeat the steps for each role.</li>
                    <li>Click <strong>Done</strong>.</li>
                  </ol>
                </li>
              </ul>
            </li>
          </ol>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Setting Up SAML2 Authentication using Azure AD">
        <div className="ug-content-main-header">Setting Up SAML2 Authentication using Azure AD</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>You can use Azure Active Directory (AD) as an identity provider to authenticate DCP users with SAML2. 
            Read <a href={"https://docs.microsoft.com/en-us/azure/active-directory/fundamentals/auth-saml"} className={"link"}>Microsoft’s SAML2 documentation</a> for more information.</p>
          <p>Prerequisites:</p>
          <ul>
            <li>SSL/TLS-enabled instance of DCP. By default, DCP AtomSphere and Boomi Data Catalog are SSL enabled</li>
            <li>Azure Portal</li>
            <li>One of the following Azure AD roles:
              <ul>
                <li>Global Administrator</li>
                <li>Cloud Application Administrator</li>
                <li>Application Administrator</li>
                <li>Owner of the service principal</li>
              </ul>
            </li>
            <li>My Apps Secure Sign-In Extension for Chrome and Microsoft Edge</li>
          </ul>
          <h4>Configuring SAML settings in Azure AD</h4>
          <p><strong>Note</strong>: If you are setting up Single Sign-on (SSO) for Boomi Data Catalog or DCP AtomSphere, you will need assistance to complete Configuring SAML settings in DCP. Complete the steps in Configuring SAML 
            Settings in Azure AD and send the following information to Support@Boomi.com:</p>
            <ul>
              <li>Identifier (Entity ID) (from step 14)</li>
              <li>App Federation Metadata URL (from step 20)</li>
            </ul>
          <ol>
            <li>Navigate to <strong>Azure Active Directory Admin Center</strong> &#62; <strong>Manage</strong> &#62; <strong>Enterprise applications</strong>.</li>
            <li>In the Enterprise applications panel, click <strong>All applications</strong>.</li>
            <li>Click <strong>Create your own application</strong>.</li>
            <li>Enter <strong>boomidcp</strong> or an application name of your choice in <strong>What’s the name of your app?</strong>.</li>
            <li>Select <strong>Integrate any other application you don’t find in the gallery (Non-gallery)</strong>.</li>
            <li>In the Azure Active Directory Admin Center, navigate back to <strong>Manage</strong> &#62; <strong>Enterprise applications</strong>.</li>
            <li>Find <strong>boomidcp</strong> or the application name you entered in step 4. Double-click to open the application properties.</li>
            <li>In the Manage section, click <strong>Properties</strong>.</li>
            <li>In Enabled for users to sign-in?, click <strong>Yes</strong>.</li>
            <li><strong>Optional</strong>: Add a Boomi logo PNG file. Logos must be 215 x 215 pixels and 100 KB or less.</li>
            <li>In User assignment required, click <strong>Yes</strong>.</li>
            <li>In Visible to users?, click <strong>Yes</strong>.</li>
            <li>Navigate to <strong>Manage</strong> &#62; <strong>Single sign-on</strong> &#62; <strong>SAML</strong>.</li>
            <li>Click <strong>Edit</strong> next to the Basic SAML Configuration section and update the following properties:
              <ul>
                <li><strong>Identifier (Entity ID)</strong>:  boomidcpssoprimary</li>
                <li><strong>Reply URL (Assertion Consumer Service URL)</strong>:
                https://&#60;yourDCPhost&#62;&#91;:port&#93;/datai-api/saml-acs/ 
                </li>
              </ul>
            </li>
            <li>Click <strong>Save</strong>.</li>
            <li>Click <strong>Edit</strong> next to the User Attributes & Claims section and add the following:
              <table>
                <tr>
                  <td><strong>Name</strong></td>
                  <td><strong>Namespace</strong></td>
                  <td><strong>Source</strong></td>
                  <td><strong>Source attribute</strong></td>
                </tr>
                <tr>
                  <td>email</td>
                  <td>blank</td>
                  <td>attribute</td>
                  <td>user.userprincipalname</td>
                </tr>
                <tr>
                  <td>firstName</td>
                  <td>blank</td>
                  <td>attribute</td>
                  <td>user.givenname</td>
                </tr>
                <tr>
                  <td>lastName</td>
                  <td>blank</td>
                  <td>attribute</td>
                  <td>user.surname</td>
                </tr>
                <tr>
                  <td>username</td>
                  <td>blank</td>
                  <td>attribute</td>
                  <td>user.userprincipalname</td>
                </tr>
                <tr>
                  <td>role</td>
                  <td>blank</td>
                  <td>attribute</td>
                  <td>user.assignedrole</td>
                </tr>
              </table>
            </li>
            <li>Click <strong>Save</strong>.</li>
            <li>Click <strong>Edit</strong> next to the SAML Signing Certificate and update the following:
              <ul>
                <li><strong>Signing option</strong>: Sign SAML response and assertion.</li>
                <li><strong>Signing algorithm</strong>: SHA-256.</li>
              </ul>
            </li>
            <li>Click <strong>Save</strong>.</li>
            <li>Store the <strong>App Federation Metadata URL</strong> in a text file. This information is added to DCP in step 5 of Configure SAML Settings in DCP.</li>
            <li>Navigate to <strong>Manage</strong> &#62; <strong>App registrations</strong>.</li>
            <li>Find <strong>boomidcp</strong> or the application name you entered in step 4. Double-click to open the application properties.</li>
            <li>Navigate to <strong>Manage</strong> &#62; <strong>App roles</strong>. 
            Read <a href={"https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-add-app-roles-in-azure-ad-apps"} className={"link"}>Microsoft’s documentation</a> about adding App Roles for more information.</li>
            <li>Click <strong>App roles</strong>.</li>
            <li>Click <strong>Create app role</strong>.</li>
            <li>Create the following app roles:
              <table>
                <tr>
                  <td><strong>Display name</strong></td>
                  <td><strong>Allowed member types</strong></td>
                  <td><strong>Value</strong></td>
                  <td><strong>Description</strong></td>
                  <td><strong>Enable this app role?</strong></td>
                </tr>
                <tr>
                  <td>Administrator</td>
                  <td>User/Groups</td>
                  <td>Administrator</td>
                  <td>Boomi DCP</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Data Analyst</td>
                  <td>User/Groups</td>
                  <td>Data Analyst</td>
                  <td>Boomi DCP Data Analyst</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Data Engineer</td>
                  <td>User/Groups</td>
                  <td>Data Engineer</td>
                  <td>Boomi DCP Data Engineer</td>
                  <td>Yes</td>
                </tr>
                <tr>
                  <td>Data Steward</td>
                  <td>User/Groups</td>
                  <td>Data Steward</td>
                  <td>Boomi DCP Data Steward</td>
                  <td>Yes</td>
                </tr>
              </table>
            </li>
            <li>Navigate to <strong>Manage</strong> &#62; <strong>Enterprise applications</strong>.</li>
            <li>Find <strong>boomidcp</strong> or the application name you entered in step 4. Double-click to open the application properties.</li>
            <li>Click <strong>Users and groups</strong>.</li>
            <li>Click <strong>Add user/group</strong>.</li>
            <li>Select a user.</li>
            <li>Assign a role to the user.</li>
            <li>Repeat steps 30 through 32 for all users.</li>
          </ol>
          <h4>Configuring SAML settings in DCP</h4>
          <ol>
            <li>Sign in to DCP from Microsoft Edge or Safari. Firefox is not supported. <br></br> 
            <strong>Note</strong>: Keep this browser window open to prevent being locked out of your instance in the event you need to make a correction to your settings.</li>
            <li>Open a separate window in Google Chrome and sign in to DCP.</li>
            <li>Using the Chrome browser logged in to DCP, navigate to <strong>Manage</strong> &#62; <strong>Settings</strong> &#62; <strong>Authentication</strong>.</li>
            <li>In Authentication Method, select <strong>SAML2</strong>.</li>
            <li>Configure the following settings:
              <ul>
                <li>SAML2 Name ID Format: NAMEID_FORMAT_EMAILADDRESS</li>
                <li>SAML2 First Name Attribute: firstName</li>
                <li>SAML2 Last Name Attribute: lastName</li>
                <li>SAML2 Username Attribute: username</li>
                <li>SAML2 Email Address Attribute: email</li>
                <li>SAML2 Unifi Role Attribute: role</li>
                <li>SAML2 Entity ID: boomidcpssoprimary</li>
                <li>SAML2 IdP Metadata: (leave blank)</li>
                <li>SAML2 IdP Metadata URL: (enter the App Federation Metadata URL)</li>
              </ul>
            </li>
            <li>Log out.</li>
          </ol>
          <h4>Testing SAML Setup from Azure AD</h4>
          <ol>
            <li>In <strong>Azure AD</strong>, navigate to <strong>Manage</strong> &#62; <strong>Enterprise applications</strong>.</li>
            <li>Find <strong>boomidcp</strong> or the application name you entered in step 4 of Configure SAML Settings in Azure AD.</li>
            <li>Navigate to <strong>Manage</strong> &#62; <strong>Single sign-on</strong>.</li>
            <li>Click <strong>Test this application</strong>.</li>
            <li>Sign in to DCP with your Azure AD credentials. A successful login displays
              a “Connection successful” message.</li>
          </ol>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="LDAP Settings">
        <div className="ug-content-main-header">LDAP Settings</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Use the following settings to configure your LDAP server settings:
          </p>
          <table>
            <tbody>
              <tr>
                <td>E-mail LDAP Attribute</td>
                <td>mail</td>
              </tr>
              <tr>
                <td>First Name LDAP Attribute</td>
                <td>givenName</td>
              </tr>
              <tr>
                <td>Group Members LDAP Attribute</td>
                <td>member</td>
              </tr>
              <tr>
                <td>Group Name LDAP Attribute</td>
                <td>cn</td>
              </tr>
              <tr>
                <td>LDAP Group Object Filter</td>
                <td>(objectClass=groupOfNames)</td>
              </tr>
              <tr>
                <td>Last Name LDAP Attribute</td>
                <td>sn</td>
              </tr>
              <tr>
                <td>LDAP Authorize All Users</td>
                <td>check</td>
              </tr>
              <tr>
                <td>LDAP Bind as Authenticating User</td>
                <td>check</td>
              </tr>
              <tr>
                <td>LDAP Bind DN</td>
                <td>cn=admin,dc=test,dc=com</td>
              </tr>
              <tr>
                <td>LDAP Bind Password</td>
                <td>&lt;your-password&gt;</td>
              </tr>
              <tr>
                <td>LDAP Bind Password</td>
                <td>&#123;8:0&#125;</td>
              </tr>
              <tr>
                <td>LDAP Connection Options</td>
                <td>Uncheck</td>
              </tr>
              <tr>
                <td>LDAP Find Group Permission</td>
                <td>Uncheck</td>
              </tr>
              <tr>
                <td>LDAP Server Uri</td>
                <td>ldap://35.227.40.1:389</td>
              </tr>
              <tr>
                <td>LDAP Start TLS</td>
                <td>Uncheck</td>
              </tr>
              <tr>
                <td>LDAP TLS CA Certificate File</td>
                <td>Leave Blank</td>
              </tr>
              <tr>
                <td>LDAP Valid Certificate</td>
                <td>Check</td>
              </tr>
              <tr>
                <td>LDAP Search Base DN</td>
                <td>dc=test,dc=com</td>
              </tr>
              <tr>
                <td>LDAP User Object Filter</td>
                <td>(objectClass=inetOrgPerson)</td>
              </tr>
              <tr>
                <td>Username LDAP Attribute</td>
                <td>uid</td>
              </tr>
            </tbody>
          </table>
          <p>Use the following steps to configure your LDAP environment:</p>
          <p>To create groups in your LDAP server:</p>
          <ol>
            <li>Create a child Entry under ou=groups</li>
            <li>Select Default</li>
            <li>
              Select 'groupOfNames' under Object Classes and then Proceed.
            </li>
            <li>Select the rdn attribute as 'cn'</li>
            <li>Enter Group name in CN field and chose Members.</li>
            <li>Click Create Object.</li>
            <li>Group will be created successfully.</li>
          </ol>
          <p>To create a user in your LDAP Server:</p>
          <ol>
            <li>Create a new entry under ou=users.</li>
            <li>Select Generic User Account.</li>
            <li>
              Enter Common Name, First Name, Select 'Posix Don't Touch' under
              GID number, Last Name, Select '/bin/sh' for login Shell, password
              (md5).
            </li>
            <li>Click Create Object.</li>
            <li>
              Once the object is created. Click ‘Add new Attribute’ for created
              user. Select Email. Enter valid email address.
            </li>
            <li>Click Update Object.</li>
          </ol>
        </div>
      </div>
      <div
        className="ug-content-wrapper"
        data-content="Email Settings"
      >
        <div className="ug-content-main-header">Email Settings</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Use the following settings to configure your server email using
            Gmail as an example:
          </p>
          <table>
            <tbody>
              <tr>
                <td>SMTP Server Hostname</td>
                <td>
                  <a
                    href={"http://smtp.gmail.com/"}
                    className={"link"}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    smtp.gmail.com
                  </a>
                </td>
              </tr>
              <tr>
                <td>SMTP Server Port</td>
                <td>465</td>
              </tr>
              <tr>
                <td>SMTP Server Requires Authentication</td>
                <td>Check</td>
              </tr>
              <tr>
                <td>SMTP Server USE SSL</td>
                <td>Check</td>
              </tr>
              <tr>
                <td>SMTP Server Use TLS</td>
                <td>UnCheck</td>
              </tr>
              <tr>
                <td>SMTP User</td>
                <td>Gmail ID (DCP Email ID)</td>
              </tr>
              <tr>
                <td>SMTP User Password</td>
                <td>Gmail Password (DCP Email Password)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Settings;
