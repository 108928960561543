import React from 'react';

function Header() {
  return (
  	<header>
	    <div className="ug-top-header clearfix">
	    	<a href="https://help.boomi.com/">
		        <div className="ug-logo-wrapper">
					<img src={require("../images/logo-skin-boomi.svg")} width="124" height="30" alt="Boomi Documentation" />
		        </div>
		        <div className="version-details">
		            <span className="version-name">Documentation</span>
		        </div>
	        </a>
	        <span className="version-number">Version 4.15</span>
	    </div>
    </header>
  );
}

export default Header;
