import React from "react";

function Appearance() {
  return (
    <section>
      <div className="ug-content-wrapper" data-content="Appearance">
        <div className="ug-content-main-header">Appearance</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/appearance.png")}
            />
          </figure>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="User Avatar">
        <div className="ug-content-main-header">User Avatar</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/user_avatar.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Personally, Identifiable Information (PII)"
      >
        <div className="ug-content-main-header">
          Personally, Identifiable Information (PII)
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            The DCP administrator, Data Steward, or the owner of a dataset can
            mark data as personally identifiable information. PII includes
            information that uniquely or nearly uniquely pertains to a single
            person, such as name, residence address, telephone number, or email
            address.
          </p>
          <p>
            When viewing a dataset in the Dataset Explorer, click the drop-down
            arrow next to the name of a column, and choose Data Security – Mark
            as PII. A small lock icon appears to indicate that this column is
            personally identifiable information. To reverse this setting, return
            to the same column and choose Unmark as PII.
          </p>
          <p>The following PII types are automatically discovered:</p>
          <ul>
            <li>Email address</li>
            <li>Credit Card Number</li>
            <li>International Mobile Equipment Identity Number</li>
            <li>IP address (IPV4 and IPV6)</li>
            <li>Genetic Sequence</li>
            <li>USA - SSN</li>
            <li>USA - Phone Numbers</li>
            <li>USA - Address</li>
          </ul>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Configuring Authorization with Apache Sentry for Hive"
      >
        <div className="ug-content-main-header">
          Configuring Authorization with Apache Sentry for Hive
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            To make the Sentry authorization mechanism available for use with
            Hive datastores, you must configure Hive accordingly. The exact
            procedure will vary depending on the type of Hive you are using
            (Cloudera, MapR, Apache, etc.); refer to the appropriate Cloudera,
            MapR, or Apache documentation for details. In general, the
            configuration tasks are:
          </p>
          <ul>
            <li>Turn on Sentry in the Hive configuration.</li>
            <li>Turn on HDFS integration with Sentry.</li>
            <li>
              In hdfs-site.xml set the default dfs.umaskmode to 012. (Due to a
              known issue in HDFS,{" "}
              <a
                href={"https://issues.apache.org/jira/browse/HDFS-6962"}
                target={"_blank"}
                rel={"noopener noreferrer"}
                className={"link"}
              >
                https://issues.apache.org/jira/browse/HDFS-6962
              </a>
              .)
            </li>
            <li>
              Also in hdfs-site.xml, be sure the hive user is part of the same
              group as the unifi user.
            </li>
            <li>
              Place the Unifi CSV SerDe JAR file on the hiveserver2 machine in
              the directory specified in hive.aux.jars.path, then restart
              hiveserver0032. (Due to a known issue in Apache Sentry,{" "}
              <a
                href={"https://issues.apache.org/jira/browse/SENTRY-905"}
                target={"_blank"}
                rel={"noopener noreferrer"}
                className={"link"}
              >
                https://issues.apache.org/jira/browse/SENTRY-905
              </a>
              .)
            </li>
          </ul>
          <p>More Information</p>
          <p>
            For example, when using Cloudera, you can find more details about
            how to configure Hive Sentry in the following post on the Cloudera
            blog:
          </p>
          <p>
            <a
              href={
                "https://blog.cloudera.com/blog/2015/01/new-in-cdh-5-3-apache-sentry-integration-with-hdfs/"
              }
              target={"_blank"}
              rel={"noopener noreferrer"}
              className={"link"}
            >
              https://blog.cloudera.com/blog/2015/01/new-in-cdh-5-3-apache-sentry-integration-with-hdfs/
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default Appearance;
