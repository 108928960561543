import React from "react";

function Adapters () {
    return(
        <section>
            <div className="ug-content-wrapper" data-content="Adapters">
                <div className="ug-content-main-header">Adapters</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>An adapter is a software package that enables DCP to access data in a datastore. DCP provides adapters for each of the different types of datastores that DCP supports (see <a href={"https://help.boomi-dcp.com/#/adapters"} className={"link"}>Supported Datastores</a>). The adapter serves as a connecting code that enables DCP to communicate with the datastore.</p>
                    <p>For each type of datastore you want to create in the DCP, you must install a corresponding adapter. Installed Adapters are available to create a Datastore from the Create Datastore > Datastore dropdown. After this is done, the available adapters appear in a dropdown list in the Add Data Store dialog.</p>
                    <figure>
                        <img alt="Adapters" src={require('../assets/images/adapters.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Before You Begin: Accessing the Adapters">
                <div className="ug-content-main-header">Before You Begin: Accessing the Adapters</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>An Internet connection is required for the adapter installation to work. The adapter installation script must have HTTPS access to port 443 to download the requested adapter packages. Alternately, if the DCP host does not have the required access, you can manually download and copy the adapter packages to the DCP host using the following steps:</p>
                    <p>*Applicable for the DCP On-Premesis edition only.</p>
                    <ol>
                        <li>
                            Download the adapters from the following link:
                            <p>https://storage.googleapis.com/unifi-hd-4tb/unifi-release/4.3/adapters_no_internet_4_3.tar.gz</p>
                        </li>
                        <li>
                            Untar the adapters and place them in $UNIFI_HOME/opt/adapters/ with the following command:
                            <p>tar xzf <a href={"https://storage.cloud.google.com/unifi-hd-4tb/unifi-release/2.9/adapters_no_internet_2.9.tar.gz"} className={"link"}>adapters_no_internet_4.3.tar.gz</a> -C $UNIFI_HOME/opt/</p>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Adding an Adapter">
                <div className="ug-content-main-header">Adding an Adapter</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Log in to the DCP UI using an account with administrator privileges, such as the unifi user.</li>
                        <li>In the left navigation bar, choose Manage > Adapters.</li>
                        <li>The Adapters page shows a card for each type of adapter supported by DCP. If the adapter is already installed, it is marked with a green border and checkmark, and the logo is greyed out. If the adapter is not already installed, its logo is shown in color. If an update is available, the update notice displays in the upper right corner of the adapter card.</li>
                        <li>Hover the cursor over the adapter you want to install.</li>
                        <li>A green plus icon appears.</li>
                        <li>Click the plus icon.</li>
                    </ol>
                    <p>When the installation is finished, a success message displays.</p>
                    <figure>
                        <img alt="Adapters" src={require('../assets/images/adapters_adding.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="AtomSphere">
                <div className="ug-content-main-header">Connecting Boomi AtomSphere platform</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the AtomSphere Adapter, click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Enter a data store name. For example, Boomi platform.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>AtomSphere Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter the base URL used to access the Boomi platform. For example, https://platform.boomi.com.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <p>Before you begin:</p>
                    <ul>
                        <li>The platform user account must have at least <a href={"https://help.boomi.com/bundle/atomsphere_platform/page/c-atm-User_roles_and_privileges.html"} className={"link"}>standard role permissions</a>.</li>
                        <li>The platform user account must have Build, Read, and Write Access privileges if you are configuring the platform to receive data from DCP.</li>
                        <li>The DCP user role must have at least Create access for Adapter, Data Store, Data Source, and at least Read access for Data Set, 
                            Data Content - Read & See Data, and Data Set Metadata.</li>
                    </ul>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Boomi platform.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select the AtomSphere adapter.</li>
                        <li>Select the AtomSphere data store.</li>
                        <li>Enter your email address associated with your platform account.</li>
                        <li>Enter your platform API token. To create your API token:
                            <ol type="a">
                                <li>In the Boomi platform, click <strong>Settings</strong>.</li>
                                <li>Select <strong>Account Information and Setup</strong>.</li>
                                <li>In the My User Settings section, click <strong>AtomSphere API Tokens</strong>.</li>
                                <li>Click <strong>Add New Token</strong>.</li>
                                <li>Enter a token name.</li>
                                <li>Click <strong>Generate Token</strong>.</li>
                                <li>Click <strong>Copy to Clipboard and Close</strong> and save your token in a text file for future use. You cannot view the token in the platform after you click <strong>Copy to Clipboard and Close</strong>.</li>
                            </ol>
                        </li>
                        <li>Enter your platform account ID, which is located in <strong>Settings</strong> &gt; <strong>Account Information and Setup</strong> &gt; <strong>Account Information</strong>.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Master Data Hub">
                <div className="ug-content-main-header">Connecting Master Data Hub</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the Master Data Hub Adapter, click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Enter a data store name. For example, Master Data Hub.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Enter the Hub Cloud host name. To locate the host name: 
                            <ol type="a">
                                <li>In the Boomi platform, navigate to <strong>Integration</strong> &gt; <strong>Manage</strong> &gt; <strong>Atom Management</strong>.</li>
                                <li>Click the atom.</li>
                                <li>In Atom Information, enter the <strong>Host Name</strong>. For example, USA East Hub Cloud.</li>
                            </ol>
                        </li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <p>Prerequisite:</p>
                    <ul>
                        <li>Boomi platform API token.</li>
                    </ul>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example,  Master Data Hub.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select the Master Data Hub adapter.</li>
                        <li>Select the Master Data Hub data store.</li>
                        <li>Enter the Hub repository name you want to connect to.</li>
                        <li>Enter your platform account ID. To locate your account ID:
                            <ol type="a">
                                <li>In the platform, navigate to <strong>Settings</strong> &gt; <strong>Account Information and Setup</strong> &gt; <strong>Account Information</strong>.</li>
                                <li>Copy the <strong>Account ID</strong>.</li>
                            </ol>
                        </li>
                        <li>Enter your Boomi platform API token. To create your API token:
                            <ol type="a">
                                <li>In the Boomi platform, click <strong>Settings</strong>.</li>
                                <li>Select <strong>Account Information and Setup</strong>.</li>
                                <li>In the My User Settings section, click <strong>AtomSphere API Tokens</strong>.</li>
                                <li>Click <strong>Add New Token</strong>.</li>
                                <li>Enter a token name.</li>
                                <li>Click <strong>Generate Token</strong>.</li>
                                <li>Click <strong>Copy to Clipboard and Close</strong> and save your token in a text file for future use. You cannot view the token in the platform after you click <strong>Copy to Clipboard and Close</strong>.</li>
                            </ol>
                        </li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Google BigQuery">
                <div className="ug-content-main-header">Connecting Google BigQuery</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the BigQuery Adapter, click <strong>Install</strong>.</li>
                        <li>Select <strong>I have read and accept the Terms and Conditions</strong>.</li>
                        <li>Click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, BigQuery.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>BigQuery Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <p>Note: The BigQuery service account must have at least read-only access to objects and object metadata. If BigQuery is used as an output destination, the service account must have read-write privileges.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, BigQuery.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select the BigQuery adapter.</li>
                        <li>Select the BigQuery data store.</li>
                        <li>Enter the <strong>OAuth Client ID</strong> and <strong>OAuth Client Secret</strong>. To obtain the client ID and secret:
                            <ol type="a">
                                <li>Navigate to <strong>Google Cloud Console</strong> &gt; <strong>APIs and Services</strong> &gt; <strong>Credentials</strong>.</li>
                                <li>Click <strong>Create Credentials</strong>.</li>
                                <li>Select <strong>OAuth Client ID</strong>.</li>
                            </ol>
                        </li>
                        <li>Enter the OAuth Callback URL, which is the DCP Host IP and an unoccupied port. The default is http://127.0.0.1:1234.</li>
                        <li><strong>Optional</strong>: Enter the <strong>OAuth Refresh Token</strong>. To create the token:
                            <ol type="a">
                                <li>Add https://developers.google.com/oauthplayground/ to the list of authorized redirect URIs in <strong>Google Cloud Console</strong> &gt; <strong>APIs & Services</strong> &gt; <strong>Credentials</strong>.</li>
                                <li>Visit https://developers.google.com/oauthplayground/.</li>
                                <li>In Step 1, select <strong>Big Query API v2</strong>.</li>
                                <li>Click <strong>Authorize APIs</strong>. Follow the prompts to authorize Google Playground.</li>
                                <li>In Step 2, click <strong>Exchange authorization code for tokens</strong>.</li>
                                <li>Copy the refresh token.</li>
                                <li>Click the gear icon in the top right of the screen.</li>
                                <li>Select <strong>Use your own OAuth credentials</strong>.</li>
                                <li>Enter the <strong>OAuth Client ID</strong> and <strong>OAuth Client Secret</strong> obtained in step 10.</li>
                            </ol>
                        </li>
                         <li>Enter the following in OAuth Scope:
                            <ul>
                                <li>https://www.googleapis.com/auth/cloud-platform</li>
                                <li>https://www.googleapis.com/auth/bigquery</li>
                                 <li>https://www.googleapis.com/auth/bigquery.insertdata</li>
                                <li>https://www.googleapis.com/auth/devstorage.full_control</li>
                                <li>https://www.googleapis.com/auth/devstorage.read_write</li>
                             </ul>
                            <p>Information about scope can be found in Google’s <a href={"https://developers.google.com/identity/protocols/oauth2/scopes#bigquery"} className={"link"}>OAuth 2.0 Scopes</a> article.</p>
                        </li>
                        <li>Enter the <strong>Project ID</strong>. The project ID is located in <strong>Google Cloud Console</strong> &gt; <strong>APIs & Services</strong> &gt; <strong>Manage all projects</strong>.</li>      
                        <li>Enter the <strong>Dataset ID</strong> located in BigQuery.</li>  
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to BigQuery. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="PostgreSQL">
                <div className="ug-content-main-header">Connecting PostgreSQL</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the PostgreSQL Adapter, click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, PostgreSQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>PostgreSQL Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your PostgreSQL host DNS name or IP address.</li>
                        <li>Enter your PostgreSQL port. 5432 is the default port.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, PostgreSQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>PostgreSQL</strong> adapter.</li>
                        <li>Select the PostgreSQL data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the PostgreSQL user name. Users with read-only access can also connect to the database. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>Enter the PostgreSQL password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to PostgreSQL. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Azure SQL">
                <div className="ug-content-main-header">Connecting Azure SQL Database</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the Azure SQL Adapter, click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, Azure SQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Azure SQL Database Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your Azure SQL database host DNS name or IP address. For example, mysqlserver.database.windows.net.</li>
                        <li>Enter your Azure SQL database port. 1433 is the default port.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Azure SQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Azure SQL Database Adapter</strong>.</li>
                        <li>Select the Azure SQL data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the Azure SQL user name. Users with read-only permissions can connect. However, if Azure SQL is an output destination, read-write permissions are required.</li>
                        <li>Enter the Azure SQL password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Azure SQL. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Oracle">
                <div className="ug-content-main-header">Connecting Oracle</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the Oracle Adapter, click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, Oracle.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Oracle</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your Oracle database host DNS name or IP address.</li>
                        <li>Enter your Oracle database port. 1521 is the default port.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Oracle.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Oracle Adapter</strong>.</li>
                        <li>Select the Oracle data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the Oracle user name. Users with read-only permissions can connect. However, if Oracle is an output destination, read-write permissions are required.</li>
                        <li>Enter the Oracle password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Oracle. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Redshift">
                <div className="ug-content-main-header">Connecting Redshift</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the Redshift Adapter, click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, Redshift.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Redshift</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your Redshift cluster JDBC endpoint. To locate the endpoint:
                            <ol type="a">
                                <li>Navigate to AWS console &gt; <strong>Analytics</strong> &gt; <strong>Amazon Redshift</strong>.</li>
                                <li>Click <strong>Clusters</strong>.</li>
                                <li>Select your cluster.</li>
                                <li>In your cluster’s <strong>General Information</strong> section, locate the JDBC URL.</li>
                                <li><p>Remove jdbc:redshift// from the beginning of the URL and remove the port and database name. The endpoint should follow this format:</p>
                                    <p>“cluster-name.xxxx.aws-region.redshift.amazonaws.com”</p>
                                </li>
                            </ol>
                        </li>
                        <li>Enter your Redshift port. 5439 is the default port.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Redshift.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Redshift</strong> adapter.</li>
                        <li>Select the Redshift data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the Redshift master-username, which is the administrator account for the cluster. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>Enter the Redshift master-user-password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Redshift. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Salesforce">
                <div className="ug-content-main-header">Connecting Salesforce</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the Salesforce Adapter, click <strong>Install</strong>.</li>
                        <li>Select <strong>I have read and accept Terms and Conditions</strong>.</li>
                        <li>Click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Service</strong>.</li>
                        <li>Enter a data store name. For example, Salesforce.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Salesforce Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your current Salesforce API Version. Refer to Salesforce’s <a href={"https://help.salesforce.com/s/articleView?id=000386929&type=1"} className={"link"}>Identify your current API version</a> article for assistance.</li>
                        <li>Enter your Salesforce domain. For example, mycompany.my.salesforce.com. Refer to Salesforce’s <a href={"https://help.salesforce.com/s/articleView?id=sf.domain_name_overview.htm&type=5"} className={"link"}>My Domain</a> article for assistance.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <p>Prerequisites: The Salesforce user must have at least read-only access to <strong>My Settings</strong> &gt; <strong>Personal</strong> &gt; <strong>Personal Information and Personal</strong> &gt; <strong>Reset My Security Token</strong>.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Salesforce.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Salesforce</strong> adapter.</li>
                        <li>Select the Salesforce data store.</li>
                        <li>Enter your Salesforce user name. You must have at least read-only access to the Personal Information page in Salesforce (<strong>My Settings</strong> &gt; <strong>Personal</strong> &gt; <strong>Personal Information and Personal</strong> &gt; <strong>Reset My Security Token</strong>).</li>
                        <li>Enter your password.</li>
                        <li>Enter your security token. To obtain the token:
                            <ol type="a">
                                <li>In Salesforce, navigate to <strong>My Settings</strong> &gt; <strong>Personal</strong> &gt; <strong>Reset My Security Token</strong>. Refer to Salesforce’s <a href={"https://help.salesforce.com/s/articleView?id=sf.user_security_token.htm&type=5"} className={"link"}>Reset My Security Token</a> article for assistance.</li>
                                <li>Click <strong>Reset Security Token</strong>. The token is sent to your email address.</li>
                            </ol>
                        </li>
                        <li><strong>Optional</strong>: Enter the Salesforce sandbox name.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Snowflake">
                <div className="ug-content-main-header">Connecting Snowflake</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the Snowflake Adapter, click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, Snowflake.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Snowflake</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your Snowflake account name. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/admin-account-identifier.html#label-account-name"} className={"link"}>Account</a> article for more information.</li>
                        <li>Enter your Snowflake account domain. For example, snowflakecomputing.com</li>
                        <li>Select an authentication type: 
                            <ul>
                                <li>BASIC - authenticate with a username and password.</li>
                                <li>OAUTH - authenticate with Snowflake OAuth 2.0.</li>
                            </ul>
                        </li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source with Basic Authentication</h5>
                    <p>If you selected BASIC as the authentication method for the data store, follow these steps to connect the data source.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Snowflake.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Snowflake Adapter</strong>.</li>
                        <li>Select the Snowflake data store.</li>
                        <li>Enter the <strong>Virtual Warehouse Name</strong>. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/warehouses.html"} className={"link"}>Virtual Warehouse</a> article for more information.</li>
                        <li>Enter the <strong>Database Name</strong>.</li>
                        <li>Enter the Snowflake user name. Users with read-only access can also connect to the database. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>Enter the Snowflake password.</li>
                        <li><strong>Optional</strong>: Select <strong>CLIENT_SESSION_KEEP_ALIVE parameter</strong>. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/sql-reference/parameters.html"} className={"link"}>Parameters</a> article for more information about the CLIENT_SESSION_KEEP_ALIVE parameter.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Snowflake. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow transform pushdown</strong>. This option only applies if you have Catalog and Preparation. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/spark-connector-use.html#pushdown"} className={"link"}>Using the Spark Connector</a> for more information about pushdown.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/jdbc-parameters.html#jdbc-driver-connection-parameter-reference"} className={"link"}>JDBC Driver Connection Properties</a> for more information.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source with OAuth 2.0 Authentication</h5>
                    <p>If you selected OAUTH as the authentication method for the data store, follow these steps to connect the data source.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Snowflake.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Snowflake Adapter</strong>.</li>
                        <li>Select the Snowflake data store.</li>
                        <li>Enter the <strong>Virtual Warehouse Name</strong>. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/warehouses.html"} className={"link"}>Virtual Warehouse</a> article for more information.</li>
                        <li>Enter the <strong>Database Name</strong>.</li>
                        <li>Enter the Snowflake user name. Users with read-only access can also connect to the database. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>In Snowflake, run the following code in a worksheet to create the integration.<br></br>
                                <pre><code>
                                CREATE security integration DCP_APP<br></br>
                                type = oauth<br></br>
                                enabled = true<br></br>
                                oauth_client = custom<br></br>
                                oauth_client_type = 'CONFIDENTIAL'<br></br>
                                oauth_redirect_uri = '&#123;<strong>DCP host URL</strong>&#125;:&#91;<strong>port</strong>&#93;'&#59;

                                    </code></pre>
                                    
                        </li>
                        <li>Enter and run the following:<br></br>
                            <pre><code>SELECT system$show_oauth_client_secrets('DCP_APP')&#59;</code></pre>
                        </li>
                        <li>The OAUTH_CLIENT_SECRET_2, OAUTH_CLIENT_SECRET, and OAUTH_CLIENT_ID display. Copy the values in a text file.</li>
                        <li>In DCP, enter the OAUTH_CLIENT_ID and OAUTH_CLIENT_SECRET.</li>
                        <li>Enter and run the following to fetch all properties of the integration:<br></br>
                           <pre><code>DESCRIBE integration DCP_APP&#59;</code></pre>
                           <p><strong>Note</strong>: Ensure that your current role used to create the integration is not included in the BLOCKED_ROLES_LIST. 
                            If it is, refer to Snowflake’s <a href={"https://docs.snowflake.com/en/sql-reference/sql/alter-security-integration-oauth-snowflake.html"} className={"link"}>Alter Security Integration</a> article to remove your role from the Blocked List. Blocked roles cannot request an authorization code.</p>
                        </li>
                        <li>Enter the OAUTH_REDIRECT_URI in DCP’s <strong>OAuth Callback URL</strong> field.</li>
                        <li><strong>Optional</strong>: Select <strong>CLIENT_SESSION_KEEP_ALIVE parameter</strong>. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/sql-reference/parameters.html"} className={"link"}>Parameters</a> article for more information about the CLIENT_SESSION_KEEP_ALIVE parameter.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to Snowflake. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow transform pushdown</strong>. This option only applies if you have Catalog and Preparation. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/spark-connector-use.html#pushdown"} className={"link"}>Using the Spark Connector</a> for more information about pushdown.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization. Refer to Snowflake’s <a href={"https://docs.snowflake.com/en/user-guide/jdbc-parameters.html#jdbc-driver-connection-parameter-reference"} className={"link"}>JDBC Driver Connection Properties</a> for more information.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>You are redirected to the Snowflake login screen. Sign into Snowflake. <strong>Note</strong>: If your browser uses a pop-up blocker, you must allow your browser to send redirects to Snowflake.</li>
                        <li>Click <strong>Allow</strong> to let DCP access your Snowflake account.</li>
                        <li>After you click <strong>Create</strong> in the data source configuration, you are redirected to the Snowflake login screen. Sign into Snowflake.</li>
                        <li>Click <strong>Allow</strong>. DCP adds the refresh token for authorization to your data source configuration.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
                
            </div>
            <div className="ug-content-wrapper" data-content="Tableau">
                <div className="ug-content-main-header">Connecting Tableau</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the Tableau Adapter, click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>BI</strong>.</li>
                        <li>Enter a data store name. For example, Tableau.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>Tableau Server</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your base URL used to log in to Tableau.</li>
                        <li>Enter the re-synchronize interval in minutes to indicate how often you want to sync with the Tableau server.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <p>Note: You must have site administrator access to connect and must have read access to projects, workbooks, data sources, and views.</p>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, Tableau.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>Tableau Server</strong> adapter.</li>
                        <li>Select the Tableau data store.</li>
                        <li>Enter the <strong>Site ID</strong>. Refer to Tableau’s <a href={"https://help.tableau.com/current/online/en-us/sites_add.htm"} className={"link"}>Site Settings Reference</a> article for more information about the site ID.</li>
                        <li>Enter the user name. The user must have a site administration role and needs at least read access to projects, workbooks, data sources, and views.</li>
                        <li>Enter the password.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="MongoDB">
                <div className="ug-content-main-header">Connecting MongoDB</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the MongoDB adapter, click <strong>Install</strong>.</li>
                        <li>Select <strong>I have read and accept Terms and Conditions</strong>.</li>
                        <li>Click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, MongoDB.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>MongoDB Adapter</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li><strong>Optional</strong>: Select <strong>Connect to MongoDB Atlas Cloud Database</strong> if you are using Atlas Cloud.</li>
                        <li>Enter your MongoDB host name or IP address. For example, sample.host. For MongDB Atlas, enter the replica nodes separated by a comma. You can find a list of replica nodes MongoDB &gt; Deployment &gt; Database in the database overview page. 
                            Do not include the port number that is listed after each replica node.</li>
                        <li>Enter your MongoDB port number. 27017 is the default port. 
                            For MongoDB Atlas, you can find the port number listed after the replica node in MongoDB &gt; Deployment &gt; Database.</li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, MongoDB.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>MongoDB Adapter</strong>.</li>
                        <li>Select the MongoDB data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the MongoDB user name. Users require a read role and listDatabases privilege to connect to the database. Read MongoDB’s <a href={"https://www.mongodb.com/docs/upcoming/reference/privilege-actions/"} className={"link"}>Privilege Actions</a> and <a href={"https://www.mongodb.com/docs/upcoming/core/authorization/"} className={"link"}>Role-Based Access Control</a> to learn more.</li>
                        <li>Enter the MongoDB password.</li>
                        <li>Enter the authentication database name.</li>
                        <li>Select the authentication mechanism. Read MongoDB’s <a href={"https://www.mongodb.com/docs/drivers/go/v1.9/fundamentals/auth/?_ga=2.40673066.943533190.1670344415-1309415329.1670344415&_gac=1.49190356.1670352640.Cj0KCQiA7bucBhCeARIsAIOwr-_0d-mrrkBQ_OSNntWgGykhz33XqZ7jLdRpRDITiHVJcuVGcK_YH1UaAs7hEALw"} className={"link"}>Authentication Mechanisms</a> article to learn more.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="MySQL">
                <div className="ug-content-main-header">Connecting MySQL</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <h5>Install the Adapter</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Adapters</strong>.</li>
                        <li>On the MySQL Adapter, click <strong>Install</strong>.</li>
                    </ol>
                    <h5>Connect the Data Store</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Stores</strong>.</li>
                        <li>Click the plus icon. </li>
                        <li>Click <strong>Database</strong>.</li>
                        <li>Enter a data store name. For example, MySQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select <strong>MySQL</strong> in the <strong>Adapter</strong> drop-down menu.</li>
                        <li>Enter your MySQL host DNS name or IP address. The SQL query SHOW VARIABLES WHERE Variable_name = 'hostname"; shows you the host name.</li>
                        <li>Enter your MySQL port. 3306 is the default port. The SQL query "SHOW VARIABLES WHERE Variable_name = 'port'; shows you the port. </li>
                        <li>Click <strong>Create Data Store</strong>.</li>
                    </ol>
                    <h5>Connect the Data Source</h5>
                    <ol>
                        <li>Navigate to <strong>Manage</strong> &gt; <strong>Data Sources</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a data source name. For example, MySQL.</li>
                        <li><strong>Optional</strong>: Enter a description.</li>
                        <li>Select users that can access the data source. Users that can access the data source can also access all datasets within the source.</li>
                        <li><strong>Optional</strong>: Enter tags. Tags are labels that increase an object’s findability in your catalog.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select <strong>MySQL Adapter</strong>.</li>
                        <li>Select the MySQL data store.</li>
                        <li>Enter the database name.</li>      
                        <li>Enter the MySQL user name. Users with read-only access can also connect to the database. If the data source will be used as a target output, read-write permissions are required.</li>
                        <li>Enter the MySQL password.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow as Output Destination</strong> if you want to send data from a job to MySQL. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Select <strong>Allow Drop on Delete</strong>. This option only applies if you have Catalog and Preparation.</li>
                        <li><strong>Optional</strong>: Click <strong>Advanced Connection Properties</strong>. You can enter additional properties to refine the data source connection, such as specifying additional authorization.</li>
                        <li>Click <strong>Create</strong>.</li>
                        <li>To ensure you are connected, click the data source to open the <strong>Details</strong> panel and click the vertical ellipsis icon.</li>
                        <li>Click <strong>Connection Validation</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Upgrading an Adapter">
                <div className="ug-content-main-header">Upgrading an Adapter</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>After adding an adapter, you are notified when an update is available.</p>
                </div>
            </div>
        </section>
    )
}

export default Adapters;
