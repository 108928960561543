import React from "react";

function AdminOverview() {
  return (
    <section>
      <div
        className="ug-content-wrapper"
        data-content="Administration Overview"
      >
        <div className="ug-content-main-header">Administration Overview</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>Administering DCP consists of:</p>
          <ul>
            <li>License Administration</li>
            <li>Adding adapters</li>
            <li>Adding datastores and data sources</li>
            <li>Provisioning users</li>
            <li>Setting data access permissions</li>
            <li>Making configuration settings</li>
            <li>Backing up and restoring</li>
          </ul>
          <p>
            You will find all these tasks covered in their respective sections.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="License Administration"
      >
        <div className="ug-content-main-header">License Administration</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            License Administration is only applicable for the DCP On-Premesis
            edition. The DCP Boomi platform service does not require the
            administrator to manage licenses.
          </p>
          <p>
            The DCP is initially installed with a demonstration license that is
            effective for 60 days from the time the software was built. As soon
            as possible after installation, you should replace this
            demonstration license with DCP provided license tailored for your
            organization.
          </p>
          <p>
            The license file, UNIFI.license, is located in $UNIFI_HOME. The new
            license you receive from DCP is user based. That is, if you buy a
            license for 50 users, only those 50 users have access to the
            product.
          </p>
          <p>
            When importing users, from LDAP for example, the first users up to
            the license limit are automatically enabled. If your license is for
            50 and you import 100 users, only the first 50 are enabled. To
            enable a user different from the current group of 50 of enabled
            means you must disable a different user first.
          </p>
        </div>
        </div>
        <div className="ug-content-wrapper" data-content="Audit Logs">
          <div className="ug-content-main-header">Audit Logs</div>
          <div className="col-12 col-md-8 col-lg-9 ug-content">
            <p>Audit logs keep track of actions in your instance, including who performed the action, 
               when the action took place, and what objects were involved. Administrators can review 
               user activity and events in <strong>Manage</strong> &gt; <strong>Audit Logs</strong>.</p>
            <h5>Benefits</h5>
              <p>Audit logs help organizations demonstrate compliance with industry and government regulations, such as GDPR, HIPAA, and FedRAMP.  
                You can monitor audit logs to meet your organization&#39;s security standards, maintain data security, and assist in cyber 
                security incident responses.</p>
            <h5>Audit Log Types</h5>
              <p>You can select one of the following log types in the list view:</p>
                <ul>
                  <li>Access - Lists the user name, timestamp, and object the user accessed or was denied access to.</li>
                  <li>Active Session - Lists details about the use of your instance, including user name and IP address. 
                      An active session ends when the time between requests exceeds the time entered in the Active Session Idle Time setting.</li>
                  <li>Authentication - Lists authentication successes and failures, including the use of invalid or expired API tokens.</li>
                  <li>Create Update Delete - Lists the user name, timestamp, and object the user created, deleted, or updated.</li>
                  <li>Endorsement - Lists the user name, timestamp, and dataset or business glossary the user endorsed or unendorsed.</li>
                  <li>Permission - Lists the timestamp, objects, and users involved in permission changes. 
                      For example, the audit log records when a user grants access or receives access to a dataset.</li>
                </ul>
            <h5>Audit Log Exports</h5>
              <p>You can export audit log event details as a CSV file using the <a href={"https://help.boomi-dcp.com/#/api"} className={"link"}>DCP API&#39;s</a> auditlog/&#123;log_name&#125;/export endpoint.</p>
         </div>
      
      </div>
      <div className="ug-content-wrapper" data-content="Viewing Audit Logs">
          <div className="ug-content-main-header">Viewing Audit Logs</div>
          <div className="col-12 col-md-8 col-lg-9 ug-content">
            <p>Administrators can view audit logs in Manage > Audit Logs. Audit logs can be exported as a CSV file 
              using the <a href={"https://help.boomi-dcp.com/#/api"} className={"link"}>DCP API</a>.</p>
            <ol>
              <li>Navigate to <strong>Manage</strong> &gt; <strong>Audit Logs</strong>.</li>
              <li>Click one of the following audit log types:
                <ul>
                  <li><strong>Access</strong> - displays permission change events.</li>
                  <li><strong>Create Update Delete</strong> - displays events when objects were created, updated, or deleted. 
                    The objects logged include tokens, adapters, data stores, data sources, datasets, columns, 
                    jobs, workflows, groups, users, glossaries, search packages, and schedules.</li>
                  <li><strong>Authentication</strong> - displays successful and unsuccessful user authentication events, including the use of invalid tokens.</li>
                  <li><strong>Endorsement</strong> - displays events when datasets and business glossaries were endorsed or unendorsed.</li>
                  <li><strong>Permission</strong> - displays events when access to objects were granted or changed.</li>
                  <li><strong>Active Session</strong> - displays historical information about active usage. See Audit Logs for more information.</li>
                </ul>
              </li>
              <li>Use the filters to narrow your search by keyword, user, target object, event category, and data event timestamp.</li>
              <li>Click the event in the list view to open the <strong>Details</strong> panel. The event text displays details about the event, 
                including the users and the objects involved.</li>
            </ol>
         </div>
      
      </div>
    </section>
  );
}

export default AdminOverview;
