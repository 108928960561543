import React from "react";

function AboutDCP() {
  return (
    <section>
      <div
        className="ug-content-wrapper"
        data-content="About the Boomi Data Catalog & Prep"
      >
        <div className="ug-content-main-header">
          About the Boomi Data Catalog & Prep
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            The Boomi Data Catalog & Prep is the solution for self-service data
            and business intelligence. The Boomi Data Catalog & Prep allows you
            to:
          </p>
          <ul>
            <li>
              Discover, organize, and catalog your data, wherever it is located,
              in whatever form it is in.
            </li>
            <li>
              Prepare your data. The Boomi Data Catalog & Prep provides AI tools
              that simplify the jobs to just a few clicks.
            </li>
            <li>
              Secure, protect and manage access to your data by role, and by
              dataset, row, and column.
            </li>
          </ul>
          <p>
            The Boomi Data Catalog & Prep helps the enterprise more quickly and
            accurately discover, acquire, and transform your data so that it can
            be combined with existing data and analyzed using business
            intelligence and visualization tools to extract business insights.
          </p>
          <p>
            The Boomi Data Catalog & Prep provides a browser-based data
            integration software solution. The Boomi platform runs as a client
            to the data store. Users interact with the Boomi platform through a
            self-service graphical user interface.
          </p>
          <p>
            With the Boomi Data Catalog & Prep, business analysts can select and
            integrate their data sets without having to write code or involve IT
            personnel. This allows the analyst to pursue "what if" scenarios
            with the data and develop business insights much more quickly than
            with traditional hand-coded programming.
          </p>
          <p>
            The Boomi Data Catalog & Prep is a data integration platform built
            natively on top of the Hadoop ecosystem. It uses the Hadoop
            Distributed File System (HDFS) as the data store and works with
            various Hadoop execution components including Hive and MapReduce for
            data transformation.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Software Overview"
      >
        <div className="ug-content-main-header">Software Overview</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            DCP provides a browser-based data integration software solution that
            runs as a client to the datastore. Users interact with DCP through a
            self-service graphical user interface.
          </p>
          <p>
            Diverse data sources are automatically parsed, normalized, and
            presented in a familiar tabular format – with no custom programming
            or manual cleanup.
          </p>
          <p>
            With DCP, business analysts can select and integrate their dataset
            without having to write code or involve IT personnel. This allows
            the analyst to pursue "what if" scenarios with the data and develop
            business insights much more quickly than with traditional hand-coded
            programming.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Data Purging"
      >
        <div className="ug-content-main-header">Data Purging</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>Boomi periodically purges job output activity details older than 30 days to maintain optimal performance. After this information is purged, you can no longer view or export details about job output in the Activities screen. 
            Purging does not remove the job output data in your target data source.</p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Diverse Data Source"
      >
        <div className="ug-content-main-header">Diverse Data Source</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Enterprises need the ability to co-mingle datasets from various data
            sources, including cloud, on-premise, and social. DCP provides rich
            connectivity to heterogeneous data sources including databases,
            Hadoop file systems, Amazon Simple Storage Service (S3), Salesforce
            and Marketo services, and more. Both structured and semi-structured
            data can be integrated for use in analysis scenarios.
          </p>
          <p>
            To integrate the data more quickly, DCP automatically applies
            complex data parsers and data logic to semi-structured and
            unstructured data. In social media data, for example, some structure
            exists, but much of the data must be parsed and normalized before
            any analysis can occur.
          </p>
          <p>
            After passing through the DCP data parser, the data is presented to
            the business analyst user in the familiar format of rows and
            columns. Without DCP, software developers would be required to hand
            code the integration with very technology-oriented tools.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Data Analysis Overview"
      >
        <div className="ug-content-main-header">Data Analysis Overview</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            DCP connects the user directly to data assets and brings diverse
            data sources together for insight discovery. For users who have the
            Data Analyst or Administrator role, data analysis and insight is
            gained using a sequence of steps performed through the browser-based
            user interface:
          </p>
          <ul>
            <li>Select datasets from a wide variety of data sources</li>
            <li>Join datasets</li>
            <li>Add derived column values</li>
            <li>Create data filters</li>
            <li>Define aggregation</li>
            <li>Schedule transform jobs and view output</li>
          </ul>
          <p>
            DCP's open platform seamlessly integrates with a wide range of
            analytics and data visualization tools, including Tableau.
          </p>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Logging In">
        <div className="ug-content-main-header">Logging In</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Open a web browser and use the following URL to access the DCP web
            application, where hostname is the IP address or public DNS hostname
            of the Boomi host machine and port is the HTTP port entered during
            setup (the default port is 8000):
          </p>
          <p>
            http://<b>hostname:port</b>/datai
          </p>
          <p>For example:</p>
          <p>
            <a
              href="http://localhost:8000/datai/"
              target={"_blank"}
              rel="noopener noreferrer"
              className="link"
            >
              http://localhost:8000/datai/
            </a>
          </p>
          <p>To log into the DCP Boomi platform service</p>
          <p>
            Open a web browser and log into the platform using the
            following URL to access DCP web application.
          </p>
          <p>
            <a
              href={"https://platform.boomi.com"}
              className={"link"}
              rel="noopener noreferrer"
              target={"_blank"}
            >
              https://platform.boomi.com
            </a>
          </p>
          <p>
            Once logged in, click on the Open or Open in New Tab links in the
            Data Catalog and Prep section of the Services widget of the home
            screen.
          </p>
          <p>Log in using your DCP specific credentials</p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/login.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Password Assistance"
      >
        <div className="ug-content-main-header">Password Assistance</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            Any DCP user, except for LDAP users, can request to reset their own
            password. The password can be changed in this way only once in a
            24-hour period.
          </p>
          <ol>
            <li>Click Forgot Password on the DCP login screen.</li>
            <li>
              Enter the user’s email address.
              <p>
                DCP sends a password reset link to the email address associated
                with the user’s email address. If it matches the address
                configured in an active user’s account.
              </p>
            </li>
            <li>Click the link in the email.</li>
            <li>Enter the desired new password.</li>
          </ol>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Using the search Interface"
      >
        <div className="ug-content-main-header">Using the search Interface</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            You can find what you are looking for from any place in the DCP UI –
            including the first welcome screen. When you first log in to the DCP
            user interface, the Welcome Screen contains a search box allowing
            you to quickly start your search.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/1.png")}
            />
          </figure>
          <p>
            If you are just starting out, you might type "datasets" to see what
            data is available. If you have already defined several transform
            jobs, you might type "jobs" to get a list so you can run a job,
            check job output, or start a new job based on an old one. You can
            also run more sophisticated searches.
          </p>
          <p>
            The Search dialog is now common to all pages in DCP. In the main
            Search dialog.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/2.png")}
            />
          </figure>
          <p>
            Clicking in the search box expands it and offers additional search
            options:
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/3.png")}
            />
          </figure>
          <p>
            {/*<img alt="Boomi Data Catalog & Prep" src={require('../assets/images/bulb.png')} />*/}
            Things to Try dialog providing templates to help you write search
            queries that mimic natural human speech. The search interface makes
            use of natural language processing (NLP), which translates questions
            phrased as ordinary phrases into database query language.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/4.png")}
            />
          </figure>

          <p>The Things to Try categories are:</p>
          <ul>
            <li>
              Data Dictionary - searches on the data dictionary for the named
              column.
            </li>
            <li>
              Column stats - provides statistics for a specific column in a
              dataset. You can ask for the average.
            </li>
            <li>
              Dataset stats - provides row count, column count, and size
              statistics for a dataset.
            </li>
            <li>
              Relations - provides relationship information about a term or
              dataset.
            </li>
            <li>
              Permissions - displays user permissions for the specified dataset,
              column, or source.
            </li>
          </ul>
          <p>
            {/*<img alt="Boomi Data Catalog & Prep" src={require('../assets/images/trending.png')} />*/}
            Trending - displays the most common searches..
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/5.png")}
            />
          </figure>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/trending_1.png")}
            />
          </figure>
          <p>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/rating.png")}
            />
            Pin - click the "star" to save any of your searches as a "favorite,
            or to "pin" it, making it easier to perform the same search again
            later. You can also "unpin" it by clicking off the star.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/6.png")}
            />
          </figure>
          <p>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/cross.png")}
            />
            Dismiss - dismisses the current search parameters and returns the
            Search box to its original state.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/7.png")}
            />
          </figure>
          <p>
            If you have the appropriate user permissions, you can click Navigate
            to Dashboard to get an overview of all DCP objects and activity.
            Otherwise, click Navigate Away to use the DCP navigation bar and
            menu links to work with the objects for which you have permissions.
          </p>
          <p>
            As you continue to use DCP, look for the search icon or search
            dialog in any screen that provides a list of objects. You can search
            for particular datasets, users, jobs, and so on by typing in the
            search field.
          </p>
          <p>
            DCP also provides additional ways to narrow down your search. For
            example, when searching for datasets, you can first specify a data
            source to see only datasets from a single source. And you can use
            tags to categorize objects with any labels that have meaning in your
            unique usage situation.
          </p>
          <p>
            When your search matches the name of a dataset, you can see how the
            dataset is related to jobs, schedules, and workflows, and how many
            columns are in the dataset. Each column is identified by an icon
            that denotes the type of data in the column, including PII data.
            And, for a job, you can see how many datasets, schedules, and
            workflows the job is related to.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/8.png")}
            />
          </figure>
          <p>
            Clicking the name of an item returned by the search, Dataset, Job,
            Workflow, etc., opens the object for inspection.
          </p>
          {/*<figure>*/}
          {/*    <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/9.png')} />*/}
          {/*</figure>*/}
          <p>
            Click Browser Back or history icon on navigation{" "}
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/history_icon.png")}
            />{" "}
            to return to the search window.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/10.png")}
            />
          </figure>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="NLP">
        <div className="ug-content-main-header">NLP</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/nlp_1.png")}
            />
          </figure>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/nlp_2.png")}
            />
          </figure>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Using Filter">
        <div className="ug-content-main-header">Using Filter</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/filter.png")}
            />{" "}
            Filters are available on all of the Summary pages and allow you to
            refine the information presented to you. The Summary pages are:
          </p>
          <ul>
            <li>Datastores</li>
            <li>Data Sources</li>
            <li>Dataset Explorer</li>
            <li>Jobs</li>
            <li>Workflows</li>
            <li>Schedules</li>
            <li>Activities</li>
            <li>Trash</li>
            <li>Business Glossary</li>
            <li>Datatype</li>
            <li>Rules Manager</li>
            <li>Search</li>
          </ul>
          <p>For example, the Datastores Summary page filters by:</p>
          <ul>
            <li>Keyword</li>
            <li>Type</li>
            <li>Adapter</li>
            <li>Owner</li>
            <li>Date modified</li>
          </ul>
          <p>While the Data Sources Summary page filters by:</p>
          <ul>
            <li>Keyword</li>
            <li>Type</li>
            <li>Data Stores</li>
            <li>Adapter</li>
            <li>Owner</li>
            <li>Status</li>
            <li>Date</li>
            <li>Has Permissions</li>
          </ul>
          <p>
            Active filters are noted by the Filter icon which displays the
            number of filters active on a Summary page.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/11.png")}
            />
          </figure>
          <p>
            Click Reset to clear all filters. Click Refresh{" "}
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/refresh.png")}
            />{" "}
            to refresh just the Summary table.
          </p>
        </div>
      </div>

      <div className="ug-content-wrapper" data-content="Using Tags">
        <div className="ug-content-main-header">Using Tags</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            To categorize objects and make them easier to find and group, you
            can add tags to users, data sources, datasets, jobs, workflows, and
            schedules. A tag is a short text label. An object can have one or
            more tags, depending on how many categories the object belongs to.
          </p>
          <p>
            Any user with permissions on an object can add or remove a tag. Tags
            can be added when creating an object or when editing an object. Tags
            are displayed in search results and summary pages. For example, you
            can use tags to filter the datasets when selecting a dataset for a
            new transform job.
          </p>
          <p>
            A tag can have a maximum of 32 characters and can not contain spaces
            or special characters.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Tags Recommendation"
      >
        <div className="ug-content-main-header">Tags Recommendation</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            When you are creating tags, you can click the Recommendation{" "}
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/bulb.png")}
            />{" "}
            icon and DCP will suggest tags for you based on our AI algorithms.
          </p>
          <p>
            For example, for our AI algorithms to suggest a tag for the dataset,
            click Summary{" "}
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/info.png")}
            />{" "}
          </p>
          <p>
            To add a tag to a dataset, click{" "}
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/edit.png")}
            />{" "}
            Edit Tags.
          </p>
          <p>
            To view tag recommendations, click{" "}
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/bulb.png")}
            />{" "}
            Recommendations. Choose the tags to add to the dataset and click
            Save.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/12.png")}
            />
          </figure>
          <p>
            In the Dataset Properties dialog, click in the Tags field, type the
            new tag, then press the spacebar. When you are finished adding tags,
            click <b>DONE</b>.
          </p>
          <p>
            The tags appear in the <b>DETAILS</b> column of all of the Summary
            pages. You can also filter on them.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Using the Dashboard"
      >
        <div className="ug-content-main-header">Using the Dashboard</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            The DCP Dashboard provides an overview of DCP objects and activity.
            The Dashboard can be viewed by users with the administrator or data
            analyst role. To display the Dashboard, click the Navigate to
            Dashboard link in the login screen or click the DCP icon in the
            upper left of the screen.
          </p>
          <p>
            In the Dashboard, you can choose to view only objects for which you
            have permissions or view the objects that belong to all users in
            your organization. However, you can actually access and use data
            from only those items for which you have been granted permissions.
          </p>
          <p>
            The plus icon (+) at the top of the Dashboard provides a convenient
            way to launch the most common tasks.
          </p>
          <p>
            To customize the Dashboard, drag and drop any of the panels into a
            new location.
          </p>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Dashboard Task"
      >
        <div className="ug-content-main-header">Dashboard Tasks</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>From the Dashboard, you can perform the following tasks:</p>
          <ul>
            <li>View and modify your own user profile</li>
            <li>Create a transform job</li>
            <li>Define a workflow</li>
            <li>Set up a schedule</li>
            <li>Add a data source and grant other users permissions on it</li>
            <li>
              Add a dataset and grant other users permissions on it (with some
              restrictions)
            </li>
            <li>View lists of users and user groups</li>
            <li>
              Click the links at the top of the Dashboard to navigate to other
              pages that list the existing dataset, transform jobs, and so on
            </li>
            <li>
              Search for jobs, datasets, and other objects by name. This filters
              the Dashboard display so that you see only information related to
              the search item
            </li>
            <li>View dataset distribution and tag distribution.</li>
            <li>View notifications, such as job status messages</li>
            <li>Read the User Guide</li>
          </ul>
          <p>
            From the Dashboard, users with Administrator accounts can perform
            the following additional tasks (for details, see the DCP
            Administrator’s Guide):
          </p>
          <ul>
            <li>Modify software settings</li>
            <li>Set up new user accounts and user groups</li>
            <li>View and set up datastores</li>
            <li>
              View and modify all objects defined in DCP (data sources,
              datasets, jobs, schedules, users, and so on)
            </li>
            <li>
              Control which users can access all data sources and datasets
            </li>
          </ul>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/13.png")}
            />
          </figure>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/catalog_dashboard.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Custom Dashboard"
      >
        <div className="ug-content-main-header">Custom Dashboard</div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/custom_dashboard_2.png")}
            />
          </figure>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/custom_dashboard_3.png")}
            />
          </figure>
        </div>
      </div>

      <div
        className="ug-content-wrapper"
        data-content="Using the BI Explorer and Tableau"
      >
        <div className="ug-content-main-header">
          Using the BI Explorer and Tableau
        </div>
        <div className="col-12 col-md-8 col-lg-9 ug-content">
          <p>
            The Tableau BI Explorer provides a Summary page of Tableau
            workbooks. Navigate to the Tableau workbooks from Discover &gt;
            Tableau.
          </p>
          <p>
            When you first view this page you will see the workbooks have a
            chain icon. There is also a left side panel with the name of your
            Tableau server.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/14.png")}
            />
          </figure>
          <p>
            The chain icon indicates that the current Boomi user is not mapped
            to the Tableau server user for the displayed workbooks. When you
            click Link and enter your Tableau credentials Boomi creates a
            mapping between your Boomi user and your Tableau user. You now have
            access to the workbooks.
          </p>
          <p>
            The BI Tableau Summary page displays the Tableau workbooks in one of
            three states:
          </p>
          <ul>
            <li>
              <b>Chain icon</b> -- no mapping has been created between the Boomi
              user and the Tableau server user.
            </li>
            <li>
              <b>Lock icon</b> -- mapping has been created and the user does not
              have access to the workbook.
            </li>
            <li>
              <b>No icon</b> -- mapping is complete and the user has access to
              the workbook.
            </li>
          </ul>
          <p>
            After linking, or mapping your Boomi user credentials to your
            Tableau user credentials you now see the workbooks to which you have
            access.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/15.png")}
            />
          </figure>
          <p>
            Clicking on the workbook opens it and displays a list of your views
            from the workbook. Opening a view renders the view on the Boomi
            server.
          </p>
          <figure>
            <img
              alt="Boomi Data Catalog & Prep"
              src={require("../assets/images/16.png")}
            />
          </figure>
        </div>
      </div>
    </section>
  );
}

export default AboutDCP;
