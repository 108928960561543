import React from "react";

function Security () {
    return(
        <section>
            <div className="ug-content-wrapper" data-content="Security">
                <div className="ug-content-main-header">Security</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>This section discusses topics related to permissions and authorization.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Users and Groups">
                <div className="ug-content-main-header">Users and Groups</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Each person who uses DCP needs an account so that they can log in. As a DCP administrator, you can add new user accounts. Each user must be explicitly enabled. When there are more users than licenses, a user must be disabled before another user can be enabled.</p>
                    <p>Users may be grouped so that they can be more efficiently administered. The administrator can define a user group that includes multiple users, then grant or revoke data access permissions to the group as a whole.</p>
                    <p>Some users who are not administrators can view a list of users and user groups, but they cannot modify any of the settings. The exception is that as a user, you can modify certain parts of your own profile.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Adding a User Account">
                <div className="ug-content-main-header">Adding a User Account</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Administrators can add users to DCP. Read Authentication Settings for more information on authentication methods.</p>
                    <h4>Adding local login users</h4>
                      <ol>
                        <li>Navigate to <strong>Manage</strong> &#62; <strong>Access</strong> &#62; <strong>Users</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Enter a username,. The username cannot be edited after the user is created.</li>
                        <li>Enter a temporary password and make a note of it. You will need to send the password to the new user.</li>
                        <li>Select the user’s role. Review the role definitions to assist you in choosing the appropriate permissions.</li>
                        <li>Click <strong>Create</strong>. Send the user the temporary password. The new user will receive a welcome email and can log in to create their own password.</li>
                        <li>Click the user’s name in the Users list.</li>
                        <li>Under Has Permissions, click <strong>Data Sources</strong> and select the check box next to data sources you want the user to access.</li>
                        <li>Click <strong>Data Sets</strong> and select the check box next to data sets you want the user to access. Users can only view and work with data sets they have access to.</li>
                      </ol>
                    <h4>Adding users with Boomi SSO authentication</h4>
                      <p>The DCP user’s email address must match the email address of the Boomi platform account. Boomi SSO is available for DCP Boomi platform and Boomi Data Catalog users.</p>
                      <ol>
                        <li>With Boomi SSO authentication activated, navigate to <strong>Manage</strong> &#62; <strong>Access</strong> &#62; <strong>Users</strong>.</li>
                        <li>Enter the user’s first name and last name.</li>
                        <li>Enter the user’s email address associated with their platform account.</li>
                        <li>Select a DCP role.</li>
                        <li>Click <strong>Create</strong>.</li>
                      </ol>
                    <h4>Adding users with LDAP authentication</h4>
                      <ol>
                        <li>With Lightweight Directory Access Protocol (LDAP) authentication activated, navigate to <strong>Manage</strong> &#62; <strong>Access</strong> &#62; <strong>Users</strong>.</li>
                        <li>Click the plus icon.</li>
                        <li>Select <strong>Import Users</strong>.</li>
                        <li>Do one of the following:
                            <ol>
                                <li>To import groups and users, Click <strong>Groups</strong> and select appropriate groups. 
                                    <ol>
                                        <li>Click <strong>Next</strong>.</li>
                                        <li>Select the appropriate groups from LDAP.</li>
                                        <li>Click <strong>Import</strong>. Assign the users to an appropriate role (Administrator, Data Engineer, Data Analyst, Data Catalog Manager or Data Steward).</li>
                                    </ol>
                                </li>
                                <li>To import only users, Click <strong>Users</strong>. 
                                    <ol>
                                        <li>Click the plus icon and select <strong>Import Users</strong>.</li>
                                        <li>Assign the users to an appropriate role (Administrator, Data Engineer, Data Analyst, Data Catalog Manager, or Data Steward).</li>
                                    </ol>
                                </li>
                            </ol>
                        </li>
                        <li>Users can  log in with their username and password. If a user is unable to log in, contact Boomi Support or for on-premises applications, 
                            review the datai_api.log file located in UNIFI_HOME/logs.</li>
                      </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Adding a User Group">
                <div className="ug-content-main-header">Adding a User Group</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>To add a user group</p>
                    <ol>
                        <li>Log in to the DCP user interface with a user account that has administrator permissions, such as the Boomi user.</li>
                        <li>In the left navigation bar, click Manage, then click Access.</li>
                        <li>Choose the GROUPS tab.</li>
                        <li>Click the plus icon.</li>
                        <li>Give the group a name.</li>
                        <li>Choose the users you want to belong to the group.</li>
                        <li>Click CREATE.</li>
                    </ol>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/create_group.png')} />
                    </figure>
                    <p>To import LDAP users or groups</p>
                    <p>When adding a new user, you can choose IMPORT USER. A list of user accounts is displayed from your organization’s LDAP server. You can also import a user group by clicking IMPORT USERS and clicking the GROUPS tab. You can assign a role or tags to all the users.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Ownership Transfers">
                <div className="ug-content-main-header">Ownership Transfers</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>DCP provides the ability to transfer ownership of all resources owned by a given user, to another user. To transfer ownership, navigate to the Access page, select the Users tab, find the user for whom you’d like to transfer ownership. After you’ve found the user, simply click on the menu icon and select the Transfer Ownership option.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/ownership_transfer.png')} />
                    </figure>
                    <p>Follow the options as indicated on screen.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/ownership_transfer_2.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Disabling Users">
                <div className="ug-content-main-header">Disabling Users</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>To disable DCP users that no longer require access. Simply navigate to the Access page, select the Users tab and find the user you’d like to disable.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/disabling_user.png')} />
                    </figure>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/disabling_user_2.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="User Roles">
                <div className="ug-content-main-header">User Roles</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Five user roles are available to choose from when creating or editing a user account.</p>
                    <ul>
                        <li>Administrator – Has the greatest level of permissions on data, user accounts, and the behavior of the DCP application.</li>
                        <li>Data Analyst – Acquires data from various sources and prepares it for data analysis, such as by creating transform jobs.</li>
                        <li>Data Steward – Responsible for data governance by administering data in compliance with policy or regulatory obligations. Users with this role see a customized view of the UI to fit the user’s responsibilities.</li>
                        <li>Data Engineer – Responsible for developing architectures for building data pipelines. Users with this role see a customized view of the UI to fit the user’s responsibilities.</li>
                        <li>Data Catalog Manager – Responsible for maintaining the inventory of all data assets in an organization. Users with this role see a customized view of the UI to fit the user’s responsibilities.</li>
                    </ul>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/user_role.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Conversations">
                <div className="ug-content-main-header">Conversations</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>DCP users can engage in conversations by exchanging text messages within DCP about datasets and jobs. Conversations are useful for collaboration and communication to increase the effectiveness of teams using DCP.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Data Access Permissions">
                <div className="ug-content-main-header">Data Access Permissions</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p><i>Access permission</i> means the ability to view and use data sources and datasets.</p>
                    <ul>
                        <li>If you have access permissions on a data source, this means that you can view the data source in the DCP user interface and use its data in your datasets.</li>
                        <li>If you have access permissions on a dataset, you can view the dataset and create transform jobs using the dataset. A user can have permissions on a dataset without necessarily having permissions on the underlying data source.</li>
                    </ul>
                    <p>In this document, when we talk about permissions, we are usually referring to a user's ability to view and use data, not the ability to modify the definition of a data source or dataset. </p>
                    <p><b>Who Can Grant Permissions</b></p>
                    <p>The Administrator user is the owner of all DCP objects and can grant or revoke access to other users and objects. Users who create their own data source or dataset can grant access permissions to other users.</p>
                    <p><b>Granting User Permissions</b></p>
                    <p>To set permissions to control what data sources, datasets, and columns a user (or user group) can access:</p>
                    <ol>
                        <li>In the left navigation, choose Manage > Access.</li>
                        <li>Choose DATASOURCES, DATASETS, or COLUMNS.</li>
                        <li>Choose the name of the data source, dataset, or column for which you want to grant the user access.</li>
                        <li>Use the checkboxes to select the users or groups that can access this object.</li>
                    </ol>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/permissions_1.png')} />
                    </figure>
                    <p><b>Granting Data Source Permissions</b></p>
                    <p>Only the data source’s owner or a user with administrator privileges can change data access permissions for a data source.</p>
                    <p>Access permissions for data sources can be granted when adding or modifying a data source or through the Manage Access links, accessible in the navigation bar. Choose Manage – Access in the left navigation, then choose DATASOURCES.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/permissions_2.png')} />
                    </figure>
                    <p><b>Granting Dataset Permissions</b></p>
                    <p>The following people can change data access permissions for a dataset:</p>
                    <ul>
                        <li>The dataset's owner</li>
                        <li>The owner of the data source from which the dataset was created</li>
                        <li>A user with administrator privileges</li>
                    </ul>
                    <p>To grant access permissions for datasets, choose Manage – Access in the left navigation, then choose DATASETS.</p>
                    <p><b>Granting Column Permissions</b></p>
                    <p>A dataset’s owner or an administrator can change data access permissions for a column in the dataset.</p>
                    <p>To make this feature available, the DCP administrator must check the Enable Column Level Security setting in the General tab of the Settings dialog. See Settings.</p>
                    <p>When a dataset is being viewed, each column heading provides a drop-down menu that includes a Access choice. The owner or administrator can control which users can view individual columns in the dataset.</p>
                    <p>You can also choose Manage – Access in the left navigation, then choose COLUMNS.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/permissions_3.png')} />
                    </figure>
                    <p>If a user is not given permission on one or more columns:</p>
                    <ul>
                        <li>Those columns do not appear in the dataset view and the sample data view.</li>
                        <li>Any job that uses the forbidden columns appears greyed out in the Jobs list. The user cannot click on the job to view its details.</li>
                        <li>When defining a transform job, if the user does not have permissions on the column that would be used for a join, then that table is not included in recommended joins.</li>
                        <li>Forbidden columns do not appear in search results.</li>
                    </ul>
                    <p>Note: For the sake of efficient performance, leave Enable Column Level Security off if column-level security is not needed. With the feature disabled, fewer security queries are performed.</p>
                    <p><b>Permissions for Other Objects</b></p>
                    <p>Permissions to view the data output from jobs, workflows, and schedules are determined dynamically by DCP based on the datasets involved. For example, to view job output, a user must have data access permission on all the datasets used in the job.</p>
                    <p><b>How Permissions Affect Users</b></p>
                    <p>In DCP, a user can use only those data sources and datasets for which the user has permissions.</p>
                    <p>A user has permission to use all the datasets created from that user's permitted data sources unless the owner of the dataset has explicitly revoked this permission. The dataset owner is whoever created the dataset. This could be an administrator or an ordinary user.</p>
                    <p>A DCP administrator or the owner of a data source can grant or deny users access to datasets created from that data source. This means that as a dataset owner, your choices about user access permissions can be overridden by the administrator or the data source owner.</p>
                    <p>More Information:<br/>
                        <a href={"https://docs.google.com/document/d/1kVwhO54APgdA-O8mQSWt17_TzPbYpRm-/edit#heading=h.2grqrue"} target={"_blank"} rel={"noopener noreferrer"} className={"link"}>Adding a Data Source</a><br/>
                        <a href={"https://docs.google.com/document/d/1kVwhO54APgdA-O8mQSWt17_TzPbYpRm-/edit#heading=h.3im3ia3"} target={"_blank"} rel={"noopener noreferrer"} className={"link"}>Users and Groups</a>
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Security;
