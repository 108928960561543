import React from 'react';
import LeftPageIndex from "./sections/left_page_index";
import AboutDCP from "./sections/about_dcp";
import AdminOverview from "./sections/admin_overview";

import DataType from "./sections/data_type";
import RulesManager from "./sections/rules_manager";

import DataSources from "./sections/data_sources";
import DataSets from "./sections/data_sets";
import Adapters from "./sections/adapters";
import DataStores from "./sections/data_stores";
import Glossary from "./sections/glossary";
import TransformJobs from "./sections/transform_jobs";
import Workflow from "./sections/workflow";
import Schedules from "./sections/schedules";
import ScheduleParams from "./sections/schedule_params";
import Activities from "./sections/activities";
import History from "./sections/history";
import Trash from "./sections/trash";
import Settings from "./sections/settings";
import EnablingSsl from "./sections/enabling_ssl";
import ConfiguringSpark from "./sections/configuring_spark";
import Tuning from "./sections/tuning";
import BackupRestore from "./sections/backup_restore";
import Security from "./sections/security";
import Appearance from "./sections/appearance";
import Notifications from "./sections/notifications";
import Help from "./sections/help";
import Copyright from "./sections/copyright";

class UserGuide extends React.Component {
	componentDidMount () {
		this.init();
	}

	init () {
	    let firstMenu = document.querySelectorAll("ul.open")[0];
	    firstMenu.style.maxHeight = firstMenu.scrollHeight + "px";

	    let menuItems = document.getElementsByClassName("menu-item"),
	        subMenuItems = document.getElementsByClassName("sub-menu-item"),
	        arrowIcons = document.querySelectorAll(".menu-item span .icon-arrow-right"),
	        menuSubMenuLen = Math.max(menuItems.length, subMenuItems.length),
	        debouncer;

	    for (let i=0; i<menuSubMenuLen; i++) {
	        if (i < menuItems.length) {
	            menuItems[i].addEventListener("click", function (e) {
	                if (!(this.classList.contains("is-open") || this.classList.contains("active"))) {
	                    if (this.querySelectorAll(".icon-arrow-right")[0]) {
	                        slideToggle(this.querySelectorAll(".icon-arrow-right")[0]);
	                    }
	                }
	                showContent(this);
	                e.stopPropagation();
	            });
	        }
	        if (i < subMenuItems.length) {
	            subMenuItems[i].addEventListener("click", function (e) {
	                showContent(this);
	                e.stopPropagation();
	            });
	        }
	    }

	    for (let i=0; i<arrowIcons.length; i++) {
	        arrowIcons[i].addEventListener("click", function (e) {
	            slideToggle(this);
	            e.stopPropagation();
	        });
	    }

	    window.addEventListener("resize", function (e) {
	        clearTimeout(debouncer);
	        debouncer = setTimeout(() => {
	                        let openElements = document.querySelectorAll(".open");
	                        for (let i=0; i<openElements.length; i++) {
	                            openElements[i].style.maxHeight = openElements[i].scrollHeight +  "px";
	                        }
	                    }, 300);

	    });

	    function showContent(ele) {
	        let contentFor = ele.getAttribute("data-for");

	        if (!ele.classList.contains("active")) {
	            for (let i=0; i<menuItems.length; i++) {
	                menuItems[i].classList.remove("active");
	            }

	            for (let i=0; i<subMenuItems.length; i++) {
	                subMenuItems[i].classList.remove("active");
	            }

	            if (ele.classList.contains("active")) {
	                ele.classList.remove("active");
	            }
	            else {
	                ele.classList.add("active");
	            }

							const top = document.querySelectorAll(`[data-content="${contentFor}"]`)[0].offsetTop;

							window.scrollTo({
	              top: top,
	              behavior: 'smooth',
	            });
					}

	        makeBreadcrumb(ele);

	    }

	    function triggerMenuClick () {
	        let breadcrumbItems = document.querySelectorAll("[data-for].link");

	        for (let i=0; i<breadcrumbItems.length; i++) {
	            breadcrumbItems[i].addEventListener("click", function (e) {
	                let contentFor = breadcrumbItems[i].getAttribute("data-for"),
	                    navItem = document.querySelectorAll("[data-for='" + contentFor + "']")[0];

	                showContent(navItem);
	                e.stopPropagation();
	            });


	        }

	    }

	    function makeBreadcrumb (ele) {
	        let breadcrumb = [],
	        breadcrumbHtml = "";

	        breadcrumb.push(ele.getAttribute("data-for"));

	        while(ele.parentNode) {
	            if (ele.parentNode && ele.parentNode.classList.contains("is-open")) {
	                breadcrumb.unshift(ele.parentNode.getAttribute("data-for"));
	            }
	            if(ele.parentNode.classList.contains("ug-left-container")) {
	                break;
	            }
	            ele = ele.parentNode;
	        }
	        breadcrumb.unshift("Boomi Data Catalog & Prep");
	        for (let i=0; i<breadcrumb.length; i++) {
	            if (i===0) {
	                breadcrumbHtml += `<a href="https://help.boomi.com/" class="link">${breadcrumb[i]}</a>`;
	            }
	            else {
	                breadcrumbHtml += `<span class="${(i < breadcrumb.length - 1) && 'link'}" data-for="${breadcrumb[i]}">${breadcrumb[i]}</span>`;
	            }
	        }
	        let breadcrumbContainer = document.getElementsByClassName("ug-breadcrumb")[0];
	        breadcrumbContainer.innerHTML = breadcrumbHtml;
	        triggerMenuClick();
	    }

	    function slideToggle (ele) {
	        let parent = ele.closest(".menu-item"),
	            immediateChild = parent.getElementsByTagName("ul")[0],
	            immediateChildHeight = immediateChild.scrollHeight + "px",
	            openElements = document.querySelectorAll(".open");

	            if (immediateChild.classList.contains("open")) {
	                immediateChild.classList.remove("open");
	                immediateChild.style.maxHeight = 0;
	            }
	            else {
	                immediateChild.classList.add("open");
	                immediateChild.style.maxHeight = immediateChildHeight;
	                for (let i=0; i<openElements.length; i++) {
	                    openElements[i].style.maxHeight = openElements[i].scrollHeight + immediateChild.scrollHeight + "px";
	                }
	            }


	            if (parent.classList.contains("is-open")) {
	                parent.classList.remove("is-open");
	            }
	            else {
	                parent.classList.add("is-open");
	            }
	    }

	}


render() {
	  return (
	  	<div className="ug-body clearfix">
	        <LeftPageIndex />

	        <div className="ug-right-container col-md-9 col-lg-10">
	            <div className="ug-breadcrumb">
	                <a href="https://help.boomi.com/" className="link">Boomi Data Catalog &amp; Prep</a>About the Boomi Data Catalog & Prep
	            </div>

	            {/* html for About DCP content starts here */}
				<AboutDCP />
	            {/* html for About DCP content ends here */}

				{/* html for Administration Overview content starts here */}
				<AdminOverview />
				{/* html for Administration Overview content ends here */}

	            {/* html for Conversation content starts here */}
	            <div className="ug-content-wrapper" data-content="Conversations">
	                <div className="ug-content-main-header">Conversations</div>
	                <div className="col-12 col-md-8 col-lg-9 ug-content">
	                    <p>DCP users can engage in conversations by exchanging text messages within DCP regarding datasets and jobs. Conversations are useful for collaboration and communication to increase the effectiveness of teams using DCP.</p>
	                    <p>To use conversations:</p>
	                    <ol>
	                        <li>When viewing a dataset or job, click the Chat icon <img alt="Boomi Data Catalog & Prep" src={require('./assets/images/chat.png')} /></li>
	                        <li>
	                            From the Chat pane type messages and reply to messages from others.
	                            <figure>
	                                <img alt="Boomi Data Catalog & Prep" src={require('./assets/images/17.png')} />
	                            </figure>
	                        </li>
	                        <li>
	                            Type comments at the bottom of the chat pane. In a comment, you can use the following special characters to address another user directly or insert a link to an object like another job or dataset:
	                            <ul>
	                                <li>Use the @ symbol followed by a user ID to directly address another user. DCP will display a list of suggestions to auto-complete the user ID as you type.</li>
	                                <li>Use the # symbol followed by the name of a DCP object, like a job or dataset, to insert a hyperlinked reference to that object. DCP will display a list of suggestions to auto-complete the object as you type. <b>Note:</b> This requires NLP to be enabled from Settings.</li>
	                                <li>PII information is noted by the PII icon in the PII columns.</li>
	                            </ul>
	                        </li>
	                    </ol>
	                </div>
	            </div>
	            {/* html for Conversation content ends here */}

				{/* html for Data Types content starts here */}
				<DataType />
				{/* html for Data Types content starts here */}

				{/* html for Rules Manager content starts here */}
				<RulesManager />
				{/* html for Rules Manager content ends here */}

				{/* html for Tag Manager content starts here */}
				<div className="ug-content-wrapper" data-content="Tag Manager">
					<div className="ug-content-main-header">Tag Manager</div>
					<div className="col-12 col-md-8 col-lg-9 ug-content">
					  <p>The Tag Manager lists all the tags available in your DCP instance.</p>
					  <p>The Tag Manager allows administrators to:
						<ul>
							<li>efficiently apply tags to multiple objects and remove tags from multiple objects.</li>
							<li>review all tags currently used in your instance.</li>
						</ul>
					  </p>
					  <p>Administrators can click the information icon to review who created the tag, the creation date, and the number of objects the tag is associated with. 
						It may take up to a minute for tag associations to appear after you add a tag to an object.</p>
					  <h5>Adding tags</h5>
					  <p>To add an existing tag to objects, select a tag from the Tags list then select the check box next to the object. To add a new tag:</p>
					  <ol>
						<li>Navigate to one of the following types of objects in DCP:
							<ul>
								<li>User profile</li>
								<li>Data source</li>
								<li>Data set</li>
								<li>Business glossary</li>
								<li>Job</li>
								<li>Workflow</li>
								<li>Schedule</li>
								<li>Data Type</li>
								<li>Rule</li>
							</ul>
						</li>
						<li>Click the <strong>Details</strong> icon  for the object.</li>
						<li>Click your cursor next to Tags.</li>
						<li>Pause your cursor next to Tags.</li>
						<li>Click the Add/Edit tags icon.</li>
						<li>Click your cursor in the Edit Tags window.</li>
						<li>Enter a label for your tag.</li>
						<li>Press Enter on your keyboard.</li>
						<li>Click the X icon to close the Details panel.</li>
					  </ol>
						<figure>
							<img alt="Tag Manager" src={require('./assets/images/tag_manager_info.png')} />
						</figure>
					</div>
				</div>
				{/* html for Tag Manager content ends here */}

				{/* html for Adapters content starts here */}
				<Adapters />
				{/* html for Adapters content ends here */}

	            {/* html for Data Stores content starts here */}
				<DataStores />
	            {/* html for Data Stores content ends here */}

	            {/* html for Data Sources content starts here */}
				<DataSources />
	            {/* html for Data Sources content ends here */}

	            {/* html for dataset content starts here */}
				<DataSets />
	            {/* html for dataset content ends here */}

				{/* html for Glossary content starts here */}
				<Glossary />
				{/* html for Glossary content ends here */}

	            {/* html for transform jobs content starts here */}
	            <TransformJobs />
	            {/* html for transform jobs content ends here */}

	            {/* html for workflow content starts here */}
	            <Workflow />
	            {/* html for workflow content ends here */}

	            {/* html for Schedules content ends here */}
	            <Schedules />
	            {/* html for Schedules content ends here */}

	            {/* html for Schedule Parameters content starts here */}
	            <ScheduleParams />
	            {/* html for Schedule Parameters content ends here */}

				{/* html for Activities content starts here */}
	            <Activities />
	            {/* html for Activities content ends here */}

				{/* html for History content starts here */}
				<History />
				{/* html for History content ends here */}

				{/* html for Trash content starts here */}
				<Trash />
				{/* html for Trash content ends here */}

				{/* html for Settings content starts here */}
				<Settings />
				{/* html for Settings content ends here */}

				{/* html for Enabling Ssl content starts here */}
				<EnablingSsl />
				{/* html for Enabling Ssl content ends here */}

				{/* html for Configuring Spark content starts here */}
				<ConfiguringSpark />
				{/* html for Configuring Spark content ends here */}

				{/* html for Tuning content starts here */}
				<Tuning />
				{/* html for Tuning content ends here */}

				{/* html for BackupRestore content starts here */}
				<BackupRestore />
				{/* html for BackupRestore content ends here */}

				{/* html for Security content starts here */}
				<Security />
				{/* html for Security content ends here */}

				{/* html for Appearance content starts here */}
				<Appearance />
				{/* html for Appearance content ends here */}

				{/* html for Notifications content starts here */}
				<Notifications />
				{/* html for Notifications content ends here */}

				{/* html for Help content starts here */}
				<Help />
				{/* html for Help content ends here */}

				{/* html for Copyright content starts here */}
				<Copyright />
				{/* html for Copyright content ends here */}
	        </div>
	    </div>
	  );
  }
}

export default UserGuide;
