import React from "react";

function DataSets () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Datasets">
                <div className="ug-content-main-header">Datasets</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>A dataset is a subset of data from a data source:</p>
                    <ul>
                        <li>A table in a database</li>
                        <li>A file in a Hadoop file system, Amazon S3 bucket, or</li>
                        <li>Data in an account hosted by a service such as Salesforce or Marketo DCP users work with datasets to explore the available data and set up transform jobs. Permissions can be granted to determine which DCP users can access each dataset.</li>
                    </ul>
                    <p>DCP users work with datasets frequently in order to explore the available data and set up transform jobs. Permissions can be granted to determine which DCP users can access each dataset.</p>
                    <p>When a dataset is added and parsed by DCP:</p>
                    <ul>
                        <li>DCP detects the structure of the data and creates a list of columns. DCP can detect the characters that separate the data values in a comma-separated value (CSV) file, the name-value pairs in a JSON file, the fields in a fixed-width file, and more. DCP can also work with semi-structured data, where there is no obvious column separator and the data can come from various non-tabular sources such as log files. If the data is in a database, DCP’s job is made easy, because the database has already defined the columns.</li>
                        <li>The data type of each data value is detected, such as String (STR), Integer (INT), or Date (DAT). DCP can detect some interesting data types, such as credit card number and IP address. DCP supports Character Large Object (CLOB) data. Users can override the automatically assigned data type later.</li>
                        <li>Missing column values and badly written rows are identified.</li>
                        <li>A few rows of data are read into DCP as a sample. The number of rows can be configured using DEFAULT_SAMPLE_SIZE as described in <a href={"https://docs.google.com/document/d/1kVwhO54APgdA-O8mQSWt17_TzPbYpRm-/edit#heading=h.356xmb2"} className="link" target={"_blank"} rel={"noopener noreferrer"}>Settings.</a></li>
                    </ul>
                    <p><b>Dataset Summary</b></p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_summary.png')} />
                    </figure>
                    <p><b>KG Nav</b></p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_kg_nav.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Data Import and Preparation">
                <div className="ug-content-main-header">Data Import and Preparation</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>When a dataset is added, the data is parsed by DCP:</p>
                    <ul>
                        <li>DCP detects the structure of the data and creates a list of columns. DCP can detect the characters that separate the data values in a comma-separated value (CSV) file, the name-value pairs in a JSON file, and more. DCP can also work with semi-structured data, where there is no obvious column separator and the data can come from various non-tabular sources such as log files. If the data is in a database, DCP’s job is made easy, because the database has already defined the columns.</li>
                        <li>The data type of each data value is detected, such as String (STR), Integer (INT), or Date (DAT). Some of the data is categorized under the classification Personally Identifiable Information (PII), including credit card numbers, social security numbers, email addresses, phone numbers and IP addresses. DCP also supports Character Large Object (CLOB) data. Users can override the automatically assigned data type later. DCP also detects Personally Identifiable Information (PII) and masks it based on default rules.</li>
                        <li>After the data is detected, the system imports a technical data dictionary  and merges it with a business data dictionary  and displays both on the Dataset Explorer Data Dictionary  page. The technical data dictionary  includes data types from the database, column comment, and database comments.</li>
                        <li>Missing column values and badly written rows are identified.</li>
                        <li>
                            System Hive, MS SQL server, and Adobe Cloud datasets can be marked as "Writable", allowing them to be used as output targets. Where one of these dataset types is the output target in Jobs and Schedules, only writeable datasets can be chosen.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/20.png')} />
                            </figure>
                        </li>
                        <li>A few rows of data are read into DCP as an initial sample. The number of rows can be configured by the DCP administrator as described in General Settings in the DCP Administrator’s Guide. Additional rows of sample data can be viewed as needed.</li>
                    </ul>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Data Endorsement">
                <div className="ug-content-main-header">Data Endorsement</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Part of data preparation is tagging your data so that others understand the status of the data.</p>
                    <p>DCP has two tags:</p>
                    <ul>
                        <li>Trusted - trusted as per the requirements of your organization.</li>
                        <li>Deprecated - no longer useful or trusted.</li>
                    </ul>
                    <p>Endorse your data from the Summary icon <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/info.png')} /></p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/21.png')} />
                    </figure>
                    <p>Click History to review the history of endorsements.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/22.png')} />
                    </figure>
                    <p><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/trusted.png')} /> Trusted and <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/deprecated.png')} /> Deprecated data icons display in the dataset title.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/23.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Displaying a Dataset">
                <div className="ug-content-main-header">Displaying a Dataset</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>In the left navigation bar, click Discover > Dataset Explorer.
                            <p>The Datasets screen lists the datasets for which the logged-in user is the owner or for which the user has permissions. This might be only a portion of the datasets that exist in each data source. If the data source is a database, all the tables in the database are listed as Datasets.</p>
                            <p>You and the other users at your organization can create more datasets at any time. If you do not see a dataset that contains the data you want, you can add another dataset. See Creating a Dataset for more information.</p>
                        </li>
                        <li>
                            Select your dataset. You can select your dataset directly, or use the FILTERS to limit the search.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/24.png')} />
                            </figure>
                            <p>For more information on using FILTERS, see Using Filters.</p>
                            <p>In the DATA SOURCE column, the icon to the left of the data source indicates what type of data source it is: database, remote file systems such as Hadoop or Amazon S3, or external service such as Salesforce.</p>
                            <ul>
                                <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/bi.png')} /> Business Intelligence</li>
                                <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/filesystem.png')} /> Filesystem</li>
                                <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/database.png')} /> Database</li>
                                <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/service.png')} /> Service</li>
                                <li><img alt="Boomi Data Catalog & Prep" src={require('../assets/images/atomsphere.png')} /> Atomsphere</li>
                            </ul>
                            <p><b>Troubleshooting: No datasets listed for a data source. </b></p>
                            <p>When a Hadoop, Amazon S3, or other remote filesystem data source is used for the first time, the Datasets list is empty. Click Create New Dataset and explore the files in the data source folder to choose the ones you want to use as a dataset.</p>
                            <p>You can also choose a dataset from <b>RECENTLY VIEWED</b>.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/25.png')} />
                            </figure>
                            <p>Recently viewed displays the last 10 viewed datasets across the dataset columns.</p>
                        </li>
                        <li>
                            In <b>DATASET</b>, click the name of the dataset you want to explore.
                            <p>DCP parses the dataset, detects the rows and columns, and displays sample data. Above the sample data, information about the dataset is displayed, including the file name, the number of columns, and the number of rows currently included in the sample data. Scroll left and right in the DCP UI to see all the columns.</p>
                            <p>If data masking has been defined, or if you do not have permission to view data in this dataset, the data is partially or fully obscured.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/26.png')} />
                            </figure>
                            <p><b>Note:</b> You can easily change to a different dataset by clicking the display name of the current dataset and selecting a new datasource and dataset.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/27.png')} />
                            </figure>
                        </li>
                        <li>To sort the rows of the dataset, hover over one of the column headings and click the drop-down arrow, then choose Ascending or Descending.</li>
                        <li>To find other objects that are related to this dataset, click:
                            <ol className="lower-alpha">
                                <li>Related Resources to find related Jobs, Functions, Filters, or Datasets.
                                    <figure>
                                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/28.png')} />
                                    </figure>
                                </li>
                                <li>Similar Datasets to datasets that match the current dataset. The delta between the selected dataset and the matching datasets is highlighted</li>
                            </ol>
                        </li>
                        <p>For example, if you click Similar Datasets, DCP displays suggested datasets based on its recommendation calculations. This can be an easy way to find your way to the dataset that you want to view next.</p>
                        <figure>
                            <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/29.png')} />
                        </figure>
                        <p>You can also compare datasets to review their similarities. Click the dataset to compare with the primary dataset. The two datasets display side-by-side.</p>
                        <p>The similarities are noted, as well as the possibility that the dataset is a possible duplicate.</p>
                        <figure>
                            <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/30.png')} />
                        </figure>
                        <figure>
                            <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_similar_2.png')} />
                        </figure>
                        <figure>
                            <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_similar_3.png')} />
                        </figure>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Viewing Columns in a Dataset">
                <div className="ug-content-main-header">Viewing Columns in a Dataset</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>After selecting a dataset to view, use these steps to navigate through the columns in the dataset.</p>
                    <ol>
                        <li>To show a quick graph of the sample data in any column, click the column header. A value histogram appears above the table. You can display the histogram for a string column, such as the state in a street address.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/31.png')} />
                            </figure>
                            <p>You can display the histogram for a date column.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/32.png')} />
                            </figure>
                            <p>You can display the histogram for an integer column.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/33.png')} />
                            </figure>
                        </li>
                        <li>Move the slider to Range Histogram to see another view of the data. (Not available for some column types.)
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/34.png')} />
                            </figure>
                        </li>
                        <li>To filter the sample data that is displayed, click on a bar in the histogram, or click and drag to select a range of values in the histogram (the available action depends on the data type of the column).
                            <p>For example, you can click on the bar for a single state to display only rows with the matching state.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/35.png')} />
                            </figure>
                            <p>You can click and drag across a range of values in the histogram for a numeric or date column to show only rows with matching values in that column.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/36.png')} />
                            </figure>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Viewing Dataset Sample and Full Statistics">
                <div className="ug-content-main-header">Viewing Dataset Sample and Full Statistics</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Overall data set sample statistics help you understand the general characteristics of the information in a data set and can assist with decisions about data usage.</p>
                    <p>You can view dataset statistics for all rows in a data set. The following statistics are available on the data set’s <strong>Sample Data</strong> tab:</p>
                    <ul>
                      <li>Average row width</li>
                      <li>Column count</li>
                      <li>Row count</li>
                      <li>Distinct column data types</li>
                      <li>Number of columns with PII (Personal Identifiable Information)</li>
                    </ul>
                    <p>By default, DCP analyzes the first 20 rows of sample data. You can configure the default sample size in the <strong>DEFAULT_SAMPLE_SIZE</strong> field in Settings. You can also increase the amount of sample rows for a specific data set in the <strong>Sample Statistics</strong> drop-down menu.</p>
                    <ol>
                      <li>Navigate to <strong>Explore</strong> &#62; <strong>Data Set Explorer</strong>.</li>
                      <li>Select the data set.</li>
                      <li>In the Sample Data tab, click the <strong>Sample Statistics</strong> drop-down menu.</li>
                      <li>Overall dataset statistics display based on the default sample size.</li>
                      <li><strong>Optional</strong>: Do one of the following:
                        <ul>
                          <li>Select the <strong>No. of Rows</strong> radio button and adjust the row count. For example, if the number of rows is 20, you’ll calculate statistics for 20 rows of data.</li>
                          <li>Select the <strong>All</strong> radio button to compute statistics for the entire dataset. Computing full statistics can take several minutes depending on the size of the dataset.</li>
                        </ul>
                      </li>
                      <li>Click <strong>Compute</strong>. A progress indicator displays while calculating statistics. After statistics download, click a column to view Value and Range graphs, if applicable. 
                        To review statistical activity information and troubleshoot errors, view the statistic summary and log in <strong>Prepare</strong> &#62; <strong>Activities</strong> &#62; <strong>Statistics</strong>.</li>
                    </ol>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" width= "50%" src={require('../assets/images/img-dcp-view_column_statistics.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Viewing Detailed Column Statistics">
                <div className="ug-content-main-header">Viewing Detailed Column Statistics</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>After selecting a dataset to view, use these steps to show data profiling displays for the columns in the dataset.</p>
                    <p><b>Note:</b> The profiling calculations can take time if the dataset is large. The DCP system administrator can limit the size of the dataset using the Profiling Maximum Dataset Size setting as described in the DCP Administrator’s Guide.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="To view detailed column statistics">
                <div className="ug-content-main-header">To view detailed column statistics</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>
                            Click the column you want to explore, then click Column View.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/38.png')} />
                            </figure>
                        </li>
                        <li>The right side of the column viewscreen shows a chart that ranks the values in the column by how often the values occur in the sample dataset.
                            <p>Depending on the data type of the column, DCP displays a different type of profiling diagram.</p>
                            <p>For example, for a string column, DCP displays statistics and a treemap diagram:</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/39.png')} />
                            </figure>
                            <p>For an integer column, DCP displays statistics and a frequency histogram that includes the mean and standard deviation:</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/40.png')} />
                            </figure>
                            <p>If the sample data is longer than the cell width, a hover tooltip appears to display the full text.</p>
                        </li>
                        <li>In a <b>DATE</b> column, you can use the <b>YEAR, WEEK, DAY, and FULL</b> buttons to redraw the profiling heatmap with different time groupings. This is helpful to answer questions like, "On what day of the month do most deals close?"
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/41.png')} />
                            </figure>
                        </li>
                        <li>To return to the previous view, click the Table View button.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/42.png')} />
                            </figure>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Setting Column Permission">
                <div className="ug-content-main-header">Setting Column Permission</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The owner of a dataset or a DCP administrator can control which users can view individual columns in the dataset. If a user is not given permission on one or more columns:</p>
                    <ul>
                        <li>Those columns do not appear in the dataset view and the sample data view.</li>
                        <li>Any job that uses masked columns appears greyed out in the Jobs list.</li>
                    </ul>
                    <p>The user can not click on the job to view its details.</p>
                    <ul>
                        <li>When defining a transform job, if the user does not have permissions on the column that would be used for a join, then that table is not included in recommended joins.</li>
                        <li>Masked columns do not appear in search results.</li>
                    </ul>
                    <p>To make this feature available, the DCP administrator must check the Enable Column Level Security setting in the General tab of the Settings dialog. For details about settings, see the DCP Administrator’s Guide.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="How to Set Column Permissions">
                <div className="ug-content-main-header">How to Set Column Permissions</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Display the dataset as described in Displaying a Dataset.</li>
                        <li>Click the down arrow next to the column heading. A drop-down menu appears.</li>
                        <li>Hover the cursor over Permissions. A list of usernames is displayed. These are the users who can be given permission to view the dataset.</li>
                        <li>Select and deselect usernames from the list to determine who can and who can not view the column.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/43.png')} />
                            </figure>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Masking Data">
                <div className="ug-content-main-header">Masking Data</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>DCP masks some sensitive data in the Data Explorer by default, such as credit card numbers, based on data type detection during data import. In addition, the DCP administrator can mask (hide) some of the characters in individual columns in the dataset. When the dataset is displayed in the Data Explorer, the characters will appear as asterisks (*) to users who are logged in without administrator permissions. This is useful to protect sensitive data such as credit card numbers.</p>
                    <p><b>Note:</b> The data will be masked only in the Data Explorer.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="How to Mask data">
                <div className="ug-content-main-header">How to Mask data</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Display the dataset as described in Displaying a Dataset.</li>
                        <li>Click the down arrow next to the column heading. A drop-down menu appears.</li>
                        <li>Hover the cursor over Data Security, then click the slider next to Mask Data.</li>
                        <li>Choose one of the following options:
                            <ul>
                                <li><b>Mask All</b> – change all characters in the column into asterisks.</li>
                                <li><b>Partial Mask</b> – change a given number of characters into asterisks.</li>
                                <li><b>Partial Show</b> – change all characters into asterisks, except for a given number.</li>
                                <figure>
                                    <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/how_to_mask_data_1.png')} />
                                </figure>
                                <figure>
                                    <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/how_to_mask_data_2.png')} />
                                </figure>
                            </ul>
                        </li>
                        <li>Click <b>SAVE</b>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Sensitive Data Types">
                <div className="ug-content-main-header">Sensitive Data Types</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>When DCP connects to a dataset it runs data discovery to automatically determine the type of data contained in the dataset. Some of this data is sensitive and is masked to protect it. These are the sensitive data types that are automatically discovered as personally identifiable information (PII):</p>
                    <ul>
                        <li>Credit Card Number</li>
                        <li>International Mobile Equipment Identity Number</li>
                        <li>IP address (IPV4 and IPV6)</li>
                        <li>Email</li>
                        <li>Genetic Sequence</li>
                        <li>Social Security Number (USA)</li>
                        <li>Phone Numbers (USA)</li>
                        <li>Address (USA)</li>
                    </ul>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Creating Master Data Hub Models">
                <div className="ug-content-main-header">Creating Master Data Hub Models</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>You can create and publish Master Data Hub models using datasets within your catalog. The Related Resources tab lets you create, publish, and view models. 
                       See Viewing a Master Data Hub Model to learn more.</p>
                    <div>
                        <p>Prerequisites:</p>
                        <ul>
                            <li>Installed AtomSphere adapter in DCP</li>
                            <li>Configured AtomSphere data store and data source in DCP</li>
                            <li>Boomi platform account</li>
                            <li>Master Data Hub</li>
                        </ul>
                    </div>
                    <p><strong>Note:</strong> You cannot create two models from the same dataset using the same platform account. You can edit or delete a model in Master Data Hub.</p>
                    <ol>
                        <li>Navigate to <strong>Explore</strong> &gt; <strong>Data Set Explorer</strong>.</li>
                        <li>Click on a dataset.</li>
                        <li>Click the <strong>Details</strong> icon (i icon) to open the <strong>Details</strong> panel.</li>
                        <li>Click the vertical ellipsis icon.</li>
                        <li>Click <strong>Create MDH Data Model</strong>.</li>
                        <li>Enter a model name. The model name must contain at least one letter, cannot lead with numerals, cannot exceed 40 characters, 
                            and cannot contain spaces or special characters.</li>
                        <li>Select an AtomSphere data source. DCP uses the AtomSphere data source to create a Hub model.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select at least one column name for the data model.</li>
                        <li>Click <strong>Next</strong>.</li>
                        <li>Select at least one column to use for the matching rule to identify unique records. 
                            Two or more matching rules always use the AND operator. To use the OR operator, edit the match rule in Master Data Hub.</li>
                        <li>Do one of the following:
                            <ul>
                                <li>Click <strong>Create</strong> to create a draft.</li>
                                <li>Click <strong>Create & Publish</strong> to create and publish your model. You can deploy models in Master Data Hub after publishing. 
                                Read <a href={"https://help.boomi.com/bundle/hub/page/t-mdm-Deploying_a_model_to_a_repository.html"} className={"link"}>Deploying a model to a repository </a> 
                                to learn more about deploying models.</li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Viewing a Master Data Hub Model">
                <div className="ug-content-main-header">Viewing a Master Data Hub Model</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <div>
                      <p>In Related Resources, you can:</p>
                      <ul>
                        <li>Create Master Data Hub models</li>
                        <li>View drafts and published models associated with the dataset</li>
                        <li>Publish a draft</li>
                      </ul>
                    </div>
                    <p>You can edit or delete a model in Master Data Hub. You cannot create two models from the same dataset using the same platform account.</p>
                    <ol>
                        <li>Navigate to <strong>Explore</strong> &gt; <strong>Data Set Explorer</strong>.</li>
                        <li>Click on a dataset.</li>
                        <li>Click <strong>Related Resources</strong>.</li> 
                        <li>Do one of the following: 
                            <ul>
                                <li>Click the plus icon to create a model. See Creating Master Data Hub Models to learn more.</li>
                                <li>Click a draft to publish a model.</li>
                                <li>Click the model to see the columns used for the model and the columns used for matching rules.</li>
                            </ul>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Changing the Column Data Type">
                <div className="ug-content-main-header">Changing the Column Data Type</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>When DCP imports and parses a new data source, it automatically determines the data type of each data column. When viewing a database or CSV dataset, you can change the data type. This is helpful in the rare case where DCP has assigned a different data type than the one intended.</p>
                    <p>To change the data type of a column</p>
                    <ol>
                        <li>Display the dataset as described in Displaying a Dataset.</li>
                        <li>Click the data type at the top of a column, then choose a new data type from the drop-down list.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/44.png')} />
                            </figure>
                        </li>
                        <li>Click Save.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Hierarchical View">
                <div className="ug-content-main-header">Hierarchical View</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_hierarchical_view.png')} />
                    </figure>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_hierarchical_view_1.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Creating a Dataset">
                <div className="ug-content-main-header">Creating a Dataset</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>DCP can provide access to one or more datasets from each data source. A dataset can be created by the user who owns the data source or any other user who has permission to access the data source. 
                        In the Description, you can enter an HTML anchor tag to create a hyperlink. For example, you can add a link to a web page that directs users to data set information in another application.</p>
                    <ol>
                        <li>In the Dashboard, click the plus icon, then choose "Create Dataset" <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/plus.png')} /></li>
                        <li>Choose the type of data source: local system, database, file system, or service.</li>
                        <li>Click Data Source to scroll through the drop-down list of data sources. Or you can start typing the name to search for a particular data source. Choose the data source you want to use.</li>
                        <li>If you chose a file system data source, choose a directory and file to provide the data for the new dataset.
                            <p>Imported datasets are visually highlighted as such in the data dictionary display. Imported Description and Imported Data Type columns are shown. These data dictionary  values cannot be changed.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/45.png')} />
                            </figure>
                            <p><b>Local System</b></p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_local.png')} />
                            </figure>
                            <p><b>Atmosphere</b></p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_atmosphere.png')} />
                            </figure>
                            <p>If you chose a database or service data source, click the name of a schema or table to provide the data for the new dataset.</p>
                            <p>For data sources such as PostgreSQL that support schemas, you can import one schema at a time. You can also import just one table at a time from database data sources.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/46.png')} />
                            </figure>
                            <p>For Services Data Sources, select the tables and click Import.</p>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/47.png')} />
                            </figure>
                        </li>
                        <li>If the data source is a file system, you can set the dataset name, description, and user permissions. For a dataset created from a database or service data source, you will set these a bit later in the procedure.</li>
                        <li>(Optional) Define tags if desired. For more information see Using Tags.</li>
                        <li>Click IMPORT (or, for a file system dataset, CREATE).
                            <ol className="lower-alpha">
                                <li><b>File System:</b>
                                    <ul>
                                        <li>When creating a dataset from a CSV file, you can direct DCP to ignore a specified number of rows at the start of the file. This is useful for files where the first few rows do not contain data records, but instead contain headers or comments.</li>
                                        <li>Before you click <b>SAVE</b> on your new dataset, click the three dots to display the menu, then choose Parameters.</li>
                                    </ul>
                                    <figure>
                                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/48.png')} />
                                    </figure>
                                    <p>Check to see that headersPresent is checked and skipLines matches the number of rows to skip.</p>
                                    <figure>
                                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/49.png')} />
                                    </figure>
                                </li>
                                <li><b>Database:</b> (For a database or service dataset)</li>
                            </ol>
                            <p>After the import is finished, click the name of the new dataset in the list. In the context menu, choose Edit Properties. Give the dataset a name. In Permissions, choose the names of the other users you want to have access to this dataset. The Permissions drop-down list displays the users that have been granted access to the selected data source.</p>
                            <p><b>Troubleshooting:</b> No users in the Permissions drop-down list. If the Permissions list does not drop down, then no other users have been granted access to this data source. You can not grant access to any other users at this time. Ask a DCP administrator to add those users to the data source, then come back and edit the dataset to grant additional user access permissions.</p>
                        </li>
                        <li>Click <b>SAVE</b>.</li>
                    </ol>
                    <p><b>Canonical Model</b></p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_canonical.png')} />
                    </figure>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_canonical_1.png')} />
                    </figure>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_canonical_2.png')} />
                    </figure>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_canonical_3.png')} />
                    </figure>
                    <p>Canonical Dataset Data dictionary is different from usual </p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_canonical_1.png')} />
                    </figure>
                    <p><b>Virtual View</b></p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_vv.png')} />
                    </figure>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_vv_1.png')} />
                    </figure>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_vv_2.png')} />
                    </figure>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_create_vv_3.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Data Dictionary">
                <div className="ug-content-main-header">Data Dictionary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The data dictionary contains a collection of system and user-defined attributes for a dataset. Data dictionaries contain details that help your organization understand the purpose and meaning of the columns and column values in a dataset.</p>
                    <p>You can define multiple attributes for each column, such as field length and description. 
                       In addition to column attributes, you can define column values for an attribute. Each attribute contains additional context, description, or other 
                       information about the column. For example, a delivery status field might have predefined values like Shipped, In Transit, and Delivered. You can 
                       provide details about the meaning of each status.</p>
                  <h4>Importing and Exporting</h4>
                    <p>You can export the data dictionary as a CSV file to make multiple edits at once. After editing the CSV file, you must import the data dictionary to overwrite and make changes. 
                       Exporting is also useful when you want to reuse a data dictionary for a similar dataset. See Exporting the Data Dictionary and Importing the Data Dictionary for more information.</p>
                  <h4>Permissions</h4>
                  <p>Administrators can control who can view, create, and edit the data dictionary.</p>
                  <table>
                    <tr>
                      <td><strong>Action</strong></td>
                      <td><strong>Required Permissions</strong></td>
                    </tr>
                    <tr>
                      <td>View and export the data dictionary</td>
                      <td>
                        <ul>
                            <li>Data Set - Read</li>
                            <li>Data Content - Read</li>
                            <li>Data Set Metadata - Read</li>
                            <li>Access to the dataset</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Import the data dictionary</td>
                      <td>
                      <ul>
                            <li>Data Set - Read</li>
                            <li>Data Content - Read</li>
                            <li>Data Set Metadata - Create</li>
                            <li>Access to the dataset</li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Adding Data Dictionary for a Column">
                <div className="ug-content-main-header">Adding a Data Dictionary for a Column</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>View the dataset that you want to work with. See Displaying a Dataset.</li>
                        <li>Click <strong>Data Dictionary</strong>.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/50.png')} />
                            </figure>
                            <p>The data dictionary  view is displayed.</p>
                        </li>
                        <li>In the data dictionary  view, click <strong>Add Attribute</strong> <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/add_attr.png')} />
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/51.png')} />
                            </figure>
                        </li>
                        <li>Type a name for the data dictionary  attribute, and choose whether to allow other users to edit the data dictionary  and add star ratings to comment on the usefulness of the data dictionary.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/52.png')} />
                            </figure>
                        </li>
                        <li>Press <strong>Enter</strong> on your keyboard. DCP creates the new attribute with a blank editing area.</li>
                        <li>Click in the empty area below the attribute name and next to a column name. Type the value for the attribute. To narrow down the list of columns, click the <strong>Search</strong> icon above the Column Names. When you are finished typing, click anywhere outside the editing area, and DCP saves the description.</li>
                        <li>If desired, repeat steps 3 – 6 to add more data dictionary attributes.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/53.png')} />
                            </figure>
                        </li>
                    </ol>
                    <p><b>Note:</b> The values you add can also be deleted, but it is a hard delete, it cannot be undone.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Adding Predefined Column Values with Data Dictionary">
                <div className="ug-content-main-header">Adding Predefined Column Values to the Data Dictionary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>You can add column values from sample statistics or full statistics to the data dictionary. 
                        The Boomi Data Catalog service does not allow you to add values from full statistics. <br />
                        You can also add values for a specific column by clicking the column's arrow and selecting <strong>Insert all values from statistics</strong>.</p>
                    <p>You can insert up to 500 distinct values. You cannot add ranges of values. When the number of buckets in the histogram is too large, 
                        the column page for the data set displays a "No data to display" message and no graph, indicating that you cannot add these values to the data dictionary.</p>
                    <ol>
                        <li>Navigate to <strong>Explore</strong> &gt; <strong>Data Set Explorer</strong>.</li>
                        <li>Select the data set.</li>
                        <li>In <strong>Sample Data</strong>, wait for the sample statistics to load.</li>
                        <li>Click the <strong>Sample Statistics</strong> drop-down.</li>
                        <li>Under <strong>Sampling Size</strong>, select the <strong>All</strong> radio button.</li>
                        <li>Click <strong>Compute</strong>.</li>
                        <li>Wait for data to load. The <strong>Full Statistics</strong> drop-down displays when full statistics are loaded.</li>
                        <li>Click <strong>Data Dictionary</strong>.</li>
                        <li>Click the vertical ellipsis icon.</li>
                        <li>Click <strong>Add Column Values.</strong></li>
                        <li>Select the check boxes next to the column values you want to add.</li>
                        <li>Click <strong>Add.</strong></li>
                        <li>The number of values added displays next to the column name. Pause your cursor on the value count and click the arrow to view the values.</li>
                        <li>Click the <strong>Add Attribute</strong> icon to add an attribute column, such as a description column, to each value.</li>
                        <li>Enter a name for the attribute, such as Description. By default, the ability to edit the description and provide a rating is enabled. 
                            You can deselect the check boxes to remove these permissions.</li>
                        <li>Press Enter on your keyboard. DCP creates the new attribute with a blank editing area.</li>
                        <li>Click in the empty area below the attribute name and next to a column value. Enter the value for the attribute. When finished, click anywhere outside the editing area and DCP saves the value.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/58.png')} />
                            </figure>
                        </li>
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Deleting Predefined Column Values with Data Dictionary">
                <div className="ug-content-main-header">Deleting Predefined Column Values and Attributes from the Data Dictionary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>After you remove any column value attributes, you can delete column values. A deleted attribute cannot be restored.</p>
                    
                    <ol>
                        <li>Navigate to <strong>Explore</strong> &gt; <strong>Data Set Explorer</strong>.</li>
                        <li>Click <strong>Data Dictionary</strong>.</li>
                        <li>Pause your cursor on the attribute column label.</li>
                        <li>Click the vertical ellipsis.</li>
                        <li>Select <strong>Delete</strong>.</li>
                        <li>Click <strong>Yes</strong></li>
                        <li>Click the vertical ellipsis at the top of the screen.</li>
                        <li>Select <strong>Delete All Values</strong>.</li>
                        <li>Select the column.</li>
                        <li>Click <strong>Remove</strong>.</li>  
                    </ol>
                </div>
            </div>
            <div className="ug-content-wrapper" data-content="Viewing the Data Dictionary">
                <div className="ug-content-main-header">Viewing the Data Dictionary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <ol>
                    <li>Navigate to <strong>Explore</strong> &#62; <strong>Data Set Explorer</strong>.</li>
                    <li>Click <strong>Data Dictionary</strong>.</li>
                   </ol>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/59.png')} />
                    </figure>
                    <p>For each column in the dataset, the associated data dictionary attributes are shown. You can give a one- to five-star rating for the usefulness of the data dictionary (if Rating was enabled when the data dictionary was added, change the data dictionary -if editing is enabled - or add a new data dictionary attribute if you have permission).</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Searching the Data Dictionary">
                <div className="ug-content-main-header">Searching the Data Dictionary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Add your search term in the Search dialog, or alternatively, click <strong>Recommendations</strong>.</li>
                        <li>Type the word or phrase you are searching for. If you type only part of a word, DCP will find the entire word that matches it. To search for an exact phrase, enclose it in double quotes.</li>
                        <li>Click <strong>Data Dictionary</strong>.
                            <p>When you search for a data dictionary, the results can include datasets, columns, column attributes, values, and value attributes.</p>
                            <p>You can choose whether to display the data dictionary search results in a list or in a graphical tree.</p></li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Exporting the Data Dictionary">
                <div className="ug-content-main-header">Exporting the Data Dictionary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>You can export the data dictionary as a CSV file. You can edit the exported file and re-import it to make edits to the data dictionary. See Importing the Data Dictionary.</p>
                  <p>Exporting a data dictionary is useful when:</p>
                  <ul>
                    <li>You want to make a large amount of edits to a data dictionary.</li>
                    <li>You want to reuse a data dictionary for a similar dataset.</li>
                  </ul>
                  <ol>
                    <li>Navigate to <strong>Explore</strong> &#62; <strong>Data  Set Explorer</strong>.</li>
                    <li>Select a dataset.</li>
                    <li>Click the i icon to open the <strong>Details</strong> panel.</li>
                    <li>Click<strong> More options</strong> (vertical ellipsis icon).</li>
                    <li>Select <strong>Export Data Dictionary</strong>.
                      <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_export_dd.png')} />
                      </figure>
                    </li>
                  </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Importing the Data Dictionary">
                <div className="ug-content-main-header">Importing the Data Dictionary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>You can import a data dictionary associated with a data set in your catalog using a CSV file. You must format the CSV file to match the template provided in DCP.</p>
                  <p>Importing a data dictionary is useful when:</p>
                  <ul>
                    <li>You want to add a data dictionary from another source into your catalog.</li>
                    <li>You have exported the data dictionary to make edits and want to re-import the change to your data dictionary.</li>
                  </ul>
                  <p>Review the CSV template to ensure that your file matches the template. To download the template:</p>
                  <ol>
                    <li>Navigate to <strong>Explore</strong> &#62; <strong>Data  Set Explorer</strong>.</li>
                    <li>In the top right, click <strong>Import Data Dictionary</strong>.</li>
                    <li>Click the <strong>Click here</strong> link to download the template.</li>
                    <li>Click<strong> More options</strong> (vertical ellipsis icon).</li>
                    <li>Open the sample CSV file to review the format. Your CSV file must have at least 5 columns. Do not alter the title of the columns.</li>
                  </ol>
                  <h4>CSV Template Format</h4>
                  <table>
                    <tr>
                      <td>CSV File Column</td>
                      <td>Description</td>
                      <td>Notes</td>
                    </tr>
                    <tr>
                      <td>source</td>
                      <td>The name of the data source associated with the dataset.</td>
                      <td>Required</td>
                    </tr>
                    <tr>
                      <td>dataset</td>
                      <td>The dataset name.</td>
                      <td>Required</td>
                    </tr>
                    <tr>
                      <td>column</td>
                      <td>The dataset column title.</td>
                      <td>Required</td>
                    </tr>
                    <tr>
                      <td>value</td>
                      <td>The column value.</td>
                      <td>Optional</td>
                    </tr>
                    <tr>
                      <td>attr_name_1</td>
                      <td>The dictionary column title.</td>
                      <td>Required</td>
                    </tr>
                    <tr>
                      <td>attr_data_1</td>
                      <td>The data/information for the first dictionary column.</td>
                      <td>Required</td>
                    </tr>
                  </table>
                  <ol start="6">
                    <li>Navigate to <strong>Explore</strong> &#62; <strong>Data Set Explorer</strong>.</li>
                    <li>Click <strong>Upload</strong>.</li>
                    <li>Select the CSV file.</li>
                    <li>Click <strong>Import</strong>.</li>
                    <li>Navigate to <strong>Prepare</strong> &#62; <strong>Activities</strong> &#62; <strong>Statistics</strong> to review the status of the import. After import, definitions appear in the dataset’s Data Dictionary.</li>
                  </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Deleting a Dataset">
                <div className="ug-content-main-header">Deleting a Dataset</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>A dataset can be deleted by either the owner of the dataset or a user with Administrator privileges. To delete a dataset, display it in the Dataset Explorer page or in search results, then click the context menu and choose Delete.</p>
                    <p>This deletes all the associated objects also, such as transform jobs. If you do not have permissions on the dependent objects, DCP lists the restricted objects so you can request the owners to give you access to them.</p>
                    <p>Deleted datasets are listed in the Trash window. You can restore the dataset from here if desired. When you restore a dataset you are notified of any missing dependent objects.</p>
                    <p>All Deleted items can be retrieved from the Trash. Navigate to Manage > Trash to review all deleted objects, But once an item is "Deleted Permanently" it cannot be retrieved.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/deleting_dataset_1.png')} />
                    </figure>
                    <p>Deleted Dataset and their associated objects can be recovered from the Trash.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/deleting_dataset_2.png')} />
                    </figure>
                    <p>Deleted Dataset  and their associated objects cannot be recovered.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Viewing Datasets">
                <div className="ug-content-main-header">Viewing Datasets</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>In the left navigation bar, click Explore > Dataset Explorer.</p>
                    <p>The Datasets screen displays the datasets that users have configured in the DCP and only those datasets to which the logged-in user owns or has permission to view.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Viewing Dataset Information">
                <div className="ug-content-main-header">Viewing Dataset Information</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Click the Summary icon to view detailed statistics about the datasource.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/info_active_icon.png')} />
                    </figure>
                    <p>The Dataset Summary screen contains the following information:</p>
                    <ul>
                        <li>Datasource name</li>
                        <li>Who created the data source</li>
                        <li>When the data source was created</li>
                        <li>When the data source was last updated</li>
                        <li>Whether or not the dataset is to be used as an output target used for data preparation (writeable)</li>
                        <li>Description (editable)</li>
                        <li>Tags (editable)</li>
                        <li>Sample or Full Statistics</li>
                        <li>Related Resources</li>
                        <li>User permissions</li>
                        <li>Groups</li>
                    </ul>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/dataset_sample_data.png')} />
                    </figure>
                    <p>From this screen you can:</p>
                    <ul>
                        <li>Edit the description</li>
                        <li>Edit the Tags</li>
                        <li>Use Conversations</li>
                        <li>Change user access</li>
                        <li>Change the groups that can access the Dataset</li>
                    </ul>
                    <p><b>Changing Dataset User Permissions</b></p>
                    <ol>
                        <li>Open your dataset from the Dataset Explorer.</li>
                        <li>Click Users.</li>
                        <li>Select the users who will access the Dataset.</li>
                    </ol>
                    <p><b>Changing Dataset Group Permissions</b></p>
                    <ol>
                        <li>Open your dataset from the Dataset Explorer.</li>
                        <li>Click Groups.</li>
                        <li>Select the groups to which the Dataset will belong.</li>
                    </ol>
                    <p>For more information see:</p>
                    <ul>
                        <li>
                            <a href={"https://docs.google.com/document/d/1Nk8VvYIiaPvR9xiWPxOlMJCxFlAFQfwrBPwhbxBnipI/edit#bookmark=id.2r0uhxc"} className="link" target={"_blank"} rel={"noopener noreferrer"}>Data Sources</a>
                        </li>
                        <li>
                            <a href={"https://docs.google.com/document/d/1Nk8VvYIiaPvR9xiWPxOlMJCxFlAFQfwrBPwhbxBnipI/edit#bookmark=id.2hio093"} className="link" target={"_blank"} rel={"noopener noreferrer"}>Data Access Permissions</a>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default DataSets;
