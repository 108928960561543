import React from "react";

function ScheduleParams () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Using Schedule Parameters">
                <div className="ug-content-main-header">Using Schedule Parameters</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>This section explains how variables can be used in schedules to process dataset sources incrementally based on desired conditions.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Supported Variables">
                <div className="ug-content-main-header">Supported Variables</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>$DATE(format) [+|- delta]$</p>
                    <p>$DATETIME(format) [+|- delta]$</p>
                    <p>$WORKFLOW_NAME$</p>
                    <p>$JOB_NAME$</p>
                    <p>$SCHEDULE_NAME$</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Date and Time Strings">
                <div className="ug-content-main-header">Date and Time Strings</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The format strings for $DATE$ and $DATETIME$ can include any of the following:</p>
                    <table>
                        <thead>
                        <tr>
                            <th>Directive</th>
                            <th>Meaning</th>
                            <th>Example</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>%a</td>
                            <td>Weekday as locale’s abbreviated name.</td>
                            <td>Sunday, Monday, ..., Saturday (en_US); Sonntag, Montag, ..., Samstag (de_DE)</td>
                        </tr>
                        <tr>
                            <td>%A</td>
                            <td>Weekday as locale’s full name.</td>
                            <td>Sunday, Monday, ..., Saturday (en_US); Sonntag, Montag, ..., Samstag (de_DE)</td>
                        </tr>
                        <tr>
                            <td>%w</td>
                            <td>Weekday as a decimal number, where 0 is Sunday and 6 is Saturday</td>
                            <td>0, 1, ..., 6</td>
                        </tr>
                        <tr>
                            <td>%d</td>
                            <td>Day of the month as a zero-padded decimal</td>
                            <td>01, 02, ..., 31</td>
                        </tr>
                        <tr>
                            <td>%b</td>
                            <td>Month as locale’s abbreviated name.</td>
                            <td>Jan, Feb, ..., Dec (en_US); Jan, Feb, ..., Dez (de_DE)</td>
                        </tr>
                        <tr>
                            <td>%B</td>
                            <td>Month as locale’s full name.</td>
                            <td>January, February, ..., December (en_US); Januar, Februar, ..., Deczember (de_DE)</td>
                        </tr>
                        <tr>
                            <td>%m</td>
                            <td>Month as a zero-padded decimal number.</td>
                            <td>01, 02, ..., 12</td>
                        </tr>
                        <tr>
                            <td>$y</td>
                            <td>Year without century as a zero-padded decimal number.</td>
                            <td>00, 01, ..., 99</td>
                        </tr>
                        <tr>
                            <td>%Y</td>
                            <td>Year with century as a decimal number.</td>
                            <td>1970, 1988, 2001, 2013</td>
                        </tr>
                        <tr>
                            <td>%H</td>
                            <td>Hour (24-hour clock) as a zero-padded decimal number.</td>
                            <td>00, 01, ..., 23</td>
                        </tr>
                        <tr>
                            <td>%I</td>
                            <td>Hour (12-hour clock) as a zero-padded decimal number.</td>
                            <td>01, 02, ..., 12</td>
                        </tr>
                        <tr>
                            <td>%p</td>
                            <td>Locale’s equivalent of either AM or PM.</td>
                            <td>AM, PM (en_US); am, pm (de_DE)</td>
                        </tr>
                        <tr>
                            <td>%M</td>
                            <td>Minute as a zero-padded decimal number.</td>
                            <td>00, 01, ..., 59</td>
                        </tr>
                        <tr>
                            <td>%S</td>
                            <td>Second as a zero-padded decimal number.</td>
                            <td>00, 01, ..., 59</td>
                        </tr>
                        <tr>
                            <td>%f</td>
                            <td>Microsecond as a decimal number, zero-padded on the left.</td>
                            <td>000000, 000001, ..., 999999</td>
                        </tr>
                        <tr>
                            <td>%z</td>
                            <td>UTC offset in the form +HHMM or -HHMM (empty string if the object is naive).</td>
                            <td>(empty), +0000, -0400, +1030</td>
                        </tr>
                        <tr>
                            <td>%Z</td>
                            <td>Time zone name (empty string if the object is naive).</td>
                            <td>(empty), UTC, EST, CST</td>
                        </tr>
                        <tr>
                            <td>%j</td>
                            <td>Day of the year as a zero-padded decimal number.</td>
                            <td>001, 002, ..., 366</td>
                        </tr>
                        <tr>
                            <td>%U</td>
                            <td>Week number of the year (Sunday as the first day of the week) as a zero-padded decimal number. All days in a new year preceding the first Sunday are considered to be in week 0.</td>
                            <td>00, 01, ..., 53</td>
                        </tr>
                        <tr>
                            <td>%W</td>
                            <td>Week number of the year (Monday as the first day of the week) as a decimal number. All days in a new year preceding the first Monday are considered to be in week 0.</td>
                            <td>00, 01, ..., 53</td>
                        </tr>
                        <tr>
                            <td>%c</td>
                            <td>Locale’s appropriate date and time representation.</td>
                            <td>Tue Aug 16 21:30:00 1988 (en_US); Di 16 Aug 21:30:00 1988 (de_DE)</td>
                        </tr>
                        <tr>
                            <td>%x</td>
                            <td>Locale’s appropriate date representation.</td>
                            <td>08/16/88 (None); 08/16/1988 (en_US); 16.08.1988 (de_DE)</td>
                        </tr>
                        <tr>
                            <td>%X</td>
                            <td>Locale’s appropriate time representation.</td>
                            <td>21:30:00 (en_US); 21:30:00 (de_DE)</td>
                        </tr>
                        <tr>
                            <td>%%</td>
                            <td>A literal '%' character.</td>
                            <td>%</td>
                        </tr>
                        </tbody>
                    </table>
                    <p>For the $DATE$ variable, the use of format directives that are time-related will result in values as if the time is 00:00:00.</p>
                    <p>The use of format directives that include a / can be interpreted as directories. For example, $DATE(%d/%m/%Y)$ might be formatted as 1/1/2014, which depending on where the substituted string is used could be interpreted as three directories.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Delta and Strings">
                <div className="ug-content-main-header">Delta and Strings</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>For $DATE$ and $DATETIME$ variables, the delta string is in the format: ...].</p>
                    <p>&lt;op&gt; &lt;number&gt; &lt;length&gt; [&lt;number&gt; &lt;length&gt;</p>
                    <p>Valid &lt;op&gt; are:</p>
                    <ul>
                        <li>+</li>
                        <li>-</li>
                    </ul>
                    <p>For $DATE$ valid lengths are:</p>
                    <ul>
                        <li>day(s)</li>
                        <li>month(s)</li>
                        <li>years(s)</li>
                    </ul>
                    <p>For $DATETIME$ valid lengths are:</p>
                    <ul>
                        <li>day(s)</li>
                        <li>month(s)</li>
                        <li>year(s)</li>
                        <li>hour(s)</li>
                        <li>minute(s)</li>
                        <li>second(s)</li>
                    </ul>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Example of delta usage">
                <div className="ug-content-main-header">Example of delta usage</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>my_dir = '/data/$DATETIME(%Y-%m-%d) - 1 month 15 days 2 years$/'</p>
                    <p><b>Note:</b> Ordering is not important in the delta.</p>
                </div>
            </div>
        </section>
    )
}

export default ScheduleParams;
