import React from 'react';
import SwaggerUI from 'swagger-ui';
import * as $ from 'jquery';
import spec from './assets/dcp_swagger';

class Swagger extends React.Component {
    componentDidMount () {
        this.init();
    }

    init () {
        let host = $("#host").val() || "localhost:8000";

        $("#swagger-ui").html("");
        SwaggerUI({
            dom_id: '#swagger-ui',
            spec: {...spec, host},
            filter: "",
            onComplete: () => {
                $("header .subtitle").text(spec.info.version);
                $(".details .description").text(spec.info.description);

                //show everything
                $("body").show();
            }
        });

        $(".dynamic-css")
            .html(`
                .btn.authorize, .authorization__btn, .btn.try-out__btn {
                    display: none !important;
                }
            `)
    }


    render() {
        return (
            <div class="body-wrapper">
                <div class="details" >
                    <p class="description"></p>
                </div>
                <div id="swagger-ui">

                </div>
                <style class="dynamic-css">

                </style>
            </div>
        );
    }
}

export default Swagger;
