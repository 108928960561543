import React from "react";

function BackupRestore () {
    return(
        <section>
            <div className="ug-content-wrapper" data-content="Backup and Restore">
                <div className="ug-content-main-header">Backup and Restore</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Backup and Restore settings are only available in the DCP On-Premesis edition.</p>
                    <p>Use the following steps to backup or restore your DCP instance.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Backup">
                <div className="ug-content-main-header">Backup</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Stop the Boomi Data Catalog & Prep<br/>
                            <span className={"font-weight-300"}>$ usr/local/&lt;UNIFING&gt;/scripts/sbin/unifi_stop</span>
                        </li>
                        <li>Take a backup of your DCP Metastore database<br/>
                            <span className={"font-weight-300"}>$ pg_dump unifi > /usr/local/&lt;UNIFING&gt;/unifidb.sql</span>
                        </li>
                        <li>Backup the DCP install directory<br/>
                            <span className={"font-weight-300"}>$ tar cvf unifi.tar  /usr/local/&lt;UNIFING&gt;</span>
                        </li>
                        <li>Compress backed-up file<br/>
                            <span className={"font-weight-300"}>$ gzip unifi.tar</span>
                        </li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Restore">
                <div className="ug-content-main-header">Restore</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>Restore install directory backup to the same location<br/>
                            <span className={"font-weight-300"}>$ tar xzf unifi.tar.gz</span>
                        </li>
                        <li>Create metastore database<br/>
                            <span className={"font-weight-300"}>$ createdb  unifi</span>
                        </li>
                        <li>Restore backup of your DCP Metastore database.<br/>
                            <span className={"font-weight-300"}>$ psql -f  unifi.sql</span>
                        </li>
                        <li>Start the Boomi Data Catalog & Prep<br/>
                            <span className={"font-weight-300"}>$ /usr/local/&lt;UNIFING&gt;/scripts/sbin/unifi_start</span>
                        </li>
                    </ol>
                </div>
            </div>
        </section>
    )
}
export default BackupRestore;
