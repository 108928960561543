import React from "react";

function Notifications () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Notifications">
                <div className="ug-content-main-header">Notifications</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>DCP provides notifications of important events, such as when jobs finish running or when another user requests access to a dataset you own. To see notifications, click the notification icon, the bell in the left navigation bar.</p>
                    <p>A panel opens on the screen where you can view a list of notification summaries. Click a notification in the list to see the available details. For example, when a job finishes running, you can click the notification to see the runtime log from the job (the same as the log output that you can view from the Schedule History panel in the Dashboard).</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Filter by Type">
                <div className="ug-content-main-header">Filter by Type</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Types of Notifications are as follows</p>
                    <ol>
                        <li>All</li>
                        <li>Bulk Job Imports</li>
                        <li>Comments</li>
                        <li>Graph Bulk Reload</li>
                        <li>Import Activities</li>
                        <li>Job Activities</li>
                        <li>Job Output Exports</li>
                        <li>Permanent Deletes</li>
                        <li>Requests</li>
                    </ol>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/notifications_1.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Filter by Date">
                <div className="ug-content-main-header">Filter by Date</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/notifications_2.png')} />
                    </figure>
                </div>
            </div>
        </section>
    )
}

export default Notifications;
