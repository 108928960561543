import React from "react";

function LeftPageIndex() {
  return (
    <div className="ug-left-container col-md-3 col-lg-2">
      <div className="ug-nav-header">Contents</div>
      <ul>
        <li
          className="menu-item active is-open"
          data-for="About the Boomi Data Catalog & Prep"
        >
          <span>
            About the Boomi Data Catalog & Prep
            <i className="icon-arrow-right active"></i>
          </span>
          <ul className="open">
            <li className="sub-menu-item" data-for="Software Overview">
              <span>Software Overview</span>
            </li>
            <li className="sub-menu-item" data-for="Data Purging">
              <span>Data Purging</span>
            </li>
            <li className="sub-menu-item" data-for="Diverse Data Source">
              <span>Diverse Data Source</span>
            </li>
            <li className="sub-menu-item" data-for="Data Analysis Overview">
              <span>Data Analysis Overview</span>
            </li>
            <li className="menu-item" data-for="Logging In">
              <span>
                Logging In<i className="icon icon-tiny icon-arrow-right"></i>
              </span>
              <ul>
                <li className="sub-menu-item" data-for="Password Assistance">
                  <span>Password Assistance</span>
                </li>
              </ul>
            </li>
            <li className="sub-menu-item" data-for="Using the search Interface">
              <span>Using the search Interface</span>
            </li>
            <li className="sub-menu-item" data-for="NLP">
              <span>NLP</span>
            </li>
            <li className="sub-menu-item" data-for="Using Filter">
              <span>Using Filter</span>
            </li>
            <li className="menu-item" data-for="Using Tags">
              <span>
                Using Tags<i className="icon icon-tiny icon-arrow-right"></i>
              </span>
              <ul>
                <li className="sub-menu-item" data-for="Tags Recommendation">
                  <span>Tags Recommendation</span>
                </li>
              </ul>
            </li>
            <li className="menu-item" data-for="Using the Dashboard">
              <span>
                Using the Dashboard
                <i className="icon icon-tiny icon-arrow-right"></i>
              </span>
              <ul>
                <li className="sub-menu-item" data-for="Dashboard Task">
                  <span>Dashboard Task</span>
                </li>
                <li className="sub-menu-item" data-for="Custom Dashboard">
                  <span>Custom Dashboard</span>
                </li>
              </ul>
            </li>
            <li
              className="sub-menu-item"
              data-for="Using the BI Explorer and Tableau"
            >
              <span>Using the BI Explorer and Tableau</span>
            </li>
          </ul>
        </li>

                <li className="menu-item" data-for="Administration Overview">
	                    <span>Administration Overview<i className="icon-arrow-right"></i></span>
                    <ul>
                        <li className="sub-menu-item" data-for="License Administration">
                            <span>License Administration</span>
                        </li>
                        <li className="menu-item" data-for="Audit Logs">
                            <span>Audit Logs<i className="icon-arrow-right"></i></span>
                            <ul>
                              <li className="sub-menu-item" data-for="Viewing Audit Logs">
                                <span>Viewing Audit Logs</span>
                              </li> 
                            </ul>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Conversations">
                    <span>Conversations</span>
                </li>

                <li className="menu-item" data-for="Data Type">
                    <span>Data Type<i className="icon-arrow-right"></i></span>
                    <ul>
                        <li className="sub-menu-item" data-for="Create Data Type">
                            <span>Create Data Type</span>
                        </li>
                        <li className="sub-menu-item" data-for="Edit and Delete Data Types">
                            <span>Edit and Delete Data Types</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Rules Manager">
                    <span>Rules Manager<i className="icon-arrow-right"></i></span>
                    <ul>
                        <li className="sub-menu-item" data-for="PII Classification Overview">
								<span>PII Classification Overview</span> 
                        </li>
                        <li className="sub-menu-item" data-for="PII Classification Rules for Data Types">
								<span>PII Classification Rules for Data Types</span> 
                        </li>
                        <li className="sub-menu-item" data-for="PII Classification Rules for Columns">
								<span>PII Classification Rules for Columns</span> 
                        </li>
                        <li className="sub-menu-item" data-for="Classifying Specific Columns as PII">
								<span>Classifying Specific Columns as PII</span> 
                        </li>
                        <li className="sub-menu-item" data-for="Data Masking Overview">
								<span>Data Masking Overview</span> 
                        </li>
                        <li className="sub-menu-item" data-for="Masking Data Types with Rules">
								<span>Masking Data Types with Rules</span> 
                        </li>
                        <li className="sub-menu-item" data-for="Masking Columns with Rules">
								<span>Masking Columns with Rules</span> 
                        </li>
                        <li className="sub-menu-item" data-for="Masking Specific Columns">
								<span>Masking Specific Columns</span> 
                        </li>
                        <li className="sub-menu-item" data-for="Edit and Delete Rules">
                            <span>Edit and Delete Rules</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Tag Manager">
                    <span>Tag Manager</span>
                </li>

                <li className="menu-item" data-for="Adapters">
	                    <span>
	                        Adapters
	                        <i className="icon icon-tiny icon-arrow-right"></i>
	                    </span>
                    <ul>
                        <li className="menu-item" data-for="Before You Begin: Accessing the Adapters">
                            <span>Before You Begin: Accessing the Adapters</span>
                        </li>
                        <li className="menu-item" data-for="Adding an Adapter">
                          <span>Adding an Adapter</span>
                        </li>
                        <li className="menu-item" data-for="AtomSphere">
                          <span>AtomSphere</span>
                        </li>
                        <li className="menu-item" data-for="Azure SQL">
                          <span>Azure SQL</span>
                        </li>
                        <li className="menu-item" data-for="Google BigQuery">
                          <span>Google BigQuery</span>
                        </li>
                        <li className="menu-item" data-for="Master Data Hub">
                          <span>Master Data Hub</span>
                        </li>
                        <li className="menu-item" data-for="MongoDB">
                          <span>MongoDB</span>
                        </li>
                        <li className="menu-item" data-for="MySQL">
                          <span>MySQL</span>
                        </li>
                        <li className="menu-item" data-for="Oracle">
                          <span>Oracle</span>
                        </li>
                        <li className="menu-item" data-for="PostgreSQL">
                          <span>PostgreSQL</span>
                        </li>
                        <li className="menu-item" data-for="Redshift">
                          <span>Redshift</span>
                        </li>
                        <li className="menu-item" data-for="Salesforce">
                          <span>Salesforce</span>
                        </li>
                        <li className="menu-item" data-for="Snowflake">
                          <span>Snowflake</span>
                        </li>
                        <li className="menu-item" data-for="Tableau">
                          <span>Tableau</span>
                        </li>
                        <li className="menu-item" data-for="Upgrading an Adapter">
                          <span>Upgrading an Adapter</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Data Stores">
	                    <span>
	                        Data Stores
	                        <i className="icon icon-tiny icon-arrow-right"></i>
	                    </span>
                    <ul>
                        <li className="sub-menu-item" data-for="Supported Data Stores">
                            <span>Supported Data Stores</span>
                        </li>
                        <li className="sub-menu-item" data-for="Viewing Data Stores">
                            <span>Viewing Data Stores</span>
                        </li>
                        <li className="sub-menu-item" data-for="Adding a Data Store">
                            <span>Adding a Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the AtomSphere Data Store">
                            <span>AtomSphere Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Azure SQL Data Store">
                            <span>Azure SQL Database Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the BigQuery Data Store">
                            <span>BigQuery Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Master Data Hub Data Store">
                            <span>Master Data Hub Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the MongoDB Data Store">
                            <span>MongoDB Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the MySQL Data Store">
                            <span>MySQL Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Oracle Data Store">
                            <span>Oracle Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the PostgreSQL Data Store">
                            <span>PostgreSQL Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Redshift Data Store">
                            <span>Redshift Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Salesforce Data Store">
                            <span>Salesforce Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Snowflake Data Store">
                            <span>Snowflake Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Tableau Data Store">
                            <span>Tableau Data Store</span>
                        </li>
                        <li className="sub-menu-item" data-for="Data Store Configuration Parameters">
                            <span>Data Store Configuration Parameters</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Data Sources">
	                    <span>
	                        Data Sources
	                        <i className="icon icon-tiny icon-arrow-right"></i>
	                    </span>
                    <ul>
                        <li className="sub-menu-item" data-for="Viewing Data Sources">
                            <span>Viewing Data Sources</span>
                        </li>
                        <li className="menu-item" data-for="Adding a Data Source">
                            <span>Adding a Data Source<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="File Crawler Data Source">
                                    <span>File Crawler Data Source</span>
                                </li>
                                <li className="sub-menu-item" data-for="Creating a File Crawler Data Source">
                                    <span>Creating a File Crawler Data Source</span>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the AtomSphere Data Source">
                            <span>AtomSphere Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Azure SQL Data Source">
                            <span>Azure SQL Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the BigQuery Data Source">
                            <span>BigQuery Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Master Data Hub Data Source">
                            <span>Master Data Hub Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the MongoDB Data Source">
                            <span>MongoDB Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the MySQL Data Source">
                            <span>MySQL Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Oracle Data Source">
                            <span>Oracle Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the PostgreSQL Data Source">
                            <span>PostgreSQL Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Redshift Data Source">
                            <span>Redshift Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Salesforce Data Source">
                            <span>Salesforce Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Snowflake Data Source">
                            <span>Snowflake Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Connect the Tableau Data Source">
                            <span>Tableau Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Deleting a Data Source">
                            <span>Deleting a Data Source</span>
                        </li>
                        <li className="sub-menu-item" data-for="Additional Data Source Configuration Parameters">
                            <span>Additional Data Source Configuration Parameters</span>
                        </li>
                        <li className="menu-item" data-for="BI Explorer and the Tableau Data Source">
                            <span>BI Explorer and the Tableau Data Source<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Tableau as a Trusted Server">
                                    <span>Tableau as a Trusted Server</span>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu-item" data-for="Data Type Discovery">
                            <span>Data Type Discovery</span>
                        </li>
                        <li className="sub-menu-item" data-for="Re-Importing a Data Source">
                            <span>Re-Importing a Data Source</span>
                        </li>
                        <li className="menu-item" data-for="Adding a User-Defined Data Type">
                            <span>Adding a User-Defined Data Type<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Notes on using UDT">
                                    <span>Notes on using UDT</span>
                                </li>
                                <li className="sub-menu-item" data-for="DCP System Defined Datatypes">
                                    <span>DCP System Defined Datatypes</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Datasets">
	                    <span>Datasets<i className="icon icon-tiny icon-arrow-right"></i>
	                    </span>
                    <ul>
                        <li className="menu-item" data-for="Data Import and Preparation">
                            <span>Data Import and Preparation<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Data Endorsement">
                                    <span>Data Endorsement</span>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu-item" data-for="Displaying a Dataset">
                            <span>Displaying a Dataset</span>
                        </li>
                        <li className="sub-menu-item" data-for="Viewing Columns in a Dataset">
                            <span>Viewing Columns in a Dataset</span>
                        </li>
                        <li className="sub-menu-item" data-for="Viewing Dataset Sample and Full Statistics">
                            <span>Viewing Dataset Sample and Full Statistics</span>
                        </li>
                        <li className="menu-item" data-for="Viewing Detailed Column Statistics">
                            <span>Viewing Detailed Column Statistics<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="To view detailed column statistics">
                                    <span>To view detailed column statistics</span>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item" data-for="Setting Column Permission">
                            <span>Setting Column Permission<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="How to Set Column Permissions">
                                    <span>How to Set Column Permissions</span>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item" data-for="Masking Data">
                            <span>Masking Data<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="How to Mask data">
                                    <span>How to Mask data</span>
                                </li>
                                <li className="sub-menu-item" data-for="Sensitive Data Types">
                                    <span>Sensitive Data Types</span>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item" data-for="Creating Master Data Hub Models">
                            <span>Creating Master Data Hub Models<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Viewing a Master Data Hub Model">
                                    <span>Viewing a Master Data Hub Model</span>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu-item" data-for="Changing the Column Data Type">
                            <span>Changing the Column Data Type</span>
                        </li>
                        <li className="sub-menu-item" data-for="Hierarchical View">
                            <span>Hierarchical View</span>
                        </li>
                        <li className="sub-menu-item" data-for="Creating a Dataset">
                            <span>Creating a Dataset</span>
                        </li>
                        <li className="menu-item" data-for="Data Dictionary">
                            <span>Data Dictionary<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Adding Data Dictionary for a Column">
                                    <span>Adding a Data Dictionary for a Column</span>
                                </li>
                                <li className="sub-menu-item" data-for="Adding Predefined Column Values with Data Dictionary">
                                    <span>Adding Predefined Column Values to the Data Dictionary</span>
                                </li>
                                <li className="sub-menu-item" data-for="Deleting Predefined Column Values with Data Dictionary">
                                    <span>Deleting Predefined Column Values from the Data Dictionary</span>
                                </li>
                                
                                <li className="sub-menu-item" data-for="Viewing the Data Dictionary">
                                   <span>Viewing the Data Dictionary</span>
                                </li>
                                <li className="sub-menu-item" data-for="Searching the Data Dictionary">
                                   <span>Searching the Data Dictionary</span>
                                </li>
                                 <li className="sub-menu-item" data-for="Exporting the Data Dictionary">
                                  <span>Exporting the Data Dictionary</span>
                                </li>
                                <li className="sub-menu-item" data-for="Importing the Data Dictionary">
                                  <span>Importing the Data Dictionary</span>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu-item" data-for="Deleting a Dataset">
                            <span>Deleting a Dataset</span>
                        </li>
                        <li className="menu-item" data-for="Viewing Datasets">
                            <span>Viewing Datasets<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Viewing Dataset Information">
                                    <span>Viewing Dataset Information</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Business Glossary">
                    <span>Business Glossary<i className="icon icon-tiny icon-arrow-right"></i></span>
                    <ul>
                        <li className="sub-menu-item" data-for="Creating a Business Glossary">
                            <span>Creating a Business Glossary</span>
                        </li>
                        <li className="sub-menu-item" data-for="Deleting a Business Glossary">
                            <span>Deleting a Business Glossary</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Transform Jobs">
	                    <span>Transform Jobs<i className="icon icon-tiny icon-arrow-right"></i>
	                    </span>
                    <ul>
                        <li className="sub-menu-item" data-for="Prep Jobs">
                            <span>Prep Jobs</span>
                        </li>
                        <li className="menu-item" data-for="Viewing Prep Jobs">
                            <span>Viewing Prep Jobs<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Prep Job Summary Cart">
                                    <span>Prep Job Summary Cart</span>
                                </li>
                                <li className="sub-menu-item" data-for="Viewing individual Prep Job Details">
                                    <span>Viewing individual Prep Job Details</span>
                                </li>
                                {/*<li className="sub-menu-item" data-for="Job summary info panel">*/}
                                {/*    <span>Job summary info panel</span>*/}
                                {/*</li>*/}
                            </ul>
                        </li>
                        <li className="menu-item" data-for="Overview of Creating a Prep Job">
                            <span>Overview of Creating a Prep Job<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Creating a Prep Job">
                                    <span>Creating a Prep Job</span>
                                </li>
                                <li className="sub-menu-item" data-for="Data source and Dataset">
                                    <span>Data source and Dataset</span>
                                </li>
                                <li className="menu-item" data-for="Join Datasets">
                                    <span>Join Datasets<i className="icon icon-tiny icon-arrow-right"></i></span>
                                    <ul>
                                        <li className="sub-menu-item" data-for="How to Define a Join">
                                            <span>How to Define a Join</span>
                                        </li>
                                    </ul>
                                </li>
                                <li className="menu-item" data-for="Enrich Data With Derived Attributes">
                                    <span>Enrich Data With Derived Attributes<i className="icon icon-tiny icon-arrow-right"></i></span>
                                    <ul>
                                        <li className="sub-menu-item" data-for="Example">
                                            <span>Example</span>
                                        </li>
                                        <li className="sub-menu-item" data-for="How to Add a Derived Attribute in a Prep Job">
                                            <span>How to Add a Derived Attribute in a Prep Job</span>
                                        </li>
                                    </ul>
                                </li>
                                <li className="sub-menu-item" data-for="Choose Columns">
                                    <span>Choose Columns</span>
                                </li>
                                <li className="menu-item" data-for="Add Filter">
                                    <span>Add Filter<i className="icon icon-tiny icon-arrow-right"></i></span>
                                    <ul>
                                        <li className="sub-menu-item" data-for="Add Filter using sample data drawer">
                                            <span>Add Filter using sample data drawer</span>
                                        </li>
                                    </ul>
                                </li>
                                <li className="sub-menu-item" data-for="Aggregate">
                                    <span>Aggregate</span>
                                </li>
                                <li className="menu-item" data-for="Output">
                                    <span>Output<i className="icon icon-tiny icon-arrow-right"></i></span>
                                    <ul>
                                        <li className="sub-menu-item" data-for="Multiple Outputs">
                                            <span>Multiple Outputs</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item" data-for="Copying or Editing a job">
                            <span>Copying or Editing a job<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="How to Copy a Job">
                                    <span>How to Copy a Job</span>
                                </li>
                                <li className="sub-menu-item" data-for="How to Edit a Job">
                                    <span>How to Edit a Job</span>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item" data-for="Running a Job and Specifying Output Options">
                            <span>Running a Job and Specifying Output Options<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Viewing Job Output and Logs">
                                    <span>Viewing Job Output and Logs</span>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item" data-for="Export Job from Summary panel">
                            <span>Export Job from Summary panel</span>
                            {/*<ul>*/}
                            {/*    <li className="sub-menu-item" data-for="Single Job Export option">*/}
                            {/*        <span>Single Job Export option</span>*/}
                            {/*    </li>*/}
                            {/*    <li className="sub-menu-item" data-for="Multiple Job Export option">*/}
                            {/*        <span>Multiple Job Export option</span>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                        </li>
                        <li className="menu-item" data-for="OS Jobs">
                            <span>OS Jobs</span>
                        </li>
                        <li className="menu-item" data-for="Local Import">
                            <span>Local Import</span>
                        </li>
                        <li className="menu-item" data-for="SQL Jobs">
                            <span>SQL Jobs<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Strategy-Create">
                                    <span>Strategy-Create</span>
                                </li>
                                <li className="sub-menu-item" data-for="Strategy-Insert">
                                    <span>Strategy-Insert</span>
                                </li>
                                <li className="sub-menu-item" data-for="Strategy-Overwrite">
                                    <span>Strategy-Overwrite</span>
                                </li>
                                <li className="sub-menu-item" data-for="Strategy-Raw Query">
                                    <span>Strategy-Raw Query</span>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item" data-for="Deleting a Job">
                            <span>Deleting a Job</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Workflow">
	              <span>Workflow<i className="icon icon-tiny icon-arrow-right"></i></span>
                  <ul>
                      <li className="sub-menu-item" data-for="Viewing Workflows">
                            <span>Viewing Workflows</span>
                      </li>
                      <li className="sub-menu-item" data-for="Creating a Workflow">
                            <span>Creating a Workflow</span>
                      </li>
                      <li className="sub-menu-item" data-for="Creating Workflow Groups">
                            <span>Creating a Workflow Group</span>
                      </li>
                      <li className="sub-menu-item" data-for="Deleting a Workflow">
                            <span>Deleting a Workflow</span>
                      </li>
                  </ul>
                </li>

                <li className="menu-item" data-for="Schedules">
	                    <span>Schedules<i className="icon icon-tiny icon-arrow-right"></i>
	                    </span>
                    <ul>
                        <li className="sub-menu-item" data-for="Incremental Data Capture(IDC)">
                            <span>Incremental Data Capture(IDC)</span>
                        </li>
                        <li className="sub-menu-item" data-for="Viewing Schedule">
                            <span>Viewing Schedule</span>
                        </li>
                        <li className="sub-menu-item" data-for="Scheduling Jobs">
                            <span>Scheduling Jobs</span>
                        </li>
                        <li className="sub-menu-item" data-for="Import Schedules">
                            <span>Import Schedules</span>
                        </li>
                        <li className="sub-menu-item" data-for="Schedule Output Target Configuration">
                            <span>Schedule Output Target Configuration</span>
                        </li>
                        <li className="sub-menu-item" data-for="Deleting a Schedule">
                            <span>Deleting a Schedule</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for="Using Schedule Parameters">
	                    <span>Using Schedule Parameters<i className="icon icon-tiny icon-arrow-right"></i>
	                    </span>
                    <ul>
                        <li className="sub-menu-item" data-for="Supported Variables">
                            <span>Supported Variables</span>
                        </li>
                        <li className="sub-menu-item" data-for="Date and Time Strings">
                            <span>Date and Time Strings</span>
                        </li>
                        <li className="menu-item" data-for="Delta and Strings">
                            <span>Delta and Strings<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Example of delta usage">
                                    <span>Example of delta usage</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for={"Activities"}>
	                    <span>Activities<i className="icon icon-tiny icon-arrow-right"></i></span>
                    <ul>
                        <li className="menu-item" data-for="Job Activity">
                            <span>Job Activity<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Job Activity Summary">
                                    <span>Summary</span>
                                </li>
                                <li className="sub-menu-item" data-for="Job Activity Output">
                                    <span>Output</span>
                                </li>
                                <li className="sub-menu-item" data-for="Job Activity Input">
                                    <span>Input</span>
                                </li>
                                <li className="sub-menu-item" data-for="Job Activity Logs">
                                    <span>Logs</span>
                                </li>
                                <li className="sub-menu-item" data-for="Job Activity Lineage">
                                    <span>Lineage</span>
                                </li>
                                <li className="sub-menu-item" data-for="Job Activity Export Job Output">
                                    <span>Export Job Output</span>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu-item" data-for="Import Activity">
                            <span>Import Activity</span>
                        </li>
                        <li className="menu-item" data-for="Statistics Activity">
                            <span>Statistics Activity</span>
                        </li>
                        <li className="menu-item" data-for="Deleting a Activity">
                            <span>Deleting a Activity</span>
                        </li>
                        <li className="menu-item" data-for="Rerunning Jobs">
                            <span>Rerunning Jobs</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for={"History"}>
                    <span>History</span>
                </li>

                <li className="menu-item" data-for={"Trash"}>
                    <span>Trash</span>
                </li>

        <li className="menu-item" data-for={"Settings"}>
          <span>
            Settings<i className="icon icon-tiny icon-arrow-right"></i>
          </span>
          <ul>
            <li className="sub-menu-item" data-for="Authentication Settings">
              <span>Authentication Settings</span>
            </li>
            <li className="sub-menu-item" data-for="Setting Up OpenID Connect Authentication">
              <span>Setting Up OpenID Connect Authentication</span></li>
            <li className="sub-menu-item" data-for="Setting Up SAML2 Authentication">
              <span>Setting Up SAML2 Authentication</span>
            </li>
            <li className="sub-menu-item" data-for="Setting Up SAML2 Authentication using Azure AD">
              <span>Setting Up SAML2 Authentication using Azure AD</span>
            </li>
            <li className="sub-menu-item" data-for="LDAP Settings">
              <span>LDAP Settings</span>
            </li>
            <li className="sub-menu-item" data-for="Email Settings">
              <span>Email Settings</span>
            </li>
          </ul>
        </li>

                <li className="menu-item" data-for={"Enabling SSL"}>
                    <span>Enabling SSL<i className="icon icon-tiny icon-arrow-right"></i></span>
                    <ul>
                        <li className="sub-menu-item" data-for="Self-signed Certificates">
                            <span>Self-signed Certificates</span>
                        </li>
                        <li className="sub-menu-item" data-for="Important Notes on Configuration">
                            <span>Important Notes on Configuration</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for={"Configuring Spark"}>
                    <span>Configuring Spark<i className="icon icon-tiny icon-arrow-right"></i></span>
                    <ul>
                        <li className="sub-menu-item" data-for="Configuring System Data Source Access for Spark">
                            <span>Configuring System Data Source Access for Spark</span>
                        </li>
                        <li className="sub-menu-item" data-for="Important Notes on Configuration">
                            <span>Important Notes on Configuration</span>
                        </li>
                        <li className="menu-item" data-for="Choosing Local or YARN Execution">
                            <span>Choosing Local or YARN Execution<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="YARN mode">
                                    <span>YARN mode</span>
                                </li>
                                <li className="sub-menu-item" data-for="Local mode">
                                    <span>Local mode</span>
                                </li>
                            </ul>
                        </li>
                        <li className="menu-item" data-for="Allocating Resources for Spark">
                            <span>Allocating Resources for Spark<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Configuring Spark Context">
                                    <span>Configuring Spark Context</span>
                                </li>
                                <li className="sub-menu-item" data-for="Configuring Executor Service Jobs">
                                    <span>Configuring Executor Service Jobs</span>
                                </li>
                                <li className="sub-menu-item" data-for="Scheduling Spark Jobs">
                                    <span>Scheduling Spark Jobs</span>
                                </li>
                                <li className="sub-menu-item" data-for="Troubleshooting Spark Jobs">
                                    <span>Troubleshooting Spark Jobs</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for={"Tuning"}>
                    <span>Tuning<i className="icon icon-tiny icon-arrow-right"></i></span>
                    <ul>
                        <li className="sub-menu-item" data-for="Adjusting the Number of Service Processes">
                            <span>Adjusting the Number of Service Processes</span>
                        </li>
                        <li className="menu-item" data-for="Allocating Cluster Resources for Spark">
                            <span>Allocating Cluster Resources for Spark<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Recommended Settings for Spark Resources">
                                    <span>Recommended Settings for Spark Resources</span>
                                </li>
                                <li className="sub-menu-item" data-for="Example executor.conf File">
                                    <span>Example executor.conf File</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for={"Backup and Restore"}>
                    <span>Backup and Restore<i className="icon icon-tiny icon-arrow-right"></i></span>
                    <ul>
                        <li className="sub-menu-item" data-for="Backup">
                            <span>Backup</span>
                        </li>
                        <li className="sub-menu-item" data-for="Restore">
                            <span>Restore</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for={"Security"}>
                    <span>Security<i className="icon icon-tiny icon-arrow-right"></i></span>
                    <ul>
                        <li className="menu-item" data-for="Users and Groups">
                            <span>Users and Groups<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Adding a User Account">
                                    <span>Adding a User Account</span>
                                </li>
                                <li className="sub-menu-item" data-for="Adding a User Group">
                                    <span>Adding a User Group</span>
                                </li>
                                <li className="sub-menu-item" data-for="Ownership Transfers">
                                    <span>Ownership Transfers</span>
                                </li>
                                <li className="sub-menu-item" data-for="Disabling Users">
                                    <span>Disabling Users</span>
                                </li>
                            </ul>
                        </li>
                        <li className="sub-menu-item" data-for="User Roles">
                            <span>User Roles</span>
                        </li>
                        <li className="menu-item" data-for="Conversations">
                            <span>Conversations<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Data Access Permissions">
                                    <span>Data Access Permissions</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for={"Appearance"}>
                    <span>Appearance<i className="icon icon-tiny icon-arrow-right"></i></span>
                    <ul>
                        <li className="menu-item" data-for="User Avatar">
                            <span>User Avatar<i className="icon icon-tiny icon-arrow-right"></i></span>
                            <ul>
                                <li className="sub-menu-item" data-for="Personally, Identifiable Information (PII)">
                                    <span>Personally, Identifiable Information (PII)</span>
                                </li>
                                <li className="sub-menu-item" data-for="Configuring Authorization with Apache Sentry for Hive">
                                    <span>Configuring Authorization with Apache Sentry for Hive</span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for={"Notifications"}>
                    <span>Notifications<i className="icon icon-tiny icon-arrow-right"></i></span>
                    <ul>
                        <li className="sub-menu-item" data-for="Filter by Type">
                            <span>Filter by Type</span>
                        </li>
                        <li className="sub-menu-item" data-for="Filter by Date">
                            <span>Filter by Date</span>
                        </li>
                    </ul>
                </li>

                <li className="menu-item" data-for={"Help"}>
                    <span>Help</span>
                </li>

                <li className="menu-item" data-for={"Copyright"}>
                    <span>Copyright</span>
                </li>
            </ul>
        </div>
    )
}

export default LeftPageIndex;
