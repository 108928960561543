import React, { Component } from 'react';
import { Route, HashRouter as Router, Switch } from 'react-router-dom';

import Header from './header';
import Menu from './menu';
import App from '../App';
import UserGuide from '../pages/userGuide/user_guide';
import Adapters from '../pages/adapters/adapters';
import Swagger from '../pages/swagger/swagger';
import Footer from './footer';



class Routing extends Component {
	render () {
		return (
			<Router>
				<Header />
	  			<Menu />
	  			<div className="contentBody">
					<Switch>
						<Route exact path="/" component={App} />
						<Route exact path="/getting-started" component={UserGuide} />
						<Route exact path="/Adapters" component={Adapters} />
						<Route exact path="/api" component={Swagger} />
					</Switch>
				 </div>
				 <Footer />
			</Router>
		)
	}
}
export default  Routing;