import React from "react";

function Workflow () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Workflow">
                <div className="ug-content-main-header">Workflow</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>A workflow is a set of one or more jobs to be run as a logical group. Workflows are useful for running multiple jobs as a group and for setting up relationships between jobs. For example, you might use the results from one transform job as input to a second transform job. In this type of scenario, you can set DCP up to run the second job in the workflow only if the first job completes successfully.</p>
                    <p>A workflow can also contain just a single job. In fact, every job is required to be in a workflow in order to run. When a user runs an individual job directly, DCP creates a default workflow for the job.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Viewing Workflows">
                <div className="ug-content-main-header">Viewing Workflows</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The Workflows screen lists the workflows for which the logged-in user is the owner or has permissions.</p>
                    <p>In the left navigation bar, click Automate, then click Workflows. You can click the My View icon to see only workflows for jobs for which you have permissions or click the Community icon to see all the workflows created by users at your organization.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Creating a Workflow">
                <div className="ug-content-main-header">Creating a Workflow</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <ol>
                        <li>In the Workflow summary page, click the plus icon.</li>
                        <li>Provide a name and optional brief description for the new workflow. 
                            In descriptions, you can enter an HTML anchor tag to create a hyperlink.</li>
                        <li>(Optional) Define tags if desired. See Using Tags.</li>
                        <li>Click <b>CREATE</b>.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/78.png')} />
                            </figure>
                        </li>
                        <li>Drag and drop a job from Jobs to Canvas (or double-click the job, and it will appear in the upper left corner of the canvas). If the workflow is to contain more than one job, repeat this step.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/79.png')} />
                            </figure>
                        </li>
                        <li>
                            (For workflows that contain just one job, skip to step 6.) To define the order of the jobs and dependencies between them, connect the jobs in the Canvas. Drag from the anchor on one job and drop on another job.
                            <p>The Option popup is displayed.</p>
                        </li>
                        <li>In Option, choose what condition the first job must meet in order for the next job to run. Either the second job runs only when the first job runs successfully, or the second job runs only when the first job fails.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/80.png')} />
                            </figure>
                        </li>
                        <li>(Optional) If you want to remove a job from the workflow, click the <b>X</b> next to the job name in Jobs.</li>
                        <li>To find a job in the workflow, click the search icon at the top of the canvas and start typing any part of the job name. The matching jobs are highlighted in the canvas.
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/81.png')} />
                            </figure>
                            <figure>
                                <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/workflow_find_job_1.png')} />
                            </figure>
                        </li>
                        <li>When the workflow is done, click <b>SAVE</b>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Creating Workflow Groups">
                <div className="ug-content-main-header">Creating a Workflow Group</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>Groups allow you to run several jobs simultaneously in a workflow to decrease the time required to complete data preparation activities. 
                     Multiple groups can be created in a workflow when one group of jobs must run before another group of jobs.</p>
                  <p>By default, you can rerun all jobs in a workflow from the workflow&#39;s Summary page in Activities.</p>
                  <p>You can configure groups with the option to rerun only failed jobs. You must configure this option before the workflow runs. 
                     If <strong>Retry Only Failed</strong> is turned on, you can select <strong>Retry</strong> when you view the workflow&#39;s Summary page in Activities.</p>
                  <ol>
                    <li>Navigate to <strong>Prepare</strong> &gt; <strong>Workflows</strong>.</li>
                    <li>Click the plus icon.</li>
                    <li>Enter a workflow name.</li>
                    <li><strong>Optional</strong>: Enter a workflow description.</li>
                    <li><strong>Optional</strong>: Enter tags to classify the workflow.</li>
                    <li>Click <strong>Create</strong>.</li>
                    <li>Click <strong>Create group</strong>.</li>
                    <li>Drag and drop jobs into the group.</li>
                    <li><strong>Optional</strong>: Click the vertical ellipsis icon.</li>
                    <li><strong>Optional</strong>: Turn <strong>Retry Only Failed</strong> on.</li>
                    <li>Click <strong>Save</strong>.</li>
                  </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Deleting a Workflow">
                <div className="ug-content-main-header">Deleting a Workflow</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>A workflow can be deleted by either the owner of the workflow or a user with Administrator privileges. To delete a workflow, display it in the Workflows list page, the Workflows pane of the Dashboard, or in search results, then click the context menu and choose Delete.</p>
                    <p>This deletes all the dependent objects also. If you do not have permissions on the dependent objects, DCP lists the restricted objects so you can request the owners to give you access to them.</p>
                    <p>Deleted workflows are listed in the Trash window. You can restore the workflow from here if desired. An error message notifies you if there are any missing dependencies.</p>
                    <p>All Deleted items can be retrieved from the Trash. Navigate to Manage > Trash to review all deleted objects, But once an item is "Deleted Permanently" it cannot be retrieved.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/deleting_workflow_1.png')} />
                    </figure>
                    <p>Deleted Workflow and their associated objects can be recovered from the Trash.</p>
                    <figure>
                        <img alt="Boomi Data Catalog & Prep" src={require('../assets/images/deleting_workflow_2.png')} />
                    </figure>
                    <p>Deleted Workflow  and their associated objects cannot be recovered.</p>
                </div>
            </div>
        </section>
    )
}

export default Workflow;
