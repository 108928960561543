import React from "react";

function RulesManager () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Rules Manager">
                <div className="ug-content-main-header">Rules Manager</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The Rules Manager is located in Manage &#62; Rules Manager and lets you create rules to identify 
                      Personal Identifiable Information (PII) and mask sensitive data in your catalog.</p>
                    <figure>
                        <img alt="Rules Manager Summary" src={require('../assets/images/rules_manager_summary.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="PII Classification Overview">
                <div className="ug-content-main-header">PII Classification Overview</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Administrators can mark data in the catalog as Personal Identifiable Information (PII) and control who can create PII rules for column names and data types. 
                      Columns identified as PII contain a purple shield icon and can be fully or partially masked.</p>
                    <h5>Benefits</h5>
                    <p>Identifying and classifying personal and sensitive information in your data catalog helps you:</p>
                    <ul>
                      <li>Adhere to your organization’s data privacy policy</li>
                      <li>Adhere to government regulations</li>
                      <li>Assist in identifying personal data for removal (<a href={"https://gdpr.eu/right-to-be-forgotten/"} className={"link"}>right to be forgotten)</a></li>
                    </ul>
                    <h5>Classification Strategies</h5>
                    <p>You can indicate that data is PII in the following ways:</p>
                    <ul>
                      <li>Classify specific data types throughout the catalog</li>
                      <li>Classify columns names throughout the catalog</li>
                      <li>Classify specific columns in a data set</li>
                    </ul>
                    <h5>PII Permissions and Data Masking</h5>
                    <p>Administrators can control who sees masked data and who can create PII rules. Regardless of masking rules and permissions, Administrators always see data. 
                      You must have UDT & PII - Create role access to create PII rules for data types and column names.</p>
                    <p>If a role has Read & See Masked Data - Partial permissions, masking rules do not apply and users will see data unmasked. 
                      Users do not see masked data if their role has Read & See Masked Data - Revoked.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="PII Classification Rules for Data Types">
                <div className="ug-content-main-header">PII Classification Rules for Data Types</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>You can create rules so that certain data types are automatically identified as Personal Identifiable Information (PII). A PII rule for a data type is useful when you want to classify a specific type of information, 
                    such as a social security number, as personal and sensitive. For example, you can create a rule that classifies all social security 
                    numbers in your catalog as PII.</p>
                  <p>If you want to classify a custom data type, you must first create the custom data type.</p>
                  <p>Only one rule can apply to each data type at a time. Therefore, you must create data masking and PII classification rules at the same time. 
                    To edit a data type rule, you must first delete the existing data type rule and then create a new rule.</p>
                  <ol>
                    <li>Navigate to <strong>Manage</strong> &#62; <strong>Rules Manager</strong>.</li>
                    <li>Click the plus icon.</li>
                    <li>Select <strong>Data Type</strong>.</li>
                    <li>Select a data type in <strong>Specify Data Type</strong>.</li>
                    <li>Turn <strong>Mark as PII</strong> on.</li>
                    <li>Select one of the following to determine how PII is masked:
                        <ol>
                          <li><strong>Mask All</strong> - masks all characters or digits.</li>
                          <li><strong>Partial Mask</strong> - masks a portion of the data, such as the first 5 characters or digits.</li>
                          <li><strong>Partial Show</strong> - shows a portion of the data, such as the first 5 characters or digits.</li>
                        </ol>
                    </li>
                    <li>Click <strong>Save</strong>. Rules are immediately applied in the data catalog.</li>
                  </ol>
                    <figure>
                        <img alt="Rule" src={require('../assets/images/rules_manager_create_1.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="PII Classification Rules for Columns">
                <div className="ug-content-main-header">PII Classification Rules for Columns</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>You can create rules so that certain columns across all data sets in your catalog are automatically identified as Personal Identifiable Information (PII). Information can be masked but not marked PII. 
                    However, information marked as PII is always masked.</p>
                  <p>Column name rules are useful when you want to classify a column name as personal and sensitive. For example, you can create a rule that classifies the column name “passport_number” as PII on all data sets in your catalog. 
                    Other passport number columns not labeled “passport_number” are not affected by the rule.</p>
                  <p>Only one rule can apply to each column name at a time. Therefore, you must create data masking and PII classification rules at the same time. To edit a column name rule, you must first delete the existing column name rule and then create a new rule.</p>
                  <ol>
                    <li>Navigate to <strong>Manage</strong> &#62; <strong>Rules Manager</strong>.</li>
                    <li>Click the plus icon.</li>
                    <li>Select <strong>Column Name</strong>.</li>
                    <li>Select a column name in <strong>Specify Column</strong>.</li>
                    <li><strong>Optional</strong>: Enter a description.</li>
                    <li>Turn <strong>Mark as PII</strong> on.</li>
                    <li>Select one of the following to determine how PII is masked:
                        <ol>
                          <li><strong>Mask All</strong> - masks all characters or digits.</li>
                          <li><strong>Partial Mask</strong> - masks a portion of the data, such as the first 5 characters or digits.</li>
                          <li><strong>Partial Show</strong> - shows a portion of the data, such as the first 5 characters or digits.</li>
                        </ol>
                    </li>
                    <li>Click <strong>Save</strong>. Rules are immediately applied in the data catalog.</li>
                  </ol>
                    <figure>
                        <img alt="Rule" src={require('../assets/images/rules_manager_create.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Classifying Specific Columns as PII">
                <div className="ug-content-main-header">Classifying Specific Columns as PII</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>You can identify a specific column in a data set as PII. Identifying a specific column as PII is useful when you don’t want to apply a PII rule to all columns with the same name.</p>
                    <p>For example, you may want to identify the column name “ID” in the “Employee” data set as PII because the data refers to the employee’s ID number. Since other columns with the “ID” name in your catalog refer to data that is not considered sensitive and personal, only the “ID” column in “Employee” is classified as PII.</p>
                    <p>When you overwrite data type PII rules using Column PII rules, DCP removes column rules if you re-import the data.</p>
                    <ol>
                      <li>In the Data Set Explorer, navigate to a data set.</li>
                      <li>In Sample Data, pause your cursor on the column name.</li>
                      <li>Click the drop-down menu.</li>
                      <li>Select <strong>Data Security</strong> &#62; <strong>Mark as PII</strong>.</li>
                      <li>Select one of the following:
                        <ul>
                          <li><strong>Mask All</strong> - masks all characters or digits.</li>
                          <li><strong>Partial Mask</strong> - masks a portion of the data, such as the first 4 characters or digits.</li>
                          <li><strong>Partial Show</strong> - shows a portion of the data, such as the first 4 characters or digits.</li>
                        </ul>
                      </li>
                      <li>Click <strong>Save</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Data Masking Overview">
                <div className="ug-content-main-header">Data Masking Overview</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>You can use data masking to control who can see sensitive data in your catalog.</p>
                    <h5>Benefits</h5>
                    <p>Masking sensitive information in your data catalog helps you:</p>
                    <ul>
                      <li>Adhere to data security and privacy policies</li>
                      <li>Adhere to government regulations</li>
                    </ul>
                    <h5>Data Masking and Data Access</h5>
                    <p>Administrators can control who creates data masking rules. Regardless of masking rules and permissions, Administrators always see data.</p> 
                    <p>If a role permission has Read & See Masked Data - Partial permissions, masking rules do not apply and users will see data unmasked. 
                    Users do not see masked data if their role has Read & See Masked Data - Revoked.</p>
                    <h5>Masking Rules</h5>
                    <p>You can mask columns across multiple data sets using rules. You can specify that certain data types, such as a zip code, 
                      are masked throughout your entire catalog. You can also mask specific column names.</p>
                    <p>You can partially or fully mask data types or column names. For example, you can mask a zip code data type but allow the last 4 digits to show.</p>
                    <h5>Masking Specific Columns</h5>
                    <p>When a masking rule is not appropriate, you can fully or partially mask a specific column in a data set. Masking a specific column does not affect other data sets.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Masking Data Types with Rules">
                <div className="ug-content-main-header">Masking Data Types with Rules</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>You can prevent access to sensitive information by masking data based on its data type.</p>
                    <p>Data type masking rules are useful when you want to always fully or partially hide a specific type of information, 
                      such as a zip code, across all data sets in your catalog. For example, you can configure a rule that masks the last 3 digits of every zip code in your data catalog anytime a ZipCode data type is identified.</p>
                    <p>If you want to mask a custom data type, you must first create the custom data type. Only one rule can apply to each data type at a time. 
                      To edit a data type rule, you must first delete the existing data type rule and then create a new rule.</p>
                    <ol>
                      <li>Navigate to <strong>Manage</strong> &#62; <strong>Rules Manager</strong>.</li>
                      <li>Click the plus icon.</li>
                      <li>Select <strong>Data Type</strong>.</li>
                      <li>Select a data type in <strong>Specify Data Type</strong>.</li>
                      <li><strong>Optional</strong>: Turn <strong>Mark as PII</strong> on if the data type is considered Personal Identifiable Information (PII). You can only create one rule for each data type. 
                        Therefore, masking and PII rules are created at the same time.</li>
                      <li>Ensure <strong>Mask Data</strong> is turned on.</li>
                      <li>Select one of the following radio buttons:
                        <ol>
                          <li><strong>Mask All</strong> - masks all characters or digits.</li>
                          <li><strong>Partial Mask</strong> - masks a portion of the data, such as the first 5 characters or digits.</li>
                          <li><strong>Partial Show</strong> - shows a portion of the data, such as the first 5 characters or digits.</li>
                        </ol>
                      </li>
                      <li>Click <strong>Save</strong>. Masking rules are immediately applied in the data catalog.</li>
                    </ol>
                    <figure>
                        <img alt="Rule" src={require('../assets/images/img-dcp-data_masking.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Masking Columns with Rules">
                <div className="ug-content-main-header">Masking Columns with Rules</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>You can prevent access to specific sensitive information by masking data based on its column name.</p>
                    <p>Column name rules are useful when you want to mask information that has specific column names. For example, you can create a rule that masks the column name “primary_accountbalance” so that tables with this column name do not show sensitive account information. 
                      Other account information columns that are not labeled ““primary_accountbalance” are not affected by the rule.</p>
                    <p>Only one rule can apply to each column name at a time. To edit a column name rule, you must first delete the existing column name rule and then create a new rule.</p>
                    <ol>
                      <li>Navigate to <strong>Manage</strong> &#62; <strong>Rules Manager</strong>.</li>
                      <li>Click the plus icon.</li>
                      <li>Select <strong>Column Name</strong>.</li>
                      <li>Select a column in <strong>Specify Column</strong>. </li>
                      <li><strong>Optional</strong>: Turn <strong>Mark as PII</strong> on if the data type is considered Personal Identifiable Information (PII). You can only create one rule for each data type. 
                        Therefore, masking and PII rules are created at the same time.</li>
                      <li>Ensure <strong>Mask Data</strong> is turned on.</li>
                      <li>Select one of the following radio buttons:
                        <ol>
                          <li><strong>Mask All</strong> - masks all characters or digits.</li>
                          <li><strong>Partial Mask</strong> - masks a portion of the data, such as the first 5 characters or digits.</li>
                          <li><strong>Partial Show</strong> - shows a portion of the data, such as the first 5 characters or digits.</li>
                        </ol>
                      </li>
                      <li>Click <strong>Save</strong>. Masking rules are immediately applied in the data catalog.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Masking Specific Columns">
                <div className="ug-content-main-header">Masking Specific Columns</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>You can mask a specific column in a data set. Masking a specific column is useful when you don’t want to apply a masking rule to all columns with the same name. For example, you may want to mask the “account_balance” column in your “Customer” data set. Since you have other data sets in your catalog with an “account_balance” column 
                      that you want visible to everyone, you can apply masking to the column in your “Customer” data set only.</p>
                    <p>You can also use column data security settings to unmask a column name that would otherwise be masked by a rule.</p>
                    <ol>
                      <li>In the Data Set Explorer, navigate to a data set.</li>
                      <li>In Sample Data, pause your cursor on the column name.</li>
                      <li>Click the drop-down menu.</li>
                      <li>Select <strong>Data Security</strong> &#62; <strong>Mask Data</strong>.</li>
                      <li>Select one of the following:
                        <ul>
                          <li><strong>Mask All</strong> - masks all characters or digits.</li>
                          <li><strong>Partial Mask</strong> - masks a portion of the data, such as the first 4 characters or digits.</li>
                          <li><strong>Partial Show</strong> - shows a portion of the data, such as the first 4 characters or digits.</li>
                        </ul>
                      </li>
                      <li>Click <strong>Save</strong>.</li>
                    </ol>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Edit and Delete Rules">
                <div className="ug-content-main-header">Edit and Delete Rules</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>You must create PII and masking rules for a specific column name or data type in the same rule. To change a rule, you must delete the rule and create a new one. 
                      You can delete a rule if you have UDT and PII - Delete permissions and you own the rule. Administrators can delete any rule.</p>
                    <p>Anytime you delete and then restore a rule, you must re-import data to apply the rule to columns again.</p>
                    <ol>
                      <li>Navigate to <strong>Manage</strong> &#62; <strong>Rules Manager</strong>.</li>
                      <li>Select the rule you want to delete.</li>
                      <li>The rule’s current settings display for your review. Click <strong>Close</strong>.</li>
                      <li>In the Details panel for the selected rule, click the <strong>More options</strong> (vertical ellipsis) icon.</li>
                      <li>Select <strong>Delete</strong>.</li>
                      <li>Click <strong>Move to Trash</strong>. The removal is immediately applied to all affected columns. Deleted rules are sent to the Trash page where an administrator can restore them. 
                        Administrators have the option to delete the object permanently from your instance.</li>
                    </ol>
                </div>
            </div>
        </section>
    )
}

export default RulesManager;
