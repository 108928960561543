import React from "react";

function Tuning () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Tuning">
                <div className="ug-content-main-header">Tuning</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Tuning settings are only available in the DCP On-Premesis edition.</p>
                    <p>During ongoing usage of your DCP deployment, you may notice performance aspects which you might wish to modify to improve the user experience. This section discusses the controls available to you for tuning your DCP software.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Adjusting the Number of Service Processes">
                <div className="ug-content-main-header">Adjusting the Number of Service Processes</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Use the setting Access Client Maximum Workers to set the maximum number of access service processes to run at the same time.</p>
                    <p>Use the setting Discovery Client Maximum Workers to set the maximum number of access service processes to run at the same time.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Allocating Cluster Resources for Spark">
                <div className="ug-content-main-header">Allocating Cluster Resources for Spark</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The Spark context for the DCP executor service is configured in the spark section of the file executor.conf. The path to this file is:</p>
                    <p className={"font-weight-300"}>$UNIFI_HOME/services/executor/executor-service/executor.conf</p>
                    <p>You can help optimize the DCP performance by editing executor.conf and allocating more Hadoop node resources to Spark jobs.</p>
                    <p>Your Hadoop cluster is used by many competing processes. Keep the other loads on the cluster in mind when deciding how much power to devote to Spark, and choose a reasonable level that provides acceptable performance to the users.</p>
                    <p>NOTE: After editing executor.conf, restart DCP to put the new settings into effect.<br/>In the spark section of executor.conf, you will find the following settings:</p>
                    <p>spark.executor.instances</p>

                    <p>The is the number of spark executors to spawn. Each spark executor instance takes up one virtual core (vcore) on the YARN cluster. The default is 1. You can set this value up to the total number of nodes in spark.cores.max. It is prudent to consider the other loads that the cluster needs to handle. A number somewhere above 1 but below your total number of nodes is more likely to result in the best performance.</p>
                    <p>spark.executor.memory and spark.driver.memory</p>
                    <p>Maximum memory allocated to each of the executors and drivers. The default is 512mb each. If you have a larger amount of RAM, such as 10 gigabytes, it is not unreasonable to devote a larger share of the memory to Spark.</p>
                    <p>spark.cores.max</p>
                    <p>Tells how many processor cores are available to devote to Spark jobs. The default is one core. However, suppose your Hadoop cluster includes 20 nodes, each node having eight cores. You might not want to assign every core as available for use by Spark, but clearly, there is additional capacity that could reasonably be used.</p>
                    <p>spark.executor.cores</p>
                    <p>Tells how many processor cores each Spark executor will use. The default is 1, and this is the recommended setting. It is not recommended to change this value. The spark.executor.cores multiplied by spark.executor.instances cannot exceed spark.cores.max.</p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Recommended Settings for Spark Resources">
                <div className="ug-content-main-header">Recommended Settings for Spark Resources</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>We recommend that you provide Spark as many resources as possible while keeping enough of the YARN cluster available for other jobs. The specific settings depend on your own situation.</p>
                    <p>If the DCP is using only one cluster, allocate 50 percent or 60 perent of cluster resources for Spark. This leaves the rest of the resources for the DCP Sqoop and Hive jobs.</p>
                    <p>For example, suppose you have a YARN cluster with 64 vcores. If each node in the cluster has 64G of RAM on an 8-core machine, each vcore has approximately 8G of RAM. The following executor.conf settings would be recommended in this scenario:</p>
                    <p className={"font-weight-300"}>
                        spark.executor.instances = 32<br/>
                        spark.executor.memory = 4G
                    </p>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Example executor.conf File">
                <div className="ug-content-main-header">Example executor.conf File</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>Following is an example of the portion of executor.conf related to Spark tuning. This example shows the minimum configuration that is set by default: one Spark executor with 512m of memory. Modify this configuration to reflect the size of your cluster and how much of its resources you want to allocate to DCP.</p>
                    <table className={"font-weight-300"}>
                        <tbody>
                            <tr>
                                <td>
                                    spark &#123;<br/>
                                    context-settings &#123;<br/>
                                    spark.cores.max = 1<br/>
                                    <br/>
                                    spark.executor.memory = 512m<br/>
                                    <br/>
                                    spark.driver.memory = 512m<br/>
                                    spark.executor.instances = 1<br/>
                                    spark.executor.cores = 1<br/>
                                    …<br/>
                                    &#125;<br/>
                                    &#125;<br/>
                                </td>
                                <td>
                                    <br/>
                                    <br/>
                                    # Number of cores to allocate.<br/>
                                    # Required.<br/>
                                    # Executor memory per node,<br/>
                                    # -Xmx style eg 512m, #1G, etc.<br/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}

export default Tuning;
