import React from "react";

function Activities () {
    return (
        <section>
            <div className="ug-content-wrapper" data-content="Activities">
                <div className="ug-content-main-header">Activities</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_summary.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Job Activity">
                <div className="ug-content-main-header">Job Activity</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>The Job Activity list view gives you an overview of recent jobs and schedules. You can see which jobs were successful, 
                        which ones failed, and which are currently in progress.</p>
                    <div>
                      <p>The list view provides the following information about the job or schedule:</p>
                        <ul>
                            <li>ID - unique identifier for the job or schedule.</li>
                            <li>Name - the job name or schedule name.</li>
                            <li>Created by - the user who created the job or schedule.</li> 
                            <li>Start Time</li>
                            <li>End Time</li>
                            <li>Status - indicates the job or schedule is successful, running, or has failed.</li>
                        </ul>
                    </div>
                    <p>You can rerun or retry only failed jobs within a scheduled workflow. 
                       Select the activity and click the vertical ellipsis icon, then select <strong>Rerun</strong> or <strong>Retry</strong>. See Rerunning Jobs to learn more.</p>
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_job_activity.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Job Activity Summary">
                <div className="ug-content-main-header">Summary</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>The Job Activity Summary page shows you the following details about recent jobs and schedules:</p>
                    <ul>
                        <li>Created By - user who initialized the job.</li>
                        <li>Start Time</li>
                        <li>End Time</li>
                        <li>Status - indicates the job is successful, running, or has failed.</li>
                        <li>Output - displays the target output for the job, if applicable.</li>
                        <li>Input Dataset - displays the datasets involved in the job and high-level details.</li> 
                    </ul>
                  <p>You can click the vertical ellipsis icon to rerun a job or retry only failed jobs in a scheduled workflow. See Rerunning Jobs to learn more.</p>
                  <figure>
                    <img alt="Activities" src={require('../assets/images/activities_job_summary.png')} />
                  </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Job Activity Output">
                <div className="ug-content-main-header">Output</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_job_output.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Job Activity Input">
                <div className="ug-content-main-header">Input</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_job_intput.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Job Activity Logs">
                <div className="ug-content-main-header">Logs</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_job_logs.png')} />
                    </figure>
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_job_logs_2.png')} />
                    </figure>
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_job_logs_3.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Job Activity Lineage">
                <div className="ug-content-main-header">Lineage</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_job_lineage.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Rerunning Jobs">
                <div className="ug-content-main-header">Rerunning Jobs</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>From the Summary page in Activities, you can rerun jobs from the beginning or rerun only failed jobs within a scheduled workflow. 
                    To rerun a job, you must have access to the datasets within the job, and your role must have Create access for Activities and 
                    Read access for Datasets, Prep Jobs, Workflows, and Schedules.</p>
                  <p>You can rerun a job or an entire workflow from the beginning.</p>
                  <ol>
                    <li>Navigate to <strong>Prepare</strong> &gt; <strong>Activities</strong>.</li>
                    <li>Click the job or schedule name.</li>
                    <li>Click the vertical ellipsis icon.</li>
                    <li>Select <strong>Rerun &#40;Run workflow from beginning&#41;</strong>.</li>
                  </ol>
                  <h5>Retrying only failed jobs</h5>
                  <p>When you organize multiple jobs to run at the same time in a group, you have the option to retry and run only failed jobs. Running only failed jobs helps you reduce the amount of time it takes to rerun several jobs in a workflow. 
                    This option can be used when there are no downstream impacts from retrying only failed jobs.</p>
                  <p>By default, you can rerun all jobs in a workflow. You can configure groups with the option to rerun only failed jobs in <strong>Prepare</strong> &gt; <strong>Workflows</strong>. 
                    You must configure this option before the workflow runs. See Creating a Workflow Group to learn more.</p>
                  <ol>
                    <li>Navigate to <strong>Prepare</strong> &gt; <strong>Activities</strong>.</li>
                    <li>Click the schedule name.</li>
                    <li>Click the vertical ellipsis icon.</li>
                    <li>Select <strong>Retry (Run workflow from failed job)</strong>.</li>
                  </ol>
                  <figure>
                        <img alt="Activities" src={require('../assets/images/img-dcp-workflow_group_edit.png')} />
                  </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Job Activity Export Job Output">
                <div className="ug-content-main-header">Export Job Output</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_job_export.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Import Activity">
                <div className="ug-content-main-header">Import Activity</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>Importing data generates an import activity. You can import and update data sets from the Data Set Explorer <strong>Re-import Data Set</strong> button, 
                    through a schedule, or from the Data Source’s <strong>Import Data Source</strong> button.</p>
                  <p>After an import completes, you can view details about the imported data sets on the activity’s Summary page. You can view details about which data sets and columns were added, updated, 
                    and if any data sets failed to import.</p>
                  <p>You can quickly check the status of import activities on the Dashboard.</p>
                  <ol>
                    <li>Navigate to <strong>Prepare</strong> &#62; <strong>Activities</strong> &#62; <strong>Import</strong>.</li>
                    <li>Select the activity name.</li>
                    <li>The activity Summary tab lists details about the datasets imported. By default, all datasets involved in the activity 
                        display but you can click the status to filter import details by status.</li>
                    <figure>
                        <img alt="Activities" width="30%" src={require('../assets/images/img-dcp-import_status.png')} />
                    </figure>
                    <li>Pause your cursor over a dataset and click the <strong>Details</strong> link to show details about which columns were deleted, added, or updated.</li>
                  </ol>
                  <figure>
                        <img alt="Activities" src={require('../assets/images/img-dcp-import_activity_details.png')} />
                  </figure>
                    
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Statistics Activity">
                <div className="ug-content-main-header">Statistics Activity</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                  <p>You can view the status and details of computed sample and full statistics for datasets and columns. Statistic activities include logs that can help you troubleshoot 
                    computation failures and keep track of the date, time, and user involved. You can also review the performance of statistical computation, such as the length 
                    of time for data acquisition and transformation when calculating full statistics.</p>
                  <ol>
                    <li>After you compute statistics, navigate to <strong>Prepare</strong> &#62; <strong>Activities</strong> &#62; <strong>Statistics</strong>.</li>
                    <li>Select the activity name.</li>
                    <li>The activity <strong>Summary</strong> lists the dataset name, the total time needed to complete the calculation, and the time for data collection and transformation.</li>
                    <li>Click <strong>Logs</strong> to view details about the process. You can pause your cursor on the information to copy it for troubleshooting purposes. 
                        You can also export it to a CSV file or view as text.</li>
                  </ol>
                    <figure>
                        <img alt="Activities" src={require('../assets/images/img-dcp-statistics_activity.png')} />
                    </figure>
                </div>
            </div>

            <div className="ug-content-wrapper" data-content="Deleting a Activity">
                <div className="ug-content-main-header">Deleting a Activity</div>
                <div className="col-12 col-md-8 col-lg-9 ug-content">
                    <p>An activity can be deleted by either the owner of the schedule or a user with Administrator privileges. To delete an activity, display it in the Activity Summary page, the Activity panel of the Dashboard, or in search results, then click the context menu and choose Delete.</p>
                    <p>This deletes all the dependent objects also. If you do not have permissions on the dependent objects, DCP lists the restricted objects so you can request the owners to give you access to them.</p>
                    <p>Deleted Activities  are listed in the TRASH window. From this window, the schedule can be restored or permanently deleted. When you restore a schedule you are notified of any missing dependencies.</p>
                    <p>All Deleted items can be retrieved from the Trash. Navigate to Manage > Trash to review all deleted objects, But once an item is "Deleted Permanently" it cannot be retrieved.</p>
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_delete.png')} />
                    </figure>
                    <p>Deleted Activity and their associated objects can be recovered from the Trash.</p>
                    <figure>
                        <img alt="Activities" src={require('../assets/images/activities_delete_2.png')} />
                    </figure>
                    <p>Deleted Activity and their associated objects cannot be recovered.</p>
                </div>
            </div>
        </section>
    )
}

export default Activities;
